import { useEffect, useState } from 'react';
import { setLists } from 'store/slices/userFavorites';
import { FavoriteListType } from 'types';
import { multiUseAnalyticsEvent, siteErrorEvent } from 'utility/analytics-events';
import { setSkuProductsAddedToLists, setEcodeProductsAddedToLists } from 'store/slices/listsPanel';
import { useAppDispatch, useAppSelector } from '../store/store';
import { favoritesListsSelector } from '../store/selectors';
import { myLockerAPIRequest } from '../utility/common-utils';
import { handleCreateListAnalyticsEvent } from '../components/ListsPanel/analytics/analytics-events';
import useLogger from './useLogger';

const useLists = (storeIdentifierName: string) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const lists = useAppSelector(favoritesListsSelector);
  const { warn, trace } = useLogger();

  useEffect(() => {
    setLoading(false);
  }, [lists]);

  const getCreateListResponse = (listsArray: FavoriteListType) =>
    listsArray.find((list) => list.defaultList === false);

  const createList = async (name: string, listVisibility: boolean) =>
    new Promise((resolve) => {
      setLoading(true);
      const body = {
        name,
        listVisibility,
        defaultList: false,
      };

      myLockerAPIRequest('/lists', `brand=${storeIdentifierName}`, 'PUT', JSON.stringify(body))
        .then((response) => {
          const responseList = getCreateListResponse(response.data);
          multiUseAnalyticsEvent({ event: 'CreateList' });
          handleCreateListAnalyticsEvent(name);
          resolve(responseList);
          setLoading(false);
        })
        .catch((e) => {
          warn('useLists', { ErrorMessage: 'createList', Error: e });
          setLoading(false);
          resolve(null);
        });
    });

  const fetchLists = (completeCallback = () => {}) => {
    myLockerAPIRequest('/lists', `brand=${storeIdentifierName}`, 'GET')
      .then((response) => {
        if (response.status === 200) {
          dispatch(setLists(response.data.lists));

          /* eslint no-underscore-dangle: 0 */
          if (response.data.lists.length >= response.data._metaData.limit) {
            siteErrorEvent({
              ErrorMessage: 'Max List Limit Reached',
            });
            trace('useLists', {
              ErrorFunction: 'fetchLists',
              ErrorMessage: 'Max List Limit Reached',
            });
          }
        }

        completeCallback(response.data);
      })
      .catch((e) => {
        warn('useLists', { ErrorMessage: 'fetchLists', Error: e });
        completeCallback([]);
      });
  };

  const fetchListsFromProductsPromise = (productIdentifier: { [key: string]: string | object }) =>
    new Promise((resolve) => {
      const { ecode, sku } = productIdentifier;
      myLockerAPIRequest(
        '/products',
        `brand=${storeIdentifierName}&ecode=${ecode}${sku ? `&sku=${sku}` : ``}`,
        'GET',
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
        })
        .catch((e) => {
          warn('useLists', { ErrorMessage: 'fetchListsFromProductsPromise', Error: e });
        })
        .finally(() => {
          resolve([]);
        });
    });

  const addToLists = (
    productsIdentifiers: { [key: string]: string | object },
    listIds: string[],
    callback = () => {},
    errorCallback = () => {},
  ) => {
    const body = productsIdentifiers;
    myLockerAPIRequest(
      '/products',
      `brand=${storeIdentifierName}${listIds.length ? `&listID=${listIds.join()}` : ''}`,
      'PUT',
      JSON.stringify({
        products: body,
      }),
    )
      .then((response) => {
        if (response.status === 200) {
          callback(response.data);
          return;
        }

        errorCallback();
      })
      .catch((e) => {
        warn('useLists', { ErrorMessage: 'addToLists', Error: e });
        errorCallback(e);
      });
  };

  const deleteFromLists = (productIds: string[], callback = () => {}, errorCallback = () => {}) => {
    myLockerAPIRequest(
      '/products',
      `brand=${storeIdentifierName}&productIds=${productIds.join()}`,
      'DELETE',
    )
      .then((response) => {
        if (response.status === 202) {
          callback(response.data);
          return;
        }
        errorCallback();
      })
      .catch((e) => {
        warn('useLists', { ErrorMessage: 'deleteFromLists', Error: e });
        errorCallback(e);
      });
  };

  const fetchSingleListPromise = (listID: string) =>
    new Promise((resolve) => {
      myLockerAPIRequest('/lists/detail', `brand=${storeIdentifierName}&listID=${listID}`, 'GET')
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data.list);
          }
        })
        .catch((e) => {
          warn('useLists', { ErrorMessage: 'fetchSingleListPromise', Error: e });
        })
        .finally(() => {
          resolve({});
        });
    });

  const fetchEcodeProductsInLists = () => {
    // Convert ecodes key in list to uppercase to avoid duplicated ecodes on API
    const upperEcode = (ecode) =>
      Object.keys(ecode).reduce((acc, key) => {
        acc[key.toUpperCase()] = ecode[key];
        return acc;
      }, {});

    myLockerAPIRequest('/products/mapByEcode', `brand=${storeIdentifierName}`, 'GET')
      .then((response) => {
        if (response.status === 200) {
          dispatch(setEcodeProductsAddedToLists(upperEcode(response.data)));
        }
      })
      .catch((e) => {
        warn('useLists', {
          ErrorFunction: 'fetchEcodeProductsInLists',
          ErrorMessage: `Lists couldn't be loaded`,
          Error: e,
        });
      });
  };

  const fetchSkuProductsInLists = (ecode: string) => {
    myLockerAPIRequest('/products/mapBySku', `Ecode=${ecode}&brand=${storeIdentifierName}`, 'GET')
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSkuProductsAddedToLists(response.data));
        }
      })
      .catch((e) => {
        warn('useLists', {
          ErrorFunction: 'fetchSkuProductsInLists',
          ErrorMessage: `Lists couldn't be loaded`,
          Error: e,
        });
      });
  };

  const isProductInList = (
    productEcode: string,
    ecodeLists: string[],
    productSku: string | undefined,
    skuLists: string[],
  ) =>
    !productSku
      ? ecodeLists?.some((ecode) => productEcode === ecode)
      : skuLists?.some((sku) => productSku === sku);

  return {
    createList,
    fetchLists,
    loading,
    addToLists,
    deleteFromLists,
    fetchListsFromProductsPromise,
    fetchSingleListPromise,
    fetchSkuProductsInLists,
    fetchEcodeProductsInLists,
    isProductInList,
  };
};

export default useLists;
