import React from 'react';

import './MainLogo.scss';
import { useAppSelector } from 'store/store';
import { environmentConfigSelector, storeIdentifierNameSelector } from 'store/selectors';
import { checkStores } from 'utility/utility';

interface MainLogoProps {
  className?: string;
}

/*
 * MainLogo component displays the brand logo.
 * The assets must be uploaded into the /web_assets/logo/{stage/prod}/{storeIdentifierName} directory in
 * Akamai NetStorage before we can enable the brand to use this component.
 *
 * Images should be validated in stage before being promoted to the prod folder!
 * Both prod & stage sub folders have their own unique CP code for clearing the Akamai cache independently.
 */

const MainLogo = ({ className }: MainLogoProps) => {
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const environmentConfig = useAppSelector(environmentConfigSelector);

  return (
    <div
      className={`main-logo hmf-display-flex hmf-justify-content-flex-start ${
        className && className !== '' ? className : ''
      }`}>
      <a
        className="main-logo-link hmf-display-flex hmf-align-items-center hmf-justify-content-center"
        href={checkStores('/')}
        tabIndex={0}>
        <picture>
          {['pl', 'mj'].includes(storeIdentifierName) && (
            <source
              media="(max-width: 1025px)"
              srcSet={`https://images.dickssportinggoods.com/assets/logo/${
                environmentConfig.environment === 'prod' ? 'prod' : 'stage'
              }/${storeIdentifierName}/logo-mobile.svg`}
              data-testid="MainLogo-mobile-logo"
            />
          )}
          <img
            src={`https://images.dickssportinggoods.com/assets/logo/${
              environmentConfig.environment === 'prod' ? 'prod' : 'stage'
            }/${storeIdentifierName}/logo.svg`}
            alt="logo"
            data-testid="MainLogo-desktop-logo"
          />
        </picture>
      </a>
    </div>
  );
};

export default MainLogo;

MainLogo.defaultProps = {
  className: '',
};
