import { createSlice, current } from '@reduxjs/toolkit';
import { MLListsFormType, ListPanelState } from 'types';

export const initialState: ListPanelState = {
  productsOnList: {
    newLists: [],
    originalLists: [],
    serviceLists: [],
  },
  view: '',
  target: {
    ecode: undefined,
    sku: undefined,
    productAttributes: undefined,
  },
  successCallback: () => {},
};

export const listsPanelSlice = createSlice({
  name: 'listsPanel',
  initialState,
  reducers: {
    setListsPanelState(state, action) {
      // Useful for mass updating multiple pieces of state at once
      return { ...state, ...action.payload };
    },
    addServiceLists(state, action) {
      const list = action.payload;
      const { productsOnList } = current(state);
      return {
        ...state,
        productsOnList: {
          ...productsOnList,
          serviceLists: [...list],
        },
      };
    },
    addNewList(state, action) {
      const list = action.payload;
      const { productsOnList } = current(state);
      return {
        ...state,
        productsOnList: {
          ...productsOnList,
          newLists: [list, ...productsOnList.newLists],
        },
      };
    },
    addOriginalLists(state, action) {
      const list = action.payload;
      const { productsOnList } = current(state);
      return {
        ...state,
        productsOnList: {
          ...productsOnList,
          originalLists: [...list],
        },
      };
    },
    clearListsStore() {
      return { ...initialState };
    },
    toggleList(state, action) {
      const list = action.payload;
      const { productsOnList } = current(state);
      // search item for toggle in both lists
      const updatedServiceList = productsOnList.serviceLists.map((listItem: MLListsFormType) => {
        if (listItem.id === list) {
          return { ...listItem, selected: !listItem.selected };
        }
        return { ...listItem };
      });
      const updatedNewList = productsOnList.newLists.map((listItem: MLListsFormType) => {
        if (listItem.id === list) {
          return { ...listItem, selected: !listItem.selected };
        }
        return { ...listItem };
      });
      return {
        ...state,
        productsOnList: {
          ...productsOnList,
          serviceLists: updatedServiceList,
          newLists: updatedNewList,
        },
      };
    },
    setView(state, action) {
      return {
        ...state,
        view: action.payload,
      };
    },
    setTarget(state, action) {
      const { ecode, sku, productAttributes } = action.payload;
      return {
        ...state,
        target: {
          ecode,
          sku,
          productAttributes,
        },
      };
    },
    setSuccessCallback(state, action) {
      return {
        ...state,
        successCallback: action.payload,
      };
    },
    setSkuProductsAddedToLists(state, action) {
      return {
        ...state,
        skuProductsAddedToLists: Object.keys(action.payload),
      };
    },

    setEcodeProductsAddedToLists(state, action) {
      return {
        ...state,
        ecodeProductsAddedToLists: Object.keys(action.payload),
      };
    },
  },
});

export const {
  setListsPanelState,
  addServiceLists,
  addNewList,
  addOriginalLists,
  toggleList,
  clearListsStore,
  setView,
  setTarget,
  setSuccessCallback,
  setSkuProductsAddedToLists,
  setEcodeProductsAddedToLists,
} = listsPanelSlice.actions;

export default listsPanelSlice.reducer;
