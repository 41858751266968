import { ReduxState } from '../../types';

export const newListsSelector = (state: ReduxState) => state.listsPanel.productsOnList.newLists;
export const originalListsSelector = (state: ReduxState) =>
  state.listsPanel.productsOnList.originalLists;
export const serviceListsSelector = (state: ReduxState) =>
  state.listsPanel.productsOnList.serviceLists;
export const viewSelector = (state: ReduxState) => state.listsPanel.view;
export const successCallbackSelector = (state: ReduxState) => state.listsPanel.successCallback;
export const targetSelector = (state: ReduxState) => state.listsPanel.target;
export const skuProductsAddedToListsSelector = (state: ReduxState) =>
  state.listsPanel.skuProductsAddedToLists;
export const ecodeProductsAddedToListsSelector = (state: ReduxState) =>
  state.listsPanel.ecodeProductsAddedToLists;
