import { createSlice } from '@reduxjs/toolkit';
import { SAYTState } from '../../types';

const search = typeof window !== 'undefined' && window.location ? window.location.search : '';
const params = search ? new URLSearchParams(search) : '';
const defaultSearchTerm = (params && params.get('searchTerm')) || '';

export const initialState = {
  searchInput: defaultSearchTerm,
  debouncedSearchInput: defaultSearchTerm,
  defaultSearchTerm,
  searchData: { suggestions: null, products: [] },
  productSearchInput: {
    term: '',
    type: null,
    url: null,
  },
  fireSaytImpression: true,
  firePreSaytImpression: true,
  searchDataLoading: false,
  showSaytDrawer: false,
  showPreSaytDrawer: false,
} as SAYTState;

export const saytSlice = createSlice({
  name: 'sayt',
  initialState,
  reducers: {
    setSearchInput(state, action) {
      return { ...state, searchInput: action.payload };
    },
    setDebouncedSearchInput(state, action) {
      return { ...state, debouncedSearchInput: action.payload };
    },
    setSearchData(state, action) {
      return { ...state, searchData: action.payload };
    },
    setProductSearchInput(state, action) {
      return { ...state, productSearchInput: action.payload };
    },
    setFireSaytImpression(state, action) {
      return { ...state, fireSaytImpression: action.payload };
    },
    setFirePreSaytImpression(state, action) {
      return { ...state, firePreSaytImpression: action.payload };
    },
    setSearchDataLoading(state, action) {
      return { ...state, searchDataLoading: action.payload };
    },
    setShowSaytDrawer(state, action) {
      return { ...state, showSaytDrawer: action.payload };
    },
    setDefaultSearchTerm(state, action) {
      return { ...state, defaultSearchTerm: action.payload };
    },
    setShowPreSaytDrawer(state, action) {
      return { ...state, showPreSaytDrawer: action.payload };
    },
  },
});

export const {
  setSearchInput,
  setDebouncedSearchInput,
  setSearchData,
  setProductSearchInput,
  setFireSaytImpression,
  setFirePreSaytImpression,
  setSearchDataLoading,
  setShowSaytDrawer,
  setDefaultSearchTerm,
  setShowPreSaytDrawer,
} = saytSlice.actions;

export default saytSlice.reducer;
