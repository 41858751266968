import React from 'react';

const lazyWithRetries = <T extends React.ComponentType<any>>(
  importer: () => Promise<{ default: T }>,
): React.LazyExoticComponent<T> => {
  const retryImport = async (): Promise<{ default: T }> => {
    try {
      return await importer();
    } catch (error: any) {
      const retry = async (attempt: number): Promise<any> => {
        if (attempt >= 3) throw error;
        await new Promise((resolve) => setTimeout(resolve, 2000));

        try {
          return await importer();
        } catch (e) {
          return retry(attempt + 1);
        }
      };
      return retry(0);
    }
  };
  return React.lazy(retryImport);
};

export default lazyWithRetries;
