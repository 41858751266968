import React from 'react';
import {
  HomefieldIconSocialFacebook,
  HomefieldIconSocialInstagram,
  HomefieldIconSocialPinterest,
  HomefieldIconSocialX,
  HomefieldIconSocialYoutube,
} from '@dsg-tech/homefield-react-icons';

const FacebookIcon = ({ link = '', classes = '' }) => (
  <a
    className="social-link-a"
    href={link}
    data-em="Footer_Facebook"
    aria-label="follow us on facebook">
    <HomefieldIconSocialFacebook className={classes} />
  </a>
);

const InstagramIcon = ({ link = '', classes = '' }) => (
  <a
    className="social-link-a"
    href={link}
    data-em="Footer_Instagram"
    aria-label="follow us on instagram">
    <HomefieldIconSocialInstagram className={classes} />
  </a>
);

const PinterestIcon = ({ link = '', classes = '' }) => (
  <a
    className="social-link-a"
    href={link}
    data-em="Footer_Pinterest"
    aria-label="follow us on pinterest">
    <HomefieldIconSocialPinterest className={classes} />
  </a>
);

const TwitterIcon = ({ link = '', classes = '' }) => (
  <a className="social-link-a" href={link} data-em="Footer_Twitter" aria-label="follow us on X">
    <HomefieldIconSocialX className={classes} />
  </a>
);

const YoutubeIcon = ({ link = '', classes = '' }) => (
  <a
    className="social-link-a"
    href={link}
    data-em="Footer_Youtube"
    aria-label="follow us on Youbue">
    <HomefieldIconSocialYoutube className={classes} />
  </a>
);

export { FacebookIcon, InstagramIcon, PinterestIcon, TwitterIcon, YoutubeIcon };
