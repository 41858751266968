import {
  PromotionData,
  QuickviewDetails,
  SkuDetails,
} from 'components/Quickview/components/FulfillmentOptions/types';

export interface Content {
  Taxonomy: ContentTaxonomy[];
}

export interface NameAndHref {
  n: string;
  u: string;
}

export interface PrimaryCategoryData {
  title: string;
  csvData: CSVData[];
  link: string;
  flyoutImage?: { title: string; link: string; linkTitle: string; imageUpload: string }[];
  cta?: CTA[];
  highlight?: boolean;
  mobileAppImage?: { title: string; link: string; linkTitle: string; imageUpload: string }[];
}

export interface CSVData {
  col: CsvDataColumn[];
  n: string;
  u: string;
}
export interface Cat {
  n: string;
  u: string;
}

export interface ColumnCategory {
  n: string;
  u: string;
  ch?: NameAndHref[];
}

export interface ContentTaxonomy {
  csvData: CsvData[];
  cta: CTA[];
}

export interface CsvData extends NameAndHref {
  col: CsvDataColumn[];
}

export interface CSVPotentialData extends NameAndHref {
  col?: CsvDataColumn[];
  cats?: CsvCats[];
  ch?: NameAndHref[];
}

export interface CsvDataColumn extends NameAndHref {
  cats: CsvCats[];
}

export interface CsvCats extends NameAndHref {
  ch: NameAndHref[];
}

export interface CTA {
  linkTitle: string;
  link: string;
}

export interface ContentScheduleMetaTaxonomy {
  csid: string;
  label: string;
  startDate: string;
  endDate: string;
  contentType: string;
}

export interface ContentScheduleMeta {
  Taxonomy: ContentScheduleMetaTaxonomy[];
}

export interface ApiResponse {
  content: Content;
  contentScheduleMeta?: ContentScheduleMeta;
  metaDescription?: string;
  pageTitle?: string;
  staticId?: string | number;
  templateName?: string;
  templateTag?: string;
  tsid?: string;
}

export interface FooterLink {
  title: string;
  href: string;
  id: string;
}

export interface CommunicationSection {
  emailHeadline: string;
  smsHeadline: string;
  storeIdentifierName?: string;
}

export interface Sku {
  sku: string;
  qty: {
    ats: string;
    bopl: string;
    isa: string;
  };
}

export type StoreDetails = {
  address1: string;
  address2: string;
  street1?: string;
  street2?: string;
  chain: string;
  city: string;
  country: string;
  name: string;
  phone: string;
  state: string;
  status: string;
  store?: string;
  location?: string;
  zip: string;
  zipcode: string;
  skus?: Sku[];
  curbsideHours: {
    sun: string;
    mon: string;
    tue: string;
    wed: string;
    thu: string;
    fri: string;
    sat: string;
  };
  storeHours: {
    sun: string;
    mon: string;
    tue: string;
    wed: string;
    thu: string;
    fri: string;
    sat: string;
  };
};

export interface StoreListData {
  distance: string;
  skus: Sku[];
  store: StoreDetails;
  units: string;
}

export interface AggregatedStoreData {
  distance: string;
  inventory: {
    ats: string;
    bopl: string;
    isa: string;
  };
  storeRes: StoreDetails;
  units: string;
}

export type TargetType = {
  ecode: string;
  sku?: string;
};

export type HeaderFunctions = {
  sameDayDelivery: () => void;
  storeSelection: (param: {
    skusArr?: string[];
    selectedSkuVal?: string;
    analyticsData?: object;
    openModal?: boolean;
  }) => void;
  getStoreInfo: () => void;
  updateSelectedStore: (store: any) => void;
  openListPanel: (
    template: 'add-product' | 'remove-product',
    key: TargetType,
    defaultList: boolean,
    successCalback: (report: { added: string[]; removed: string[] }) => void,
    from: 'PLP' | 'PDP',
  ) => void;
};

export type QuickviewService = {
  openProductQuickView: (
    ecode: string,
    source: string,
    optionalParams: Record<string, string>,
  ) => void;
};

export type SkuFavoriteService = {
  openSkuFavorite: (ecode: string, swatch: string) => void;
};

export type ReduxState = {
  taxonomy: TaxonomyState;
  common: CommonState;
  locationServices: LocationServicesState;
  sayt: SAYTState;
  user: UserState;
  // to add type
  listsPanel: ListPanelState;
  quickView: QuickViewState;
};

export type QuickViewState = {
  productDetails: QuickviewDetails;
  promotionData: PromotionData;
  template: string;
  color: string;
  sku: SkuDetails;
  clearance: boolean;
  skuCategory: boolean;
  preview: boolean;
  ecodeParam: string;
  referrerParam: string;
  optionalParams: Record<string, string>;
};

export type ListPanelState = {
  productsOnList: MLListsFormStateType;
  view: string;
  target: {
    ecode: string | undefined;
    sku: string | undefined;
    productAttributes: PersonalizedAttribute | CustomizerAttribute | undefined;
  };
  successCallback: (report: { added: string[]; removed: string[] }) => void;
};

export type TaxonomyState = {
  header: HeaderTaxonomy;
  footer: FooterTaxonomy;
};

export type FavoriteListDetailType = {
  id: string;
  displayName: string;
  defaultList: boolean;
  products: FavoriteListsDetailProductType[];
  selected?: boolean;
  analytics?: {
    createdDate: string;
    updatedDate: string;
  };
};

export type FavoriteListType = {
  displayName: string;
  description?: string;
  accessToken?: string;
  listVisibility: boolean;
  brand?: string;
  id: string;
  productCount: number;
  productCapacity?: number;
  listCapacity?: number;
  listType?: string;
  isFull: boolean;
  defaultList: boolean;
  analytics?: {
    createdDate: string;
    updatedDate: string;
  };
  selected?: boolean;
};

export type ProductsOnlistType = {
  serviceLists: [];
  newLists: [];
  originalLists: [];
};

export type UserState = {
  userFavorites: {
    lists: FavoriteListType[];
    defaultList: FavoriteListDetailType;
    productsOnList: ProductsOnlistType;
  };
};

export type FavoriteListsType = {
  list: FavoriteListDetailType;
  _metaData?: {
    limit: number;
    offset: number;
    totalCount: number;
  };
};

export type FavoriteRelationType = {
  listID: number;
  productID: number;
};

export type FavoriteListsDetailProductType = {
  id: string;
  ecode: string;
  sku?: string | undefined;
  analytics?: {
    createdDate: string;
    updatedDate: string;
  };
};

export type SocialLinks = {
  twitter: string;
  facebook: string;
  instagram: string;
  youtube: string;
};

export type CommonState = {
  storeIdentifierName: string;
  storeId?: number;
  storeName: string;
  headerFooterType: 'header' | 'footer';
  breakpoint: string;
  aos: boolean;
  brandConfig: {
    storeId: number;
    storeIdentifier: string;
    storeName: string;
    catalogId: number;
    channelId: number;
    domainName: string | undefined;
    qaDomainName: string | undefined;
    fonts: string[];
    chatUrl: string | undefined;
    signUpUrl: string | undefined;
    font: string | undefined;
    socialLinks: SocialLinks;
  };
  apiEndpoints: {
    searchApi: string;
    tickerApi: string;
    thumbnailApi: string;
    storeByNumberEndpoint: string;
    storesByLocationEndpoint: string;
    productDetailsEndpoint: string;
    smsSignUpUrl: string;
    smsSignUpApiKey: string;
  };
  partyKey: string;
  featureFlags: {
    [key in string]: {
      [storeIdentifierName in string]: boolean;
    };
  };
  windowVariables: {
    [key in string]: boolean;
  };
  environmentConfig: {
    environment: string;
    applicationId: string;
    api: {
      sayt: {
        [key in string]: {
          [storeIdentifierName in string]: string;
        };
      };
      availability: {
        [key in string]: {
          [storeIdentifierName in string]: string;
        };
      };
      microService: {
        logger: {
          path: string;
          host: {
            [key in string]: {
              [storeIdentifierName in string]: string;
            };
          };
        };
      };
      logger: {
        client: {
          level: LoggerLevel;
        };
        xhr: {
          level: LoggerLevel;
          url: string;
        };
      };
    };
  };
  isSkuSelectionUndefined?: boolean;
};

export type SAYTState = {
  searchInput: string;
  debouncedSearchInput: string;
  defaultSearchTerm: string;
  searchData: SearchData;
  productSearchInput: {
    term: string;
    type: null | string;
    url: null | string;
  };
  fireSaytImpression: boolean;
  firePreSaytImpression: boolean;
  searchDataLoading: boolean;
  showSaytDrawer: boolean;
  showPreSaytDrawer: boolean;
};

export type LocationServicesState = {
  storeDetails: StoreDetails | null;
  storeList: StoreListData[] | null;
  deliveryZip: string | null;
  athleteZip: string | null;
  skuList: string[] | number[] | null;
  selectedSku: number | null;
};

export type HeaderNavLinks = {
  title: string;
  cta: CTA[];
};

export type HeaderTaxonomy = {
  headerNavBar?: NavBarData[] | undefined;
  'headerNavBar-Beta'?: NavBarData[] | undefined;
  Taxonomy?: [PrimaryCategoryData];
  Taxonomy2?: [PrimaryCategoryData];
  'current offers'?: [{ headline: string; offers: [{ title: string; link?: string }] }];
  cta?: [CTA];
  headerNavLinks?: HeaderNavLinks[];
  trendingSearches?: TrendingSearch | undefined;
};

export type FooterTaxonomy = {
  'Footer Links': [{ csvData: [CsvData]; cta: [CTA] }];
  'Footer Message': [
    {
      link: string;
      icon_dropdown: string;
      topText: string;
      bottomText: string;
      ctaText: string;
    },
  ];
  'Communication Section': [
    {
      headline: string;
      'Communication Message': [
        {
          emailHeadline: string;
          smsHeadline: string;
          smsBody: string;
          sms_link_text: string;
          sms_link: string;
        },
      ];
    },
  ];
  'Score Section': [
    {
      headline: string;
      'Score Message': [
        {
          line1text: string;
          line2text: string;
          link: string;
          ctaText: string;
        },
      ];
    },
  ];
  footerV2Messaging?: [
    {
      description: string;
      footerIcons: string;
      link: string;
      title: string;
    },
  ];
  footerEmailSignup?: [
    {
      description: string;
      title: string;
      visualAssets: [[{ altText: string; type: string; value: string }]];
    },
  ];
};

export interface NavBarData {
  link: string;
  linkTitle: string;
  mobileIcon?: string;
  description?: string;
}

export interface NavContentData {
  headerNavBar: NavBarData[] | undefined;
  Taxonomy2: PrimaryCategoryData[] | undefined;
  'headerNavBar-Beta': NavBarData[] | undefined;
}

export interface FeaturedElementItem {
  title: string;
  linkTitle: string;
  link: string;
  visualAssets: {
    altText: string;
    type: string;
    value: string;
  }[][];
}

export interface FeatureLinkItem {
  cta?: CTA[];
  'cta-beta': CTA[];
  featuredFlyoutLabel: string;
  l0SeoUrl: string;
}

export interface FeaturedElementData {
  'featured-item': FeaturedElementItem[];
  featuredLink: FeatureLinkItem[];
}

export interface FeaturedElementApiData {
  content: FeaturedElementData;
}

export interface CategoryItem {
  type: 'category';
  item: CsvCats;
}

export interface FeatureItem {
  type: 'feature';
  item: FeatureLinkItem;
}

export type CombinedDataItem = CategoryItem | FeatureItem;

export interface NikeConnected {
  id: string;
  value: string | boolean;
}

export interface IFlyoutImage {
  linkTitle: string;
  title: string;
  link: string;
  imageUpload: string;
}

export type StoreIdentifierName =
  | 'dsg'
  | 'gg'
  | 'calia'
  | 'vrst'
  | 'pl'
  | 'g3'
  | 'sm'
  | 'plf'
  | 'mj';

interface SearchSuggestionCategory {
  id: string;
  name: string;
  products: number;
  quantitySold: number;
  url: string;
}

export interface SearchSuggestionResult {
  brands: string[];
  categories: SearchSuggestionCategory[];
  keywords: string[];
  products: SearchProductResult[];
  term: string;
}

export type SearchData = {
  suggestions: null | SearchSuggestionResult;
  products: [] | SearchProductResult[];
};

export interface SearchProductResult {
  brand?: string;
  color?: string;
  isPinned?: boolean;
  dealsPercent?: number;
  mapPriceIndicator?: number;
  maxListPrice: number;
  maxOfferPrice: number;
  minListPrice: number;
  minOfferPrice: number;
  name: string;
  partNumber: string;
  priceIndicator?: number;
  productId?: number;
  products?: number;
  quantitySold?: number;
  seoUrl?: string;
  thumbnail: string;
}

export interface TrendingSearch {
  headline: string;
  cta: TrendingSearchCta[];
}

export interface TrendingSearchCta {
  linkTitle: string;
  link: string | undefined;
}

export interface IDrawerData {
  selectedL0: string;
  selectedL1: string;
}

export type LinkMessageType = {
  intro: string;
  caption: string;
  url: string;
};

export type FavoriteSuccesCallbackType = {
  f: (affectedLists: { added: string[]; removed: string[] }) => void;
};

// useProductsOnlists types
export type MLListsFormType = {
  id: string;
  name: string;
  selected: boolean;
  listID: string;
  productID: string;
  analytics?: {
    createDate: string;
  };
};

export type MLListsFormStateType = {
  serviceLists: FavoriteListType[];
  newLists: FavoriteListType[];
  originalLists: FavoriteListType[];
};

export type SlideinNavSMLink = {
  title: string;
  cta: CTA[];
};

export type PersonalizedAttribute = {
  personalizedAttribute: {
    title: string;
    value: string;
  };
};

export type CustomizerAttribute = {
  customizerAttribute: {
    title: string;
    value: string;
  };
};

export interface LoggerConfig {
  clientLoggerLevel?: LoggerLevel;
  xhrLoggerLevel?: LoggerLevel;
  xhrLoggerUrl?: string;
}

export enum LoggerLevel {
  TRACE = 0,
  DEBUG,
  INFO,
  LOG,
  WARN,
  ERROR,
  OFF,
}

export type LoggerMessageType = string | Record<string, unknown>;
export type LoggerAdditionalType = undefined | string | Record<string, unknown>;

export interface Logger {
  trace: (message: LoggerMessageType, additional: LoggerAdditionalType) => void;
  debug: (message: LoggerMessageType, additional: LoggerAdditionalType) => void;
  info: (message: LoggerMessageType, additional: LoggerAdditionalType) => void;
  log: (message: LoggerMessageType, additional: LoggerAdditionalType) => void;
  warn: (message: LoggerMessageType, additional: LoggerAdditionalType) => void;
  error: (message: LoggerMessageType, additional: LoggerAdditionalType) => void;
  logToServer: (
    level: LoggerLevel,
    message: LoggerMessageType,
    additional: LoggerAdditionalType,
  ) => void;
}
