import React from 'react';
import { Button } from '@dsg-tech/homefield-react';
import {
  HomefieldIconReactionLoveFilled,
  HomefieldIconReactionLoveOutlined,
} from '@dsg-tech/homefield-react-icons';
import { isSkuSelectionUndefinedSelector } from 'store/selectors/commonSelector';
import { useAppSelector } from 'store/store';
import './FavoritesButton.scss';

interface FavoritesUIProps {
  icon: string;
  handleFavoritesState: () => void;
  disabled?: boolean;
}

const FavoritesUI = ({ icon, handleFavoritesState, disabled }: FavoritesUIProps) => {
  const isSkuSelectionUndefined = useAppSelector(isSkuSelectionUndefinedSelector);
  const chooseIcon = (str: string | undefined) => {
    return str === 'filled' ? HomefieldIconReactionLoveFilled : HomefieldIconReactionLoveOutlined;
  };

  const IconComponent = chooseIcon(icon);

  return (
    <div className="favorite-container hmf-display-flex">
      <Button
        disabled={isSkuSelectionUndefined || disabled}
        className={`favorite-button hmf-mr-xs hmf-p-0 button-container ${
          isSkuSelectionUndefined || disabled ? 'disabled' : ''
        }`}
        onClick={handleFavoritesState}
        label="Favorites button"
        variant="secondary"
        size="large"
        icon={<IconComponent className={icon === 'outlined' ? '' : 'selected'} />}
      />
    </div>
  );
};

FavoritesUI.defaultProps = {
  disabled: false,
};

export default FavoritesUI;
