import { useEffect, startTransition } from 'react';
import { useAppDispatch, useAppSelector } from '../store/store';
import { featureFlagsSelector, windowVariablesSelector } from '../store/selectors/commonSelector';
import { setWindowVariables } from '../store/slices/common';
import useClientSideRender from './useClientSideRender';

/**
 * This custom hook handles listening and grabbing values from window variables on the page and loading them into Redux.
 * This hook should only ever be used invoked once and should only reside in the top level HeaderFooter component.
 */

const useWindowVariables = () => {
  const dispatch = useAppDispatch();
  const windowVariables = useAppSelector(windowVariablesSelector);
  const featureFlags = useAppSelector(featureFlagsSelector);

  const { isClient } = useClientSideRender();

  const getWindowVariableChanges = () => {
    let newWindowVariables = {};
    Object.keys(featureFlags).forEach((featureFlagName) => {
      if (window[featureFlagName] !== windowVariables[featureFlagName]) {
        newWindowVariables = { ...newWindowVariables, [featureFlagName]: window[featureFlagName] };
      }
    });

    if (Object.keys(newWindowVariables).length > 0) {
      startTransition(() => {
        dispatch(setWindowVariables({ ...windowVariables, ...newWindowVariables }));
      });
    }
  };

  useEffect(() => {
    document.addEventListener('WindowFlagsEvent', getWindowVariableChanges);
    window.addEventListener('load', getWindowVariableChanges);

    return () => {
      window.removeEventListener('WindowFlagsEvent', getWindowVariableChanges);
      window.removeEventListener('load', getWindowVariableChanges);
    };
  }, []);

  // Delaying the call to get window variables to address hydration differences
  useEffect(() => {
    if (isClient) {
      getWindowVariableChanges();
    }
  }, [isClient]);

  return null;
};

export default useWindowVariables;
