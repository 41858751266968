export const multiUseAnalyticsEvent = (detail) => {
  const evt = new CustomEvent('multiUseAnalyticsEvent', {
    detail,
  });
  document.dispatchEvent(evt);
};

export const siteErrorEvent = (detail) => {
  const evt = new CustomEvent('SiteError', {
    detail,
  });
  document.dispatchEvent(evt);
};

export const handleTopNavViewCustomEvent = () => {
  try {
    // eslint-disable-next-line no-undef
    const data = siteData || {};
    const evt = new CustomEvent('TopNavView', {
      detail: { ...data, ...{ EventType: 'TopNavView' } },
    });
    window.dispatchEvent(evt);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug(error);
  }
};

export const handleTapAheadEvent = () => {
  const evt = new CustomEvent('TapAheadClick');
  document.dispatchEvent(evt);
};

export const handleSearchFocusEvent = () => {
  const evt = new CustomEvent('StartSearch');
  document.dispatchEvent(evt);
};

export const handleSAYTEvent = (keyword) => {
  if (window) {
    window.localStorage.setItem('TAG_DerivativeKeyword', keyword);
  }
};

export const handleStoreSearchAnalyticsEvent = (zipcode) => {
  const event = new CustomEvent('ChangeMyStoreEvent', {
    detail: { zipcode },
  });
  document.dispatchEvent(event);
};

export const handleStoreChangeAnalyticsEvent = (detail) => {
  const event = new CustomEvent('ChangePreferredStore', {
    detail,
  });
  document.dispatchEvent(event);
};

export const handleModalOpenEvent = (storeIdentifierName, modalName) => {
  const event = new CustomEvent('ModalOpen', {
    detail: { ModalName: `${storeIdentifierName.toUpperCase()}: Modal: ${modalName}` },
  });
  document.dispatchEvent(event);
};

export const handleSDDLocationChangeAnalyticsEvent = (zipcode) => {
  const event = new CustomEvent('ChangeDeliveryLocationEvent', {
    detail: { zipcode },
  });
  document.dispatchEvent(event);
};

export const ProgressiveNavBackEvent = (
  { selectedL0, selectedL1 } = { selectedL0: '', selectedL1: '' },
) => {
  if (!selectedL0) {
    return;
  }

  multiUseAnalyticsEvent({
    event: 'ProgressiveNavBackButton',
    value: `${selectedL0}${selectedL1 ? `_${selectedL1}` : ''}`,
  });
};

export const TopNavEventForProgressiveTaxo = (category, nikeConnectedValue) => {
  const event = new CustomEvent('TopNavView', {
    detail: { TopNavViewSource: `SubCategory_${category}`, nikeConnected: nikeConnectedValue },
  });
  document.dispatchEvent(event);
};

export const handleAOSStoreNumberSetEvent = (storeNumber) => {
  const event = new CustomEvent('aosStoreInformationPopupCompleted', {
    detail: { StoreNumber: storeNumber },
  });
  document.dispatchEvent(event);
};
