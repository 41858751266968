import axios from 'axios';
import { sha256 } from 'js-sha256';
import { useState } from 'react';
import { envData } from '../environments';
import { storeIdentifierNameSelector } from '../store/selectors';
import { useAppSelector } from '../store/store';
import useLogger from './useLogger';

const useEmailSignUp = (): [
  null | object,
  boolean,
  (signUpEmail: string) => void,
  (message: string, email: any) => void,
  (signUpEmail: string) => void,
] => {
  const [userEmail, setEmail] = useState('');
  const [emailSignUpError, setEmailSignUpError] = useState<boolean>(false);
  const [emailSignUpResponse, setEmailSignUpResponse] = useState(null);
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const { signUpUrl } = envData[storeIdentifierName];
  const { warn, trace } = useLogger();

  const signUpAnalytics = (message: string, email = null) => {
    const hashedEmail = email ? sha256(email).toUpperCase() : email;
    const evt = new CustomEvent('EmailSignup', {
      detail: email
        ? { SignUpMessage: message, EAthlete: hashedEmail }
        : { SignUpMessage: message },
    });
    document.dispatchEvent(evt);
  };

  const validateEmail = (signUpEmail: string) => {
    const emailValid = signUpEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (emailValid && emailValid.length > 0) {
      setEmailSignUpError(false);
      return true;
    }
    signUpAnalytics('Please enter an email in the format username@domain.com');
    setEmailSignUpError(true);
    return false;
  };

  const makeEmailSignUpCall = (email: string) => {
    axios(signUpUrl, {
      method: 'POST',
      timeout: 2000,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ email_address: email }),
    })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        if (res.status !== 200 && res.status !== 201) {
          return setEmailSignUpError(true);
        }

        setEmailSignUpError(false);
        setEmailSignUpResponse(res);
        return true;
      })
      .catch((err) => {
        warn('useEmailSignUp', { ErrorMessage: 'Email Sign Up Failed', Error: err });
        if (err.response) {
          if (err.response.status === 409) {
            // If error response status is 409 user is already registered show message from API
            setEmailSignUpResponse(err.response);

            return setEmailSignUpError(true);
          }
          // Status code other than 409 returned display generic error
          trace('useEmailSignUp', {
            ErrorMessage: 'User is already registered',
            Error: err.response,
          });
          setEmailSignUpError(true);
        } else if (err.request) {
          // No response from API display generic error
          trace('useEmailSignUp', { Error: err.request });
          setEmailSignUpError(true);
        } else {
          // No request returned display generic error
          trace('useEmailSignUp Error: ', { Error: err.message });
          setEmailSignUpError(true);
        }
        return false;
      });
  };

  const updateEmailSignUp = (signUpEmail: string): void => {
    if (signUpEmail) {
      setEmail(signUpEmail);
      if (validateEmail(signUpEmail)) makeEmailSignUpCall(signUpEmail);
    }
  };

  return [emailSignUpResponse, emailSignUpError, updateEmailSignUp, signUpAnalytics, validateEmail];
};

export default useEmailSignUp;
