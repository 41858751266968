import { getCookie } from './cookie-utils';

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const getAthleteNameCookie = () => {
  return getCookie('DCSG-ATHLETE-NAME');
};

const getIdTokenName = () => {
  let name = '';
  if (typeof window !== 'undefined' && window.localStorage) {
    // get and check for idToken value in local storage
    const idToken = window.localStorage.getItem('idToken');
    if (idToken) {
      try {
        const json = JSON.parse(idToken);
        if (json) {
          // if the parsing works, find the right value and assign it to our scoped name var
          name = json['http://www.dickssportinggoods.com/firstName'];
        }
      } catch (error) {
        // handles any potential parsing failures
        // eslint-disable-next-line no-console
        console.error('idToken not parseable,', error);
      }
    }
  }

  return name;
};

/* eslint-disable consistent-return */
export const getUserToken = () => {
  if (typeof window !== 'undefined' && window.authFunctions) {
    return window.authFunctions.getTokens();
  }
  return undefined;
};

export function getAthleteName() {
  return getAthleteNameCookie() || getIdTokenName();
}

export async function isAuthenticated() {
  // starting at our happy path
  // we check for authFunctions, if it's there, we resolve the result of the function
  if (window.authFunctions && window.authFunctions.isAuthenticated)
    return window.authFunctions.isAuthenticated();

  if (window.ceopsStatus) {
    // if ceops is being loaded, then this will wait until its done
    const ceopsResponse = await window.ceopsStatus;

    if (
      ceopsResponse &&
      ceopsResponse.scripts &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ceopsResponse.scripts.filter((script: any) => script.path.includes('secured-athete') > 0)
    ) {
      // if ceops had a proper result, and it contains the SAClient script, then we'll use it to resolve the promise
      // but first we wait for SAClient to be loaded
      let countLimiter = 0;
      while (countLimiter <= 10 && !window.authFunctions) {
        // eslint-disable-next-line no-console
        console.debug('SAClient Not Ready');
        countLimiter += 1;
        // eslint-disable-next-line no-await-in-loop
        await sleep(250);
      }

      if (window.authFunctions && window.authFunctions.isAuthenticated)
        return window.authFunctions.isAuthenticated();
    }
  }

  if (getIdTokenName()) return true;

  return false;
}

export interface AuthState {
  name?: string;
  isLoggedIn: boolean;
}

export async function getAuthState() {
  const auth: AuthState = {
    isLoggedIn: false,
  };

  auth.name = getAthleteName();
  auth.isLoggedIn = await isAuthenticated();

  return auth;
}
