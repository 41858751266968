import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/store';
import { favoritesListSelector } from 'store/selectors/userFavoritesSelector';
import { FavoriteListsDetailProductType } from 'types';
import useFavorites from 'hooks/useFavorites';
import { getUserToken } from 'utility/auth-utils';
import useLogger from 'hooks/useLogger';
import { signOutAddToListEvent } from 'components/Quickview/analytics/analytics-events';
import FavoritesButton from './FavoritesButton';

interface FavoritesEcodeProps {
  ecode: string;
  storeIdentifierName: string;
}

const FavoritesEcode = ({ ecode, storeIdentifierName }: FavoritesEcodeProps) => {
  const { addFavorite, removeFavorite } = useFavorites(storeIdentifierName);
  const { warn } = useLogger();
  const favoritesList = useAppSelector(favoritesListSelector);
  const icon = useMemo(() => {
    if (favoritesList) {
      return favoritesList.some(
        (p: FavoriteListsDetailProductType) => p.ecode === ecode && p.sku === undefined,
      )
        ? 'filled'
        : 'outlined';
    }

    return 'outlined';
  }, [favoritesList, ecode]);

  const handleFavoritesState = useCallback(async () => {
    let userToken: undefined | string;
    try {
      userToken = await getUserToken();
    } catch (e) {
      userToken = undefined;
      warn('FavoritesEcode', {
        ErrorMessage: 'Could not retrieve userToken',
        Error: JSON.stringify(e),
      });
    }

    if (!userToken || typeof userToken !== 'string') {
      signOutAddToListEvent();

      if (window?.authFunctions) {
        window.authFunctions.login();
      }
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    icon === 'filled' ? removeFavorite(ecode) : addFavorite(ecode);
  }, [addFavorite, removeFavorite, ecode]);

  return (
    <FavoritesButton
      icon={icon}
      storeIdentifierName={storeIdentifierName}
      handleFavoritesState={handleFavoritesState}
    />
  );
};

export default FavoritesEcode;
