import axios, { Method } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MatchImageSnapshotOptions } from 'jest-image-snapshot';
import { DEVICE_WIDTH } from './constants';
import { getUserToken } from './auth-utils';

export type ViewPortBreakpointTypes = 'mobile' | 'tablet' | 'small-desktop' | 'desktop';

export const pxPlusOne = (pxNum: string) => `${parseInt(pxNum, 10) + 1}px`;

export const getBreak = (): ViewPortBreakpointTypes => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    if (window.matchMedia(`(max-width: ${DEVICE_WIDTH.mobile})`).matches) return 'mobile';

    if (
      window.matchMedia(
        `(max-width: ${DEVICE_WIDTH.tablet}) and (min-width: ${pxPlusOne(DEVICE_WIDTH.mobile)})`,
      ).matches
    )
      return 'tablet';

    if (
      window.matchMedia(
        `(max-width: ${DEVICE_WIDTH.smallDesktop}) and (min-width: ${pxPlusOne(
          DEVICE_WIDTH.tablet,
        )})`,
      ).matches
    )
      return 'small-desktop';

    return 'desktop';
  }
  return 'desktop';
};

export const getIsValidHost = () => {
  const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
  const isValidHost =
    host.includes('dickssportinggoods.com') ||
    host.includes('golfgalaxy.com') ||
    host.includes('calia.com') ||
    host.includes('vrst.com') ||
    host.includes('publiclands.com') ||
    host.includes('moosejaw.com') ||
    host.includes('goinggoinggone.com');
  return isValidHost;
};

export const getViewPortBreakpoint = (): ViewPortBreakpointTypes => {
  if (window.innerWidth >= 768 && window.innerWidth < 1023) {
    return 'tablet';
  }
  if (window.innerWidth >= 1024 && window.innerWidth < 1280) {
    return 'small-desktop';
  }
  if (window.innerWidth >= 1280) {
    return 'desktop';
  }
  return 'mobile';
};

// Remove special characters, numbers and spaces, and connect words with dashes
export const formatName = (inputName: string): string => {
  const cleanedName = inputName.replace(/[^\w\s]|[\d]/gi, ' ');
  const formattedName = cleanedName.replace(/\s+/g, '-');

  return formattedName.toLowerCase();
};

export const dataEmPrepText = (text: string) => {
  return text.replace(/[^0-9a-zA-Z-]/g, '');
};

export const dataEmIncludingSpaceCleanUpFunction = (text: string) => {
  return text.replace(/[^0-9a-zA-Z- ]/g, '');
};

export const myLockerAPIRequest = (
  endpoint: string,
  params: string,
  method: Method | undefined,
  body?: object,
) => {
  const host = typeof window !== 'undefined' && window.location ? window.location.host : '';

  const returnUrl =
    getIsValidHost() || host.includes('prod-')
      ? `https://${window.location.host}/v1/mylocker`
      : `/favorites-api/v1/mylocker`;

  return new Promise((resolve) => {
    getUserToken().then(
      (token: string | undefined) => {
        if (typeof token !== 'string') {
          resolve(Promise.resolve({ status: 0 }));
          return;
        }

        resolve(
          axios(`${returnUrl}${endpoint}?${params}`, {
            method,
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Authorization: token,
              Cookie: host.includes('prod-') ? 'np0=true' : '',
            },
            data: body,
          }),
        );
      },
      () => {
        resolve(new Promise((r) => r({ status: 0 })));
      },
    );
  });
};

export const MY_ACCOUNT_LISTS_URL = '/MyAccount/Lists';

// jest-image-snapshot custom configuration for image comparison.
// The configuration used is horizontal diff, 2% threshold, and custom directories for snapshots and diffs.
// The failure threshold percentage can be adjusted based on the amount of changes expected in the snapshot.

export const getSnapshotConfig = (
  customSnapshotsDir: string,
  customSnapshotIdentifier: string,
  customDiffDir: string,
): MatchImageSnapshotOptions => {
  return {
    diffDirection: 'horizontal',
    failureThreshold: 0.035,
    failureThresholdType: 'percent',
    customSnapshotsDir,
    customSnapshotIdentifier,
    customDiffDir,
    allowSizeMismatch: true,
  };
};
