const akamaiExpCookies = [
  'akaas_AS_Exp_Desktop',
  'akaas_AS_Exp_Mobile',
  'akaas_GG_AS_EXP',
  'akaas_AS_meta',
  'akaas_AS_EXP_DSG',
];

const akamaiExpCookiesByHostname: Record<string, string> = {
  'm.dickssportinggoods.com': 'akaas_AS_Exp_Mobile',
  'm2.dickssportinggoods.com': 'akaas_AS_Exp_Mobile',
  't.dickssportinggoods.com': 'akaas_AS_Exp_Mobile',
  'www.dickssportinggoods.com': 'akaas_AS_Exp_Desktop',
  'aos.dickssportinggoods.com': 'akaas_AS_Exp_Desktop',
  'meta.dickssportinggoods.com': 'akaas_AS_meta',
  'm.golfgalaxy.com': 'akaas_GG_AS_EXP',
  't.golfgalaxy.com': 'akaas_GG_AS_EXP',
  'www.golfgalaxy.com': 'akaas_GG_AS_EXP',
  'aos.golfgalaxy.com': 'akaas_GG_AS_EXP',
  'meta.golfgalaxy.com': 'akaas_GG_AS_EXP',
};

export function setCookie(cname: string, cvalue: string) {
  if (typeof document !== 'undefined') {
    document.cookie = `${cname}=${cvalue}; path=/`;
  }
}

export function getCookie(cookieName: string) {
  const name = `${cookieName}=`;
  if (typeof document !== 'undefined') {
    const cookieArray = document.cookie ? document.cookie.split(';') : [];
    const cookie = cookieArray.find((c) => c.trim().startsWith(name));
    if (cookie) {
      return cookie.trim().substring(name.length);
    }
  }
  return null;
}

export function getStoreNumber() {
  const storeCookieVal = getCookie('setStoreCookie');
  if (storeCookieVal) {
    const parts = storeCookieVal.split('_');
    if (parts.length === 3) {
      return parts[2];
    }
  }

  return null;
}

export function setSearchTermCookie(searchTerm: string) {
  let separator = '|';
  const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
  if (host.includes('m.')) {
    separator = ',';
  }
  if (getCookie('searchTermHistory')) {
    setCookie(
      'searchTermHistory',
      encodeURIComponent(
        decodeURIComponent(getCookie('searchTermHistory') + searchTerm + separator),
      ),
    );
  } else {
    setCookie('searchTermHistory', encodeURIComponent(searchTerm + separator));
  }
}

export function getParameterByName(name: string) {
  if (typeof window !== 'undefined' && window.location) {
    const url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    const cleanName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${cleanName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results || results.length < 0) {
      return null;
    }
    if (!results || results[2].length < 0) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return null;
}

export function getSwimlaneValue(hostname?: string): number {
  let swimlane = -1;
  let expCookieValue: string | null = null;

  if (hostname && hostname.trim() !== '' && akamaiExpCookiesByHostname[hostname]) {
    expCookieValue = getCookie(akamaiExpCookiesByHostname[hostname]);
  } else {
    expCookieValue =
      akamaiExpCookies.map(getCookie).find((cookieValue) => cookieValue !== null) ?? null;
  }

  if (expCookieValue && expCookieValue.trim() !== '') {
    const expCookieValues = /rv=(\d+)/i.exec(expCookieValue);
    if (expCookieValues && expCookieValues.length >= 2 && parseInt(expCookieValues[1], 10)) {
      swimlane = parseInt(expCookieValues[1], 10);
    }
  }

  return swimlane;
}
