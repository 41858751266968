import React, { useState } from 'react';
import Slider from 'react-slick';
import ErrorBoundaryWithLogger from 'components/common/ErrorBoundary/ErrorBoundary';
import { HomefieldIconChevronDown } from '@dsg-tech/homefield-react-icons';
import { useDelaySwitch } from '@dsg-tech/homefield-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { multiUseAnalyticsEvent } from 'utility/analytics-events';
import { checkStores } from 'utility/utility';
import TickerDrawer from './TickerDrawer/TickerDrawer';
import { TickerData } from '../Ticker.interface';
import './ExpandableTicker.scss';

interface ExpandableTickerProps {
  tickerData: TickerData[];
  backgroundColor?: string;
  foregroundColor?: string;
}

const ExpandableTicker = ({
  tickerData,
  backgroundColor,
  foregroundColor,
}: ExpandableTickerProps) => {
  const { state, neutral, switchState } = useDelaySwitch(false, 250);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hasTickerAnalyticsFired, setHasTickerAnalyticsFired] = useState(false);
  const [isSliding, setIsSliding] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 11000,
    swipe: true,
    arrows: false,
    cssEase: 'ease-out',
    afterChange: (index: number) => {
      setCurrentSlide(index);
      setIsSliding(false);
    },
    beforeChange: () => {
      setIsSliding(true);
    },
  };

  const fireTickerAnalytics = () => {
    if (!hasTickerAnalyticsFired) {
      multiUseAnalyticsEvent({ event: 'tickerFirstInteraction' });
      setHasTickerAnalyticsFired(true);
    }
  };

  const handleTickerClick = (event: React.MouseEvent) => {
    if (!isSliding && !(event.target as HTMLElement).closest('a')) {
      switchState();
      fireTickerAnalytics();
    }
  };

  return (
    <div
      className="expandable-ticker-wrapper header-ticker-minimum hmf-align-items-center hmf-display-flex hmf-flex-row hmf-justify-content-center hmf-position-relative hmf-py-xxs hmf-px-xs"
      style={{ background: backgroundColor }}>
      <div className="expandable-ticker hmf-display-flex hmf-align-items-center hmf-flex-row hmf-justify-content-center hmf-text-align-center">
        <Slider {...settings} className="expandable-ticker-track hmf-display-block">
          {tickerData.map((ticker) => {
            // In order to be able to catch the error with ErrorBoundaryWithLogger and to avoid the component from breaking
            // We need to catch the error in try-catch and still wrap with ErrorBoundaryWithLogger
            try {
              return (
                <ErrorBoundaryWithLogger
                  key={`ticker-${ticker.linkTitle}-${ticker.topText}-${ticker.link}`}>
                  <div className="expandable-ticker-item hmf-align-items-center hmf-display-flex">
                    <div className="expandable-ticker-content-section hmf-m-auto hmf-align-items-center">
                      <button
                        type="button"
                        style={{ color: foregroundColor }}
                        onClick={handleTickerClick}
                        tabIndex={0}
                        data-em={`tkr_ticker_1_ticker-1_na_naDetails_${ticker.topText.replace(
                          /[.,/#!$%^&*;:'{}=\-_`~()\s]/g,
                          '',
                        )}`}>
                        <span className="expandable-ticker-top-section hmf-body-bold-s hmf-display-block">
                          {ticker.topText}
                        </span>
                        <div className="expandable-ticker-bottom-section hmf-body-s hmf-display-flex hmf-flex-wrap hmf-justify-content-center">
                          <span className="expandable-ticker-bottom-text hmf-display-flex">
                            <span className="expandable-ticker-bottom-text-content">
                              {ticker.bottomText}
                            </span>
                            <a
                              className="expandable-ticker-link hmf-text-decoration-underline hmf-flex-shrink-none hmf-align-self-end hmf-mr-xxxs"
                              href={checkStores(ticker.link)}
                              target={
                                checkStores(ticker.link)?.includes('http') ? '_blank' : '_self'
                              }
                              data-em={`tkr_ticker_1_ticker-1_na_naDetails_${ticker.topText.replace(
                                // eslint-disable-next-line no-useless-escape
                                /[.,/#!$%^&*;:'{}=\-_`~()\s]/g,
                                '',
                              )}`}>
                              {ticker.linkTitle}
                            </a>
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </ErrorBoundaryWithLogger>
              );
            } catch (e) {
              return null;
            }
          })}
        </Slider>
      </div>
      <div className="expandable-ticker-expand">
        <button type="button" onClick={handleTickerClick} aria-label="expand ticker">
          <HomefieldIconChevronDown width="24px" height="24px" />
        </button>
      </div>
      <TickerDrawer
        tickerData={tickerData}
        showDrawer={state}
        setShowDrawer={() => switchState()}
        closing={neutral}
        focusedItem={currentSlide}
      />
    </div>
  );
};

ExpandableTicker.defaultProps = {
  backgroundColor: 'black',
  foregroundColor: 'white',
};

export default ExpandableTicker;
