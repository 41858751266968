// import { useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { ReduxState } from 'types';
import common from './slices/common';
import taxonomy from './slices/taxonomy';
import locationServices from './slices/locationServices';
import sayt from './slices/sayt';
import userFavorites from './slices/userFavorites';
import progressiveHeader from './slices/progressiveHeader';
import listsPanel from './slices/listsPanel';
import quickView from './slices/quickView';

export const rootReducer = combineReducers({
  common,
  taxonomy,
  locationServices,
  sayt,
  userFavorites,
  progressiveHeader,
  listsPanel,
  quickView,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector;
