import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Button, TextInput, ActionText, Checkbox, Drawer } from '@dsg-tech/homefield-react';
import { HomefieldIconLocationNearme } from '@dsg-tech/homefield-react-icons';
import {
  setAthleteZip,
  setSkuList,
  setStoreDetails,
  setStoreList,
  setSelectedSku,
} from 'store/slices/locationServices';
import { assignChainName, formatTitleCase, getCookie } from 'utility/utility';
import { setCookie } from 'utility/cookie-utils';
import {
  handleStoreSearchAnalyticsEvent,
  handleStoreChangeAnalyticsEvent,
  handleModalOpenEvent,
} from 'utility/analytics-events';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  environmentConfigSelector,
  storeIdentifierNameSelector,
} from 'store/selectors/commonSelector';
import {
  aggregatedStoreListSelector,
  athleteZipSelector,
  skuListSelector,
  storeDetailsSelector,
  storeListSelector,
  selectedSkuSelector,
} from 'store/selectors/locationServicesSelector';
import useLogger from 'hooks/useLogger';
import ErrorBoundaryWithLogger from 'components/common/ErrorBoundary/ErrorBoundary';
import { StoreListData } from 'types';
import StoreDetails from './StoreDetails';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import './StoreSelectionModal.scss';

const StoreSelectionModal = () => {
  const dispatch = useAppDispatch();
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const athleteZip = useAppSelector(athleteZipSelector);
  const storeList = useAppSelector(storeListSelector);
  const storeDetails = useAppSelector(storeDetailsSelector);
  const environmentConfig = useAppSelector(environmentConfigSelector);

  const skus = useAppSelector(skuListSelector);
  const selectedSku = useAppSelector(selectedSkuSelector);
  const aggregatedSkuStoreList = useAppSelector(aggregatedStoreListSelector);
  const [storeSelectionModalOpen, setStoreSelectionModalOpen] = useState(false);
  const [additionalAnalyticsDataState, setAdditionalAnalyticsDataState] = useState({});
  const [storeSelectionEventFired, setStoreSelectionEventFired] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [searchText, setSearchText] = useState(athleteZip !== null ? athleteZip : '');
  const [isaCheck, setIsaCheck] = useState(storeDetails !== undefined);
  const [atsCheck, setAtsCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [myStorePosition, setMyStorePosition] = useState(true);
  const storeSelectionModalOpenRef = useRef(false);
  const athleteZipRef = useRef<string | null>(null);
  const skusRef = useRef<string | null>(null);
  const { warn } = useLogger();

  const storeSelection = ({
    skusArr = null,
    selectedSkuVal = null,
    analyticsData = {},
    openModal = true,
  }: {
    skusArr?: string[] | number[] | null;
    selectedSkuVal?: string | number | null;
    analyticsData?: object;
    openModal?: boolean;
  } = {}) => {
    setStoreSelectionEventFired(true);
    if (skusArr !== null) {
      setShowAvailability(true);
      if (JSON.stringify(skus) !== JSON.stringify(skusArr)) {
        dispatch(setSkuList(skusArr));
      }
      if (selectedSkuVal !== null) {
        if (skusArr.some((sku) => sku.toString() === selectedSkuVal.toString())) {
          dispatch(setSelectedSku(Number(selectedSkuVal)));
        } else {
          dispatch(setSelectedSku(null));
        }
      } else {
        dispatch(setSelectedSku(null));
      }
    } else {
      setShowAvailability(false);
    }
    if (
      analyticsData &&
      typeof analyticsData === 'object' &&
      Object.keys(analyticsData).length > 0
    ) {
      setAdditionalAnalyticsDataState(analyticsData);
    }
    if (openModal) {
      setStoreSelectionModalOpen(true);
      handleModalOpenEvent(storeIdentifierName, 'Select Store');
    }
  };

  const getStoreInfo = () => {
    return { detail: storeDetails };
  };

  useEffect(() => {
    if (athleteZip && searchText !== null && searchText !== athleteZip) {
      setSearchText(athleteZip);
    }
  }, [athleteZip]);

  useEffect(() => {
    window.headerFunctions = {
      ...(window.headerFunctions && { ...window.headerFunctions }),
      storeSelection,
      getStoreInfo,
    };
    // Fire event to let others know the store location methods are defined and ready to use
    const evt = new CustomEvent('HeaderServices:StoreSelection:Ready');
    window.dispatchEvent(evt);
  }, []);

  useEffect(() => {
    if (!storeSelectionModalOpen) {
      setAdditionalAnalyticsDataState({});
    }
  }, [storeSelectionModalOpen]);

  useEffect(() => {
    // Fire event to let others know the store list changed
    const evt = new CustomEvent('HeaderServices:StoreList:Change', {
      detail: { raw: storeList, aggregated: aggregatedSkuStoreList },
    });
    window.dispatchEvent(evt);
  }, [storeList]);

  const { baseUrl, key } =
    environmentConfig.api.availability[storeIdentifierName] ||
    environmentConfig.api.availability.dsg;

  const updateStoreSelectionDetailsWithSKU = (zip = athleteZip, geoLocationSearch = false) => {
    if (!skus?.length) return;

    const skusString = skus.join(',');
    const storeIdentifier = ['dsg', 'gg', 'pl'].includes(storeIdentifierName)
      ? 'dsg,gg,pl'
      : storeIdentifierName;

    setLoading(true);

    axios
      .get(
        `${baseUrl}/ws/v4/omni/stores?addr=${zip}&radius=100&uom=imperial&lob=${storeIdentifier}&sku=${skusString}&res=locatorsearch`,
        {
          headers: { 'X-API-KEY': key },
          timeout: 20000,
        },
      )
      .then((response) => {
        if (geoLocationSearch) {
          const geocodedZip = response.data.data.origin.geocoded_address.match(/\d{5}/g)[0];
          dispatch(setAthleteZip(geocodedZip));
        }
        dispatch(setStoreList(response.data.data.results));
        if (error) {
          setError(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        warn('OnPageLoadLocationHandler - ', e);
        setError(true);
        setLoading(false);
      });
  };

  const updateStoreSelectionDetailsWithoutSKU = (zip = athleteZip, geoLocationSearch = false) => {
    const storeIdentifier = ['dsg', 'gg', 'pl'].includes(storeIdentifierName)
      ? 'dsg,gg,pl'
      : storeIdentifierName;

    setLoading(true);

    axios
      .get(`${baseUrl}/api/v4/stores/search?addr=${zip}&radius=100&lob=${storeIdentifier}`, {
        headers: { 'X-API-KEY': key },
        timeout: 20000,
      })
      .then((response) => {
        if (geoLocationSearch) {
          const geocodedZip = response.data.origin.geocoded_address.match(/\d{5}/g)[0];
          dispatch(setAthleteZip(geocodedZip));
        }
        dispatch(setStoreList(response.data.results));
        if (error) {
          setError(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        warn(err);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      athleteZip &&
      athleteZip !== undefined &&
      (storeSelectionModalOpen || storeSelectionEventFired)
    ) {
      // if there are not skus sent
      if (skus === null) {
        if ((athleteZip !== athleteZipRef.current || skusRef.current !== null) && skus === null) {
          updateStoreSelectionDetailsWithoutSKU();
          athleteZipRef.current = athleteZip;
          skusRef.current = null;
        }
      } else if (athleteZip !== athleteZipRef.current || skus.join(',') !== skusRef.current) {
        // if skus are sent
        updateStoreSelectionDetailsWithSKU();
        skusRef.current = skus.join(',');
        athleteZipRef.current = athleteZip;
      }

      if (storeSelectionEventFired) {
        setStoreSelectionEventFired(false);
      }
    }
  }, [skus, athleteZip, storeSelectionModalOpen, storeSelectionEventFired]);

  useEffect(() => {
    if (storeSelectionModalOpenRef.current === false && storeSelectionModalOpen === true) {
      setMyStorePosition(true);
      storeSelectionModalOpenRef.current = true;
    } else if (storeSelectionModalOpenRef.current === true && storeSelectionModalOpen === false) {
      storeSelectionModalOpenRef.current = false;
    }
  }, [storeSelectionModalOpen]);

  const getLocationWithLatAndLng = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = `${position.coords.latitude},${position.coords.longitude}`;
        if (skus !== null) {
          updateStoreSelectionDetailsWithSKU(userLocation, true);
        } else {
          updateStoreSelectionDetailsWithoutSKU(userLocation, true);
        }
      },
      () => {
        // user denied access
        if (getCookie('whereabouts')) {
          if (skus !== null) {
            updateStoreSelectionDetailsWithSKU(getCookie('whereabouts'), true);
          } else {
            updateStoreSelectionDetailsWithoutSKU(getCookie('whereabouts'), true);
          }
        }
      },
    );
  };

  // handlers
  const handleSearch = () => {
    if (/\b\d{5}\b/.test(searchText)) {
      dispatch(setAthleteZip(searchText));
      handleStoreSearchAnalyticsEvent(searchText);
      setInputError(false);
      setErrorMessage('');
      setMyStorePosition(false);
    } else {
      setInputError(true);
      setErrorMessage('Please enter a zip code');
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    // it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (!(e.charCode >= 48 && e.charCode <= 57)) {
      e.preventDefault();
    }
  };

  const handleSelectStore = (selectedStoreDetails: StoreListData) => {
    dispatch(setStoreDetails(selectedStoreDetails.store));
    handleStoreChangeAnalyticsEvent({
      ...additionalAnalyticsDataState,
      NewStoreID: selectedStoreDetails?.store?.location
        ? selectedStoreDetails.store.location
        : selectedStoreDetails.store?.store,
    });
    const { zip, name, location, status } = selectedStoreDetails.store;
    const setStoreCookieString = `${zip}_${name}_${location}_${status}`;
    setCookie('setStoreCookie', setStoreCookieString);
    setCookie('DCSG_NGX_CUST_STORE', setStoreCookieString);
    setStoreSelectionModalOpen(false);

    // Fire event to let others know the store location changed
    const evt = new CustomEvent('HeaderServices:StoreSelection:Change', {
      detail: selectedStoreDetails.store,
    });
    window.dispatchEvent(evt);
  };

  const storeListWithFilter =
    storeList && storeList !== null
      ? storeList.filter((storeData) => {
          if (
            skus !== null &&
            selectedSku !== null &&
            selectedSku !== undefined &&
            storeData?.skus !== undefined &&
            selectedSku !== null &&
            (atsCheck === true || isaCheck === true) &&
            showAvailability
          ) {
            const skuQty = storeData.skus.filter((data) => data.sku === selectedSku.toString());
            if (!skuQty.length) {
              return false;
            }
            const { ats, isa } = skuQty[0].qty;
            if ((isaCheck && Number(isa) > 0) || (atsCheck && Number(ats) > 0)) {
              return true;
            }
            return false;
          }
          return true;
        })
      : [];

  const nearbyStoresMessage =
    storeList !== undefined
      ? `${storeListWithFilter.length} store${
          storeListWithFilter.length > 1 ? `s` : ``
        } within 100 miles.`
      : 'We found 0 stores within 100 miles of your location.';

  return (
    <Drawer
      className="store-selection-modal"
      open={storeSelectionModalOpen}
      onCloseModal={() => {
        setStoreSelectionModalOpen(false);
      }}>
      <ErrorBoundaryWithLogger>
        <div className="store-selection-modal-container">
          <div className="store-selection-modal-header hmf-p-s hmf-mb-xxxs hmf-body-bold-l">
            SELECT STORE
          </div>
          <div className="store-selection-modal-body">
            <div className="store-selection-modal-input-container hmf-px-l">
              <div className="store-selection-modal-search hmf-display-flex hmf-align-items-flex-start hmf-mt-m hmf-mb-m-0">
                <TextInput
                  type="text"
                  className="store-selection-modal-search-input hmf-flex-2"
                  placeholder="Enter Zip code"
                  value={searchText}
                  error={inputError}
                  onKeyDown={handleKeyDown}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setSearchText(e.target.value)}
                  helperText={errorMessage}
                />
                <Button
                  variant="primary"
                  className="store-selection-modal-search-button hmf-m-0"
                  onClick={() => handleSearch()}
                  text="SEARCH"
                />
              </div>
              <div className="store-selection-modal-search-options hmf-display-flex hmf-flex-l-row hmf-flex-column hmf-py-xs">
                <div className="hmf-display-flex hmf-align-items-center hmf-justify-content-flex-start">
                  <ActionText onClick={getLocationWithLatAndLng} className="hmf-body-bold-s">
                    <>
                      <HomefieldIconLocationNearme className="store-selection-modal-location-icon" />
                      Use Current Location
                    </>
                  </ActionText>
                </div>
                {skus !== null && showAvailability && (
                  <div className="hmf-display-flex hmf-flex-row hmf-body-s hmf-justify-content-flex-start hmf-pt-xxs hmf-pt-l-0">
                    <div className="hmf-mr-xxs">
                      <Checkbox
                        className="store-selection-modal-isa hmf-legal-xs hmf-legal-m-s hmf-m-0 hmf-display-flex hmf-flex-row hmf-justify-content-center"
                        label="All Stores w/ Availability"
                        checked={isaCheck}
                        onChange={() => {
                          setIsaCheck(!isaCheck);
                        }}
                      />
                    </div>
                    {storeIdentifierName === 'dsg' && (
                      <div className="">
                        <Checkbox
                          className="store-selection-modal-ats hmf-legal-xs hmf-legal-m-s hmf-m-0 hmf-display-flex hmf-flex-row hmf-justify-content-flex-start"
                          label="Pickup Today"
                          checked={atsCheck}
                          onChange={() => {
                            setAtsCheck(!atsCheck);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="store-selection-model-nearby-msg hmf-display-flex hmf-align-items-center hmf-justify-content-center hmf-body-s hmf-py-xxxs">
              {nearbyStoresMessage}
            </div>
            <div className="store-selection-modal-store-list">
              {loading && <LoadingSpinner />}
              {error && !loading && (
                <p className="store-selection-modal-list-error hmf-body-bold-s hmf-p-xs">
                  Something went wrong.
                </p>
              )}
              {storeList &&
                storeList !== null &&
                !loading &&
                !error &&
                (storeDetails && storeDetails !== null && myStorePosition
                  ? storeListWithFilter.sort((x, y) => {
                      if (
                        x.store.location === storeDetails.location ||
                        x.store.location === storeDetails.store
                      ) {
                        return -1;
                      }

                      if (
                        y.store.location === storeDetails.location ||
                        y.store.location === storeDetails.store
                      ) {
                        return 1;
                      }

                      return 0;
                    })
                  : storeListWithFilter
                ).map((storeData) => (
                  <ErrorBoundaryWithLogger key={storeData.store.location}>
                    <div
                      className={`store-selection-modal-store-details-container hmf-body-m hmf-py-l-s hmf-py-xs hmf-px-l ${
                        storeDetails !== null &&
                        (storeData.store.location === storeDetails.location ||
                          storeData.store.location === storeDetails.store)
                          ? `store-selection-modal-current-selected-store`
                          : ``
                      }`}>
                      <StoreDetails
                        storeData={storeData}
                        formatTitleCase={formatTitleCase}
                        assignChainName={assignChainName}
                        handleSelectStore={handleSelectStore}
                        skus={skus}
                        currentSelectedStore={storeDetails}
                        selectedSku={selectedSku}
                        storeIdentifierName={storeIdentifierName}
                        showAvailability={showAvailability}
                      />
                    </div>
                  </ErrorBoundaryWithLogger>
                ))}
            </div>
          </div>
        </div>
      </ErrorBoundaryWithLogger>
    </Drawer>
  );
};

export default StoreSelectionModal;
