import React, { useState, useEffect } from 'react';
import ToastyContent from './ToastyContent';
import './Toasty.scss';

const Toasty = () => {
  const [showToasty, setShowToasty] = useState(false);
  const [userStage, setUserStage] = useState('Signed In Limit');
  const [userLinkMessage, setUserLinkMessage] = useState({});
  const [toastyTimer, setToastyTimer] = useState<NodeJS.Timeout>();

  const showToast = (stageUser: string, linkMessage = {}) => {
    setUserLinkMessage(linkMessage);
    setUserStage(stageUser);
    setShowToasty(true);

    setToastyTimer(
      setTimeout(() => {
        setShowToasty(false);
      }, 8000),
    );
  };

  const onClose = () => {
    setShowToasty(false);
  };

  useEffect(() => {
    window.addEventListener('renderToasty', (event) => {
      showToast(event.detail, event.detail?.linkMessage);
    });
  }, []);

  useEffect(() => {
    if (!showToasty) clearTimeout(toastyTimer);
  }, [setToastyTimer, showToasty]);

  if (!showToasty) return null;

  return <ToastyContent stage={userStage} link={userLinkMessage} onClose={onClose} />;
};

export default Toasty;
