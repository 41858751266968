import React from 'react';

interface Props {
  id: string;
  type: string;
  placeholder: string;
  className: string;
  onBlur: () => void;
  setValue: (e: string) => void;
  onFocus: () => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  validatePhoneNumber: (e: string) => boolean;
  mobileSignUpErrorMessage: string;
  mobileSignUpInputRef: React.RefObject<HTMLInputElement>;
}

const PhoneInput = ({
  id,
  type,
  placeholder,
  className,
  onBlur = () => {},
  setValue,
  onFocus = () => {},
  value,
  onKeyPress = () => {},
  validatePhoneNumber,
  mobileSignUpErrorMessage,
  mobileSignUpInputRef,
  ...props
}: Props) => {
  const maskPhone = (phoneNumber: string) => {
    let maskValue = phoneNumber;
    maskValue = maskValue.replace(/\D/g, '');
    maskValue = maskValue.replace(/^(\d{3})(\d)/g, '($1)$2');
    maskValue = maskValue.replace(/(\d)(\d{4})$/, '$1-$2');
    return maskValue;
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 14) {
      const maskedValue = maskPhone(e.target.value);
      setValue(maskedValue);
      if (mobileSignUpErrorMessage) {
        validatePhoneNumber(maskedValue);
      }
    }
  };
  return (
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      className={className}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      value={value}
      ref={mobileSignUpInputRef}
      {...props}
    />
  );
};

export default PhoneInput;
