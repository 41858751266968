export const envData = {
  dsg: {
    storeId: 15108,
    storeIdentifier: 'dsg',
    catalogId: 12301,
    channelId: -1,
  },
  gg: {
    storeId: 10701,
    storeIdentifier: 'gg',
    catalogId: 10051,
    channelId: -1,
  },
  calia: {
    storeId: 15108,
    storeIdentifier: 'calia',
    catalogId: 12301,
    channelId: -1,
  },
  vrst: {
    storeId: 15108,
    storeIdentifier: 'vrst',
    catalogId: 12301,
    channelId: -1,
  },
  pl: {
    storeId: 16066,
    storeIdentifier: 'pl',
    catalogId: 20310,
    channelId: -1,
  },
  g3: {
    storeId: 15108,
    storeIdentifier: 'g3',
    catalogId: 12301,
    channelId: -1,
  },
  mj: {
    storeId: 16066,
    storeIdentifier: 'mj',
    catalogId: 20310,
    channelId: -1,
  },
};
