import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  productDetails: [],
  promotionData: [],
  template: 'cart',
  color: null,
  sku: [],
  clearance: false,
  skuCategory: null,
  preview: false,
  ecodeParam: '',
  referrerParam: '',
  optionalParams: {},
};

export const quickViewSlice = createSlice({
  name: 'quickView',
  initialState,
  reducers: {
    setQuickViewState(state, action) {
      // Useful for mass updating multiple pieces of state at once
      return { ...state, ...action.payload };
    },
    setProductQuickViewDetails(state, action) {
      return { ...state, productDetails: { ...action.payload } };
    },
    setPromotionData(state, action) {
      return { ...state, promotionData: { ...action.payload } };
    },
    setTemplate(state, action) {
      return { ...state, template: action.payload };
    },
    setSelectedColor(state, action) {
      return { ...state, color: action.payload };
    },
    setSelectedSku(state, action) {
      return { ...state, sku: action.payload };
    },
    setClearance(state, action) {
      return { ...state, clearance: action.payload };
    },
    setSkuCategory(state, action) {
      return { ...state, skuCategory: action.payload };
    },
    setPreview(state, action) {
      return { ...state, preview: action.payload };
    },
    setEcodeParam(state, action) {
      return { ...state, ecodeParam: action.payload };
    },
    setReferrerParam(state, action) {
      return { ...state, referrerParam: action.payload };
    },
    setOptionalParams(state, action) {
      return { ...state, optionalParams: action.payload };
    },
    resetQuickViewState() {
      return initialState;
    },
  },
});

export const {
  setQuickViewState,
  setProductQuickViewDetails,
  setPromotionData,
  setTemplate,
  setSelectedColor,
  setSelectedSku,
  setClearance,
  setSkuCategory,
  setPreview,
  setEcodeParam,
  setReferrerParam,
  setOptionalParams,
  resetQuickViewState,
} = quickViewSlice.actions;

export default quickViewSlice.reducer;
