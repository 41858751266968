import { createSlice } from '@reduxjs/toolkit';
import { TaxonomyState } from '../../types';

export const initialState = {
  header: {},
  footer: {},
} as TaxonomyState;

export const taxonomySlice = createSlice({
  name: 'taxonomy',
  initialState,
  reducers: {
    setHeaderTaxo(state, action) {
      return { ...state, header: action.payload };
    },
    setFooterTaxo(state, action) {
      return { ...state, footer: action.payload };
    },
  },
});

export const { setHeaderTaxo, setFooterTaxo } = taxonomySlice.actions;

export default taxonomySlice.reducer;
