import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../store/store';
import {
  environmentConfigSelector,
  getFeatureFlagByNameSelector,
  storeIdentifierNameSelector,
} from '../store/selectors/commonSelector';
import {
  searchInputSelector,
  searchDebouncedInputSelector,
  searchDataSelector,
  searchProductInputSelector,
  searchFireSaytImpressionSelector,
  defaultSearchTermSelector,
} from '../store/selectors/saytSelector';
import {
  setDebouncedSearchInput,
  setSearchData,
  setFireSaytImpression,
  setSearchDataLoading,
} from '../store/slices/sayt';
import { SearchSuggestionResult, SearchProductResult } from '../types';
import { getSayTEndpoint } from '../utility/utility';
import { envData } from '../environments';
import useLogger from './useLogger';

const useSAYT = () => {
  const dispatch = useAppDispatch();
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const searchInput = useAppSelector(searchInputSelector);
  const debouncedSearchInput = useAppSelector(searchDebouncedInputSelector);
  const searchData = useAppSelector(searchDataSelector);
  const searchProductsInput = useAppSelector(searchProductInputSelector);
  const defaultSearchTerm = useAppSelector(defaultSearchTermSelector);
  const fireSaytImpression = useAppSelector(searchFireSaytImpressionSelector);
  const shorterDelaySAYTEnabled = useAppSelector(
    getFeatureFlagByNameSelector('shorterDelaySAYTEnabled'),
  );
  const environmentConfig = useAppSelector(environmentConfigSelector);
  const debounceDelay = shorterDelaySAYTEnabled ? 250 : 500;
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { warn } = useLogger();

  const search = typeof window !== 'undefined' && window.location ? window.location.search : '';
  const params = search ? new URLSearchParams(search) : '';

  const getSayTApi = () => {
    const sayTApi = environmentConfig.api.sayt[storeIdentifierName]
      ? getSayTEndpoint(environmentConfig.api.sayt[storeIdentifierName].baseUrl)
      : getSayTEndpoint(environmentConfig.api.sayt.dsg.baseUrl);
    return sayTApi;
  };

  const searchSuggestionsApi = `${getSayTApi()}v1/suggest/${
    envData[storeIdentifierName]?.storeId
  }?keyword=`;
  const searchProductsApi = `${getSayTApi()}v1/products/${envData[storeIdentifierName]?.storeId}`;

  const saytUsedEventEmitter = (): void => {
    const saytUsedEvent = new CustomEvent('SAYTImpression');
    document.dispatchEvent(saytUsedEvent);
    dispatch(setFireSaytImpression(false));
  };

  const hasSaytResponseData = (saytData: SearchSuggestionResult): boolean => {
    return (
      saytData.keywords?.length > 0 ||
      saytData.brands?.length > 0 ||
      saytData.categories?.length > 0 ||
      saytData.products?.length > 0
    );
  };

  const callSAYTAPI = (type: string) => {
    const suggestionAPI = `${searchSuggestionsApi}${searchInput}&products=true`;
    const productSuggestionsAPI = `${searchProductsApi}?${searchProductsInput.type}=${searchProductsInput.term}`;
    axios
      .get(type === 'suggestions' ? suggestionAPI : productSuggestionsAPI, {
        timeout: 2000,
        headers: {
          ...(window &&
            window.akamaiSwimlaneCookie && {
              [`x-${storeIdentifierName}-swimlane`]: window.akamaiSwimlaneCookie,
            }),
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data) {
          if (
            searchInput &&
            searchInput.length > 2 &&
            fireSaytImpression &&
            hasSaytResponseData(response.data)
          ) {
            saytUsedEventEmitter();
          }

          if (type === 'suggestions') {
            dispatch(
              setSearchData({
                suggestions: response.data as SearchSuggestionResult,
                products: response.data.products as SearchProductResult[],
              }),
            );
          } else {
            dispatch(
              setSearchData({
                ...searchData,
                products: response.data as SearchProductResult[],
              }),
            );
          }
        }
        dispatch(setSearchDataLoading(false));
      })
      .catch((e) => {
        warn(`DSG Search Failed: ${type === 'suggestions' ? 'Suggestions' : 'Products'}`, e);
        dispatch(setSearchDataLoading(false));
      });
  };

  useEffect(() => {
    // Handle debouncing search term input
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      dispatch(setDebouncedSearchInput(searchInput));
    }, debounceDelay);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [searchInput]);

  useEffect(() => {
    if (searchInput && searchInput.length > 2 && !defaultSearchTerm) {
      callSAYTAPI('suggestions');
    }
  }, [debouncedSearchInput]);

  useEffect(() => {
    if (searchProductsInput && searchProductsInput.term) {
      callSAYTAPI('products');
    }
  }, [searchProductsInput]);

  useEffect(() => {
    // adding the below check to handle the on load API call for dsg, pl and gg. F
    // For g3, the onload call will not run as G3 search component doesnt handle this as of now.
    // Will remove this once G3 search component is refactored to handle this.
    if (params && params.get('searchTerm') && storeIdentifierName !== 'g3') {
      callSAYTAPI('suggestions');
    }
  }, []);

  return null;
};

export default useSAYT;
