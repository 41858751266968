import { createSlice } from '@reduxjs/toolkit';
import { LocationServicesState } from '../../types';

export const initialState = {
  storeDetails: null,
  storeList: null,
  deliveryZip: null,
  athleteZip: null,
  skuList: null,
  selectedSku: null,
} as LocationServicesState;

export const locationServicesSlice = createSlice({
  name: 'locationServices',
  initialState,
  reducers: {
    setLocationServicesState(state, action) {
      // Useful for mass updating multiple pieces of state at once
      return { ...state, ...action.payload };
    },
    setStoreDetails(state, action) {
      return { ...state, storeDetails: action.payload };
    },
    setStoreList(state, action) {
      return { ...state, storeList: action.payload };
    },
    setDeliveryZip(state, action) {
      return { ...state, deliveryZip: action.payload };
    },
    setAthleteZip(state, action) {
      return { ...state, athleteZip: action.payload };
    },
    setSkuList(state, action) {
      return { ...state, skuList: action.payload };
    },
    setSelectedSku(state, action) {
      return { ...state, selectedSku: action.payload };
    },
  },
});

export const {
  setLocationServicesState,
  setStoreDetails,
  setStoreList,
  setDeliveryZip,
  setAthleteZip,
  setSkuList,
  setSelectedSku,
} = locationServicesSlice.actions;

export default locationServicesSlice.reducer;
