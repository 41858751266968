import { useState, useEffect, startTransition } from 'react';

const useClientSideRender = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    startTransition(() => {
      setIsClient(true);
    });
  }, []);

  return { isClient };
};

export default useClientSideRender;
