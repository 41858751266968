import { useCallback, useEffect, useState } from 'react';
import useClientSideRender from './useClientSideRender';
import useLogger from './useLogger';

/**
 * This hook is mostly copied from the `useLocalStorage` functions in the `useHooks` package
 * starting around this line:
 *
 * https://github.com/uidotdev/usehooks/blob/90fbbb4cc085e74e50c36a62a5759a40c62bb98e/index.js#L592
 *
 * Some modifications to variable names and comments are made for legibility, as well as to simplify
 * for our purposes. One notable omission is the removal of logic to handle "default" values. I feel
 * like this isn't super necessary for the ticket I wrote this for (NSE-402:
 * https://dcsgcloud.atlassian.net/browse/NSE-402), and would simplify testing
 *
 * ~Blair Wilcox - 10/15/2024
 */

/**
 * Type for representing valid JSON. Referenced in this issue of the TypeScript repo:
 *
 * https://github.com/microsoft/TypeScript/issues/1897#issuecomment-822032151
 */
export type Json = string | number | boolean | null | Json[] | { [key: PropertyKey]: Json };

/**
 * Types used for checking that the given data can be stringified to JSON. Slightly modified from
 * this post:
 *
 * https://hackernoon.com/mastering-type-safe-json-serialization-in-typescript
 */
export type JsonCompatible<T> = unknown extends T
  ? never
  : {
      [P in keyof T]: T[P] extends Json
        ? T[P]
        : T[P] extends NotAssignableToJson
        ? never
        : JsonCompatible<T[P]>;
    };
// disabling the next line here because we explicitly _want_ to check for any Function, since any
// Function would not be assignable to JSON
// eslint-disable-next-line @typescript-eslint/ban-types
type NotAssignableToJson = bigint | symbol | Function;

function dispatchStorageEvent(key: string, stringifiedValue: string | null) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue: stringifiedValue }));
}

const setLocalStorageItem = (key: string, value: Json) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  // We need to manually trigger a StorageEvent because localStorage doesn't emit this event if
  // updated in the same tab. By dispatching the event, we can ensure other components that use the
  // same key with this hook will also update. Unfortunately this won't happen if localStorage
  // manually without this hook, so the user will have to refresh the page
  dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
  // We need to manually trigger a StorageEvent because localStorage doesn't emit this event if
  // updated in the same tab. By dispatching the event, we can ensure other components that use the
  // same key with this hook will also update. Unfortunately this won't happen if localStorage
  // manually without this hook, so the user will have to refresh the page
  dispatchStorageEvent(key, null);
};

const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key);
};

/**
 * given a key string, attempt to retrieve the value from localStorage. Also, return a setter
 * function that allows that value to be updated
 */
export function useLocalStorage<T>(
  key: string,
): [T | null, (nextVal: JsonCompatible<T> | null) => void] {
  const { isClient } = useClientSideRender();
  const [value, setValue] = useState<T | null>(null);
  const { warn } = useLogger();

  const getParsedValue = (stringifiedValue: string) => {
    try {
      return JSON.parse(stringifiedValue);
    } catch {
      warn(`Error parsing JSON from localStorage for key: ${key}`);
      return null;
    }
  };

  const setValueAndLocalStorage = useCallback(
    (nextVal: JsonCompatible<T> | null) => {
      if (nextVal === null || typeof nextVal === 'undefined') {
        setValue(null);
        removeLocalStorageItem(key);
      } else {
        setValue(nextVal as T);
        setLocalStorageItem(key, nextVal);
      }
    },
    [key],
  );

  // listen for StorageEvents where the value at the current key was updated
  useEffect(() => {
    const listen: (e: StorageEvent) => void = (e) => {
      const { key: updatedKey, newValue: stringifiedNewValue } = e;
      if (updatedKey === key) {
        const parsedValue = getParsedValue(stringifiedNewValue ?? 'null');
        setValue(parsedValue);
      }
    };
    window.addEventListener('storage', listen);
    return () => window.removeEventListener('storage', listen);
  }, []);

  // when the hook loads on the client, populate the value with whatever is currently stored in
  // localStorage
  useEffect(() => {
    if (isClient) {
      const stringifiedValue = getLocalStorageItem(key);
      const parsedValue = getParsedValue(stringifiedValue ?? 'null');
      setValue(parsedValue);
    }
  }, [isClient]);

  return [value, setValueAndLocalStorage];
}
