import { createSelector } from '@reduxjs/toolkit';
import { AggregatedStoreData, ReduxState } from '../../types';

export const locationServicesSelector = (state: ReduxState) => state.locationServices;
export const storeDetailsSelector = (state: ReduxState) => state.locationServices.storeDetails;
export const storeListSelector = (state: ReduxState) => state.locationServices.storeList;
export const deliveryZipSelector = (state: ReduxState) => state.locationServices.deliveryZip;
export const athleteZipSelector = (state: ReduxState) => state.locationServices.athleteZip;
export const skuListSelector = (state: ReduxState) => state.locationServices.skuList;
export const selectedSkuSelector = (state: ReduxState) => state.locationServices.selectedSku;
export const aggregatedStoreListSelector = createSelector(
  storeListSelector,
  skuListSelector,
  (storeList, skuList) => {
    const storeDataBySku: Record<PropertyKey, AggregatedStoreData[]> = {};
    if (skuList && storeList) {
      skuList.forEach((singleSku) => {
        storeDataBySku[singleSku] = [];
        storeList.forEach((storeResult) => {
          if (storeResult?.skus) {
            const skuInventory = storeResult.skus.findIndex((s) => s.sku === singleSku.toString());
            const { store: storeRes, distance, units } = storeResult;
            if (
              storeResult.skus?.[skuInventory] &&
              (Number(storeResult.skus[skuInventory].qty.ats) > 0 ||
                Number(storeResult.skus[skuInventory].qty.bopl) > 0 ||
                Number(storeResult.skus[skuInventory].qty.isa) > 0)
            ) {
              storeDataBySku[singleSku].push({
                storeRes,
                distance,
                units,
                inventory: storeResult.skus[skuInventory].qty,
              });
            }
          }
        });
      });
      return Object.keys(storeDataBySku).length ? storeDataBySku : null;
    }
    return null;
  },
);
