import { createSlice } from '@reduxjs/toolkit';
import { CommonState } from '../../types';

export const initialState = {
  headerFooterType: 'header',
  breakpoint: 'desktop',
  storeIdentifierName: 'dsg',
  aos: false,
  windowVariables: {},
  storeName: '',
  brandConfig: {},
} as CommonState;

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCommonState(state, action) {
      // Useful for mass updating multiple pieces of state at once
      return { ...state, ...action.payload };
    },
    setHeaderFooterType(state, action) {
      return { ...state, headerFooterType: action.payload };
    },
    setBreakpoint(state, action) {
      return { ...state, breakpoint: action.payload };
    },
    setStoreIdentifierName(state, action) {
      return { ...state, storeIdentifierName: action.payload };
    },
    setStoreId(state, action) {
      return { ...state, storeId: action.payload };
    },
    setAOS(state, action) {
      return { ...state, aos: action.payload };
    },
    setAPIEndpoints(state, action) {
      return { ...state, apiEndpoints: { ...state.apiEndpoints, ...action.payload } };
    },
    setPartyKey(state, action) {
      return { ...state, partyKey: action.payload };
    },
    setWindowVariables(state, action) {
      return { ...state, windowVariables: { ...state.windowVariables, ...action.payload } };
    },
    setisSkuSelectionUndefined(state, action) {
      return { ...state, isSkuSelectionUndefined: action.payload };
    },
  },
});

export const {
  setCommonState,
  setHeaderFooterType,
  setBreakpoint,
  setStoreIdentifierName,
  setAOS,
  setAPIEndpoints,
  setPartyKey,
  setWindowVariables,
  setisSkuSelectionUndefined,
} = commonSlice.actions;

export default commonSlice.reducer;
