export const aosStoreIdentifierNames = [
  'dsgAos',
  'ggAos',
  'caliaAos',
  'plAos',
  'vrstAos',
  'g3Aos',
  'mjAos',
];

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const ca = typeof document !== 'undefined' ? document.cookie.split(';') : [];
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkStores = (href: string) => {
  if (href && href.includes('http')) return href;
  const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
  // These lines are used for local troubleshooting
  // const redirect = host.includes('localhost.');
  // const redirectURL = 'publiclands';

  const redirect = host.includes('stores.');
  const redirectURL = host.split('.')[1];
  if (redirect) {
    return `https://www.${redirectURL}.com${href}`;
  }
  return href;
};

export const setDataEmTag = (
  titlePrefix: string,
  mainTitle: string,
  subTitle?: string,
  subTitle2?: string,
): string => {
  const regexPattern = /[\s']/g;
  const newMainTitle = mainTitle?.replace(regexPattern, '');
  const newSubTitle = subTitle ? `_${subTitle?.replace(regexPattern, '')}` : '';
  const newSubTitle2 = subTitle2 ? `_${subTitle2?.replace(regexPattern, '')}` : '';
  return `${titlePrefix}_${newMainTitle}${newSubTitle}${newSubTitle2}`;
};

export const openSurveyLink = (id): void => {
  if (typeof window !== 'undefined') {
    window.KAMPYLE_ONSITE_SDK.showForm(id);
  }
};

export const formatTitleCase = (text = ''): string => {
  if (!text) {
    return '';
  }
  return text.replace(/(\w)(\w*)/g, (g0, g1, g2) => {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const assignChainName = (chain: string): string => {
  const updatedChain = chain.replace(/ /g, '_');
  const chains: { [key in string]: string } = {
    DICKS_SPORTING_GOODS: "DICK'S",
    GOLF_GALAXY: 'Golf Galaxy',
  };
  return chains[updatedChain] !== undefined ? chains[updatedChain] : formatTitleCase(chain);
};

export const getStoreChainName = (chain: string): string => {
  const updatedChain = chain.replace(/ /g, '_');
  const chains: { [key in string]: string } = {
    DICKS_SPORTING_GOODS: 'dsg',
    GOLF_GALAXY: 'gg',
    PUBLIC_LANDS: 'pl',
    MOOSEJAW: 'mj',
  };
  return chains[updatedChain] !== undefined ? chains[updatedChain] : chain;
};

export const cleanSearchTerm = (searchString: string): string => {
  return searchString
    .replace(/[![{}@#$%^&*?"()_=:;,<>\\'`~‘’“”]+/g, '')
    .replace(/[+]+/g, ' ')
    .replace(']', '')
    .replace(/\s+/g, ' ');
};

export const deepCompareWithStringify = (varOne, varTwo): boolean => {
  // Deep comparison function to determine equality of two values. Uses JSON.stringify() to compare as strings.
  return JSON.stringify(varOne) === JSON.stringify(varTwo);
};

export const STORE_INFO_URL = 'https://availability.dickssportinggoods.com/api/v1/stores/';

export const setAOSStoreAnalytics = (AOSData) => {
  const data = window.siteData || {};
  data.KioskInfo = AOSData;
};

export const searchifyTerm = (term = '') => {
  return term
    .replace(/[!@#$%^&*?"()_=:;,<>\\'`~]+/g, '')
    .replace(/[+-]+/g, ' ')
    .trim();
};

export const replaceSpaceAndCapitalize = (inputText = '') => {
  // \w+ mean at least of one character that build word so it match every
  // word and function will be executed for every match
  const output = inputText
    .replace(/\w+/g, (txt) => {
      // uppercase first letter and add rest unchanged
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    })
    .replace(/\s/g, ''); // remove any spaces

  return output;
};

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
export const chunkArray = (myArray, chunk_size) => {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
};

export const getStoreIdentifierNameFromVh = (virtualHostValue: string): string => {
  switch (virtualHostValue) {
    case '111':
      return 'dsg';
    case '112':
      return 'dsgaos';
    case '121':
      return 'gg';
    case '125':
      return 'ggaos';
    case '141':
    case '142':
      return 'calia';
    case '151':
    case '152':
      return 'vrst';
    case '161':
      return 'pl';
    case '162':
      return 'plaos';
    case '163':
      return 'plf';
    case '171':
    case '172':
      return 'g3';
    case '500':
    case '501':
      return 'sm';
    case '181':
    case '182':
      return 'mj';
    default:
      return 'dsg';
  }
};

export const handleModalLeftPosition = (
  elementToBePositioned: string,
  viewPortBreakpoint: string,
) => {
  const searchBarDomRect = document.getElementById('searchContent')?.getBoundingClientRect();
  const element = document.getElementById(elementToBePositioned);
  if (element && element !== null && searchBarDomRect !== undefined) {
    if (viewPortBreakpoint === 'desktop') {
      element.style.left = `${(searchBarDomRect.left - 16).toString()}px`;
    } else {
      element.style.left = `${searchBarDomRect.left.toString()}px`;
    }
  }
};

export const handleSaytTopPositionMobile = () => {
  const ele = document.getElementById('saytDrawerContainer');
  const container = ele?.querySelectorAll<HTMLElement>('.hmf-modal-container')[0];
  if (container && container !== null) {
    container.style.top = '0';
  }
};

export const handleSaytTopNonMobile = (viewPortBreakpoint: string) => {
  const element = document.getElementById('saytDrawerContainer');
  const headerSearchBar =
    viewPortBreakpoint !== 'desktop'
      ? document.querySelectorAll('.dsg-header-search-sticky-enabled')[0]
      : document.getElementById('headerSearchBar');
  if (element && element !== null && headerSearchBar && headerSearchBar !== null) {
    const domRect = headerSearchBar.getBoundingClientRect();
    element.style.top = `${(domRect.bottom + 8).toString()}px`;
  }
};

export const getSayTEndpoint = (autosuggestBaseApi: string) => {
  if (autosuggestBaseApi?.includes('api-search.dksxchange.com')) {
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.host.includes('dksxchange.com') &&
      window.location.host.startsWith('prod-')
    ) {
      return autosuggestBaseApi.concat('load/catalog-autosuggest/');
    }
    return autosuggestBaseApi.concat('qa/catalog-autosuggest/');
  }
  return autosuggestBaseApi;
};

export const getSiteFromPathname = (pathname: string): string => {
  const regex = /^\/([^/]+)/;
  const match = regex.exec(pathname);
  const firstPathSegment = match ? match[1] : '';
  switch (firstPathSegment) {
    case 'p':
    case 'f':
      return '/p';
    case 'OrderItemDisplay':
      return '/cart';
    case 'c':
      return '/c';
    case 'MyAccount':
      return '/maui';
    default:
      return '/home';
  }
};

export const searchTypeAnalyticsReference: { [key: string]: string } = {
  keyword: 'searchTerm',
  brand: 'brands',
  category: 'categories',
};
