import React, { ReactNode } from 'react';
import useLogger from 'hooks/useLogger';

type Props = {
  errorMessage?: string;
  componentId?: string;
  children: ReactNode;
  logError?: (error: Error, errorInfo: React.ErrorInfo) => void;
};

type State = {
  hasError: boolean;
};

type ErrorBoundaryWithLoggerProps = {
  children: React.ReactNode;
  errorMessage?: string;
  componentId?: string;
};

class ErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Invoke the logError function if provided
    const { logError = () => {} } = this.props;
    if (logError) {
      logError(error, errorInfo);
    } else {
      // eslint-disable-next-line no-console
      console.error(error, errorInfo);
    }
  }

  render() {
    const { hasError } = this.state;
    const { errorMessage = '', componentId = '', children } = this.props;
    if (hasError) {
      return errorMessage || componentId || null;
    }

    return children;
  }
}

const ErrorBoundaryWithLogger = ({
  errorMessage = '',
  componentId = '',
  children,
}: ErrorBoundaryWithLoggerProps) => {
  const { error: logError } = useLogger();

  const handleLogError = (error: Error, errorInfo: React.ErrorInfo) => {
    logError(`ErrorBoundary caught an error in ${componentId}: ${error?.message}`, {
      ErrorMessage: error?.message.toString(),
      ErrorName: error?.name.toString(),
      ErrorStack: error?.stack ? error.stack.toString() : 'No stack trace available',
    });
  };

  return (
    <ErrorBoundary errorMessage={errorMessage} componentId={componentId} logError={handleLogError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWithLogger;
