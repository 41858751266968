import { useState, useEffect } from 'react';
import { useAppSelector } from '../store/store';
import { partyKeySelector, storeIdentifierNameSelector } from '../store/selectors/commonSelector';
import useInterval from './useInterval';
import useLogger from './useLogger';

const usePARTY = () => {
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const partyKey = useAppSelector(partyKeySelector);
  const [nikeConnected, setNikeConnected] = useState({ id: 'nike_connected', value: 'false' });
  const { warn } = useLogger();

  const [id, clear] = useInterval(() => {
    if (window.party && partyKey && storeIdentifierName === 'dsg') {
      window.party
        .getPartyAttributes('tof', partyKey)
        .then((result) => {
          setNikeConnected(result.attributes.find((attrib) => attrib.id === 'nike_connected'));
        })
        .catch((e) => {
          warn('usePARTY', { ErrorMessage: 'Error in retrieving the party attributes', Error: e });
        });
      clear(id);
    }
  }, 5000);

  useEffect(() => {
    if (nikeConnected && nikeConnected.value) {
      clear(id);
    }
  }, [nikeConnected]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      clear(id);
    }, 10000);

    return () => {
      clearTimeout(timeout);
      clear(id);
    };
  }, []);

  return [nikeConnected];
};

export default usePARTY;
