import React, { Ref, useEffect, useState, useRef } from 'react';
import { Button, TextInput, Drawer } from '@dsg-tech/homefield-react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setCookie } from 'utility/cookie-utils';
import {
  handleSDDLocationChangeAnalyticsEvent,
  handleModalOpenEvent,
} from 'utility/analytics-events';
import {
  getFeatureFlagByNameSelector,
  storeIdentifierNameSelector,
} from 'store/selectors/commonSelector';
import { deliveryZipSelector } from 'store/selectors/locationServicesSelector';
import { setDeliveryZip } from 'store/slices/locationServices';
import ErrorBoundaryWithLogger from 'components/common/ErrorBoundary/ErrorBoundary';
import { KEY_CODES } from 'utility/constants';
import './SameDayDeliveryModal.scss';

type Props = {
  headerRef?: Ref<any>;
};

const SameDayDeliveryModal = ({ headerRef }: Props) => {
  const dispatch = useAppDispatch();
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const sameDayDeliveryEnabled = useAppSelector(
    getFeatureFlagByNameSelector('sameDayDeliveryEnabled'),
  );
  const deliveryZip = useAppSelector(deliveryZipSelector);
  const [modalZip, setModalZip] = useState('');
  const [inputError, setInputError] = useState(false);
  const [sddModalOpen, setSddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const updatingRef = useRef(false);

  const sameDayDelivery = (callback?: (value: string) => string) => {
    if (!sddModalOpen) {
      setSddModalOpen(true);
      handleModalOpenEvent(storeIdentifierName, 'Update Delivery Location');
    }
  };

  useEffect(() => {
    if (deliveryZip && deliveryZip !== modalZip) {
      setModalZip(deliveryZip);
    }
  }, [deliveryZip]);

  useEffect(() => {
    window.headerFunctions = {
      ...(window.headerFunctions && { ...window.headerFunctions }),
      sameDayDelivery,
    };
  }, []);

  useEffect(() => {
    if (!sddModalOpen) {
      updatingRef.current = false;
    }
  }, [sddModalOpen]);

  const handleUpdate = () => {
    if (/\b\d{5}\b/.test(modalZip)) {
      updatingRef.current = true;
      dispatch(setDeliveryZip(modalZip));
      setCookie('deliveryZip', modalZip);
      handleSDDLocationChangeAnalyticsEvent(modalZip);
      setInputError(false);
      setErrorMessage('');
      setSddModalOpen(false);

      // Fire event to let others know the delivery zip changed
      const evt = new CustomEvent('HeaderServices:SameDayDelivery:Change', { detail: modalZip });
      window.dispatchEvent(evt);
    } else {
      setInputError(true);
      setErrorMessage('Please enter a zip code');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      e.preventDefault();
      handleUpdate();
    }
  };

  return (
    <Drawer
      className="sdd-modal"
      anchorElement={headerRef}
      open={sddModalOpen}
      onCloseModal={() => {
        setSddModalOpen(false);
      }}>
      <ErrorBoundaryWithLogger>
        <div className="sdd-modal-v2-content">
          <div className="sdd-modal-header hmf-mb-xxxs hmf-label-l">Update Delivery Location</div>
          <div className="sdd-modal-body hmf-p-m">
            <div className="sdd-modal-instructions hmf-subheader-m">
              {sameDayDeliveryEnabled ? (
                <p className="hmf-m-0">
                  Please enter your ZIP code to check Same Day Delivery availability in your area.
                </p>
              ) : (
                <p className="hmf-m-0">
                  Please enter your ZIP code to check delivery availability in your area
                </p>
              )}
            </div>
            <div className="sdd-modal-input hmf-display-flex hmf-align-items-flex-start hmf-my-m">
              <TextInput
                type="number"
                value={modalZip}
                error={inputError}
                onChange={(e) => {
                  setModalZip(e.currentTarget.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder={modalZip || 'Enter Zip Code'}
                helperText={errorMessage}
              />
              <Button
                text="Update"
                className="sdd-modal-button hmf-m-0"
                variant="primary"
                onClick={handleUpdate}
              />
            </div>
          </div>
        </div>
      </ErrorBoundaryWithLogger>
    </Drawer>
  );
};

export default SameDayDeliveryModal;

SameDayDeliveryModal.defaultProps = {
  headerRef: undefined,
};
