import axios from 'axios';
import { useState } from 'react';
import { storeIdentifierNameSelector, apiEndpointsSelector } from '../store/selectors';
import { useAppSelector } from '../store/store';
import useLogger from './useLogger';

const useMobileSignUp = () => {
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const apiEndpoints = useAppSelector(apiEndpointsSelector);
  const [mobileSignUpErrorMessage, setMobileSignUpErrorMessage] = useState<string>('');
  const [isMobileSignUpSuccess, setIsMobileSignUpSuccess] = useState<boolean>(false);
  const { warn } = useLogger();

  const validatePhoneNumber = (phoneNumber: string) => {
    const regex = /^\(\d{3}\)\d{3}-\d{4}$/;
    if (!regex.test(phoneNumber)) {
      setMobileSignUpErrorMessage('Please enter a 10-digit mobile number');
      return false;
    }
    setMobileSignUpErrorMessage('');
    return true;
  };

  const makeSmsSignUpCall = (phoneNumber: string) => {
    const formattedPhoneNumber = phoneNumber.replace(/[()-]/g, '');
    if (validatePhoneNumber(phoneNumber)) {
      const requestURL = `${apiEndpoints.smsSignUpUrl}/ec/v1/sms/${formattedPhoneNumber}/opt-in`;
      axios(requestURL, {
        method: 'POST',
        timeout: 2000,
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': apiEndpoints.smsSignUpApiKey,
        },
        data: JSON.stringify({
          brand: storeIdentifierName,
          phoneNumber: formattedPhoneNumber,
          source: 'website',
        }),
      })
        .then((res) => {
          setIsMobileSignUpSuccess(true);
          setMobileSignUpErrorMessage('');
        })
        .catch((err) => {
          warn('useMobileSignUp', { ErrorMessage: 'Error in makeSmsSignUpCall', Error: err });
          setMobileSignUpErrorMessage('Something went wrong. Please try again');
        });
    }
  };

  return {
    makeSmsSignUpCall,
    mobileSignUpErrorMessage,
    isMobileSignUpSuccess,
    validatePhoneNumber,
  };
};

export default useMobileSignUp;
