/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { getCookie, getSwimlaneValue } from 'utility/cookie-utils';
import { WindowContext } from 'utility/windowContext';
import { useAppSelector } from 'store/store';
import {
  environmentConfigSelector,
  getFeatureFlagByNameSelector,
  storeIdentifierNameSelector,
} from 'store/selectors';
import { LoggerAdditionalType, LoggerConfig, LoggerLevel, LoggerMessageType } from 'types';

declare global {
  interface Window {
    BUILD_VERSION: string;
  }
}

export const LOGGER_CONFIG_DEFAULTS: LoggerConfig = {
  clientLoggerLevel: LoggerLevel.INFO,
  xhrLoggerLevel: LoggerLevel.ERROR,
  xhrLoggerUrl: undefined,
};

declare const BUILD_VERSION: string;

const levels = ['TRACE', 'DEBUG', 'INFO', 'LOG', 'WARN', 'ERROR', 'OFF'];

export function getColor(level: LoggerLevel) {
  switch (level) {
    case LoggerLevel.TRACE:
      return 'blue';
    case LoggerLevel.DEBUG:
    case LoggerLevel.INFO:
      return 'teal';
    case LoggerLevel.WARN:
      return 'orange';
    case LoggerLevel.ERROR:
      return 'red';
    default:
      // LoggerLevel.LOG:
      return 'gray';
  }
}

function getTimestamp() {
  return new Date().toISOString();
}

const useLogger = (options: LoggerConfig = LOGGER_CONFIG_DEFAULTS) => {
  const logToServerDisabled = useAppSelector(getFeatureFlagByNameSelector('logToServerDisabled'));
  const environmentConfig = useAppSelector(environmentConfigSelector);
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const [isIE, setIsIE] = useState(false);
  const win = useContext(WindowContext);
  const isLocalhost = win?.location ? win.location.hostname === 'localhost.dcsg.com' : false;

  const config = { ...options };

  if (!config.xhrLoggerUrl) {
    // We will use PDP logger service at first, but should eventually migrate to ours
    const site = '/p';
    const { baseUrl } =
      environmentConfig.api.microService.logger.host[storeIdentifierName] ||
      environmentConfig.api.microService.logger.host.dsg;
    // Detect current site and set the logger URL
    config.xhrLoggerUrl = baseUrl + site + environmentConfig.api.microService.logger.path;
  }

  useEffect(() => {
    setIsIE(
      typeof window !== 'undefined' &&
        !!(
          navigator.userAgent.indexOf('MSIE') !== -1 ||
          /Trident\//.exec(navigator.userAgent) ||
          /Edge\//.exec(navigator.userAgent)
        ),
    );

    if (typeof BUILD_VERSION === 'string' && BUILD_VERSION.trim() !== '') {
      window.BUILD_VERSION = BUILD_VERSION;
    }
  }, []);

  const logToConsole = (
    level: LoggerLevel,
    message: LoggerMessageType,
    additional: LoggerAdditionalType,
  ) => {
    if (isIE) {
      // IE specific logging
      console.log({ level: levels[level], message, additional });
    } else {
      console.log(`%c[${levels[level]}]`, `color: ${getColor(level)}`, message, additional || '');
    }
  };

  const logToServer = (
    level: LoggerLevel,
    message: LoggerMessageType,
    additional: LoggerAdditionalType,
  ): Promise<void | AxiosResponse<any, any>> | null => {
    // If the endpoint to log to server is not set or feature flag is disabled or is not an error log or in localhost, return null
    if (!config.xhrLoggerUrl || logToServerDisabled || level !== LoggerLevel.ERROR || isLocalhost)
      return null;

    const host = win?.location ? win.location.host : '';
    const formattedAdditionalMsg =
      typeof additional === 'object' ? additional : { ErrorMessage: additional };
    const formattedMessage = typeof message === 'object' ? JSON.stringify(message) : message;
    console.log(additional);

    const data = {
      level: levels[level],
      message: `NSE LOGGER: ${formattedMessage}`,
      applicationId: environmentConfig.applicationId,
      swimLane: getSwimlaneValue(host).toString(),
      date: getTimestamp(),
      optionalParameters: {
        quantumMetricSessionID: getCookie('QuantumMetricSessionID'),
        storeIdentifier: storeIdentifierName,
        ...(formattedAdditionalMsg && typeof formattedAdditionalMsg === 'object'
          ? { ...formattedAdditionalMsg }
          : {}),
      },
      BUILD_VERSION: process.env.BUILD_DATE,
    };

    // Only log to server if outside of localhost
    return axios.post(config.xhrLoggerUrl, data).catch((err) => {
      logToConsole(
        LoggerLevel.ERROR,
        `Logger._logToServer -- XHR Log Request failed: ${err}`,
        additional,
      );
    });
  };

  const log = (
    level: LoggerLevel,
    message: LoggerMessageType,
    additional: LoggerAdditionalType,
  ) => {
    if (!message) return;
    logToConsole(level, message, additional);
    logToServer(level, message, additional);
  };

  const trace = (message: LoggerMessageType, additional?: LoggerAdditionalType) =>
    log(LoggerLevel.TRACE, message, additional);
  const debug = (message: LoggerMessageType, additional?: LoggerAdditionalType) =>
    log(LoggerLevel.DEBUG, message, additional);
  const info = (message: LoggerMessageType, additional?: LoggerAdditionalType) =>
    log(LoggerLevel.INFO, message, additional);
  const logMessage = (message: LoggerMessageType, additional?: LoggerAdditionalType) =>
    log(LoggerLevel.LOG, message, additional);
  const warn = (message: LoggerMessageType, additional?: LoggerAdditionalType) =>
    log(LoggerLevel.WARN, message, additional);
  const logError = (message: LoggerMessageType, additional?: LoggerAdditionalType) =>
    log(LoggerLevel.ERROR, message, additional);

  return {
    trace,
    debug,
    info,
    log: logMessage,
    warn,
    error: logError,
    logToServer,
  };
};

export default useLogger;
