import React, { useEffect, useState } from 'react';
import ErrorBoundaryWithLogger from 'common/ErrorBoundary/ErrorBoundary';
import { HomefieldIconSignCancel } from '@dsg-tech/homefield-react-icons';
import { Modal as HomefieldModal } from '@dsg-tech/homefield-react';
import { KEY_CODES } from 'utility/constants';
import { StoreIdentifierName } from 'types';
import useHomr from 'hooks/useHomr';
import { checkStores } from 'utility/utility';
import { setCookie, getCookie } from 'utility/cookie-utils';
import { useAppSelector } from 'store/store';
import { storeIdentifierNameSelector } from 'store/selectors/commonSelector';
import CountdownTimer from './CountdownTimer/CountdownTimer';
import './Modal.scss';

interface ModalData {
  topText?: string;
  link: string;
  backgroundColor?: string;
  backgroundImage?: string;
  foregroundColor?: string;
  headline?: string;
  headlineFontSizeV2?: string;
  subheadline?: string;
  subheadlineFontSizeV2?: string;
  bodyCopy?: string;
  bodyCopyFontSize?: string;
  detailsLink?: [{ linkTitle: string; link: string }];
  disclaimer?: [{ bodyCopy: string; backgroundColor: string; foregroundColor: string }];
  countdownTimerV2?: { endDate: string; endTime: string; message: string }[];
  tagCategory: string;
  closeButtonBackgroundColor?: string;
}

type HomrModalData = {
  content: {
    modalV2: ModalData[];
  };
  tsid: string;
};

export default function Modal() {
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [showModal, setShowModal] = useState(false);
  const modalHomrData = useHomr('modal', 'default', storeIdentifierName as StoreIdentifierName);

  const modalContent = (modalHomrData?.[0] as HomrModalData)?.content;

  useEffect(() => {
    if (modalContent && Object.entries(modalContent).length > 0) {
      const { tsid } = modalHomrData[0] as HomrModalData;
      const cookieName = `modal${tsid}`;
      if (
        getCookie(cookieName) === null &&
        modalContent?.modalV2[0]?.link &&
        !window.location.pathname.includes('editor.html')
      ) {
        setModalData({ ...modalContent?.modalV2[0] });
        setShowModal(true);
        const date = new Date();
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
        const cookieValue = `; expires=${date.toUTCString()}`;
        setCookie(cookieName, cookieValue);
      }
    } else {
      setModalData(null);
      setShowModal(false);
    }
  }, [modalHomrData]);

  const closeModal = (event?: any) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    setShowModal(false);
  };

  const getEndTime = () => {
    return `${modalData?.countdownTimerV2?.[0]?.endDate}T${modalData?.countdownTimerV2?.[0]?.endTime}:00.000Z`;
  };

  const getUserModalStyles = (data: ModalData | null) => {
    const styles: any = {
      backgroundColor: data?.backgroundColor,
      color: data?.foregroundColor,
    };

    if (data?.backgroundImage) {
      styles.backgroundImage = `url(https://dks.scene7.com/is/image/dksfed/${data.backgroundImage})`;
    }

    return styles;
  };

  const getCloseButtonStyles = (data: ModalData | null) => {
    const styles: any = {
      backgroundColor: data?.closeButtonBackgroundColor,
      color: data?.foregroundColor,
    };

    if (data?.closeButtonBackgroundColor) {
      styles.backgroundColor = data.closeButtonBackgroundColor;
    }

    return styles;
  };

  const getUserDisclaimerStyles = (data: ModalData | null) => {
    if (!data?.disclaimer?.[0]) {
      return {};
    }

    return {
      backgroundColor: data?.disclaimer[0].backgroundColor,
      color: data?.disclaimer[0].foregroundColor,
    };
  };

  const fontFamilyMap: { [key: string]: { [key: string]: string } } = {
    headline: {
      'display-xl': 'hmf-display-m',
      'display-lg': 'hmf-display-m',
      'display-md': 'hmf-display-m',
      'display-sm': 'hmf-display-s',
      'display-xs': 'hmf-display-xs',
      default: 'hmf-display-m',
    },
    subheadline: {
      'heading-xl': 'hmf-header-xl',
      'heading-lg': 'hmf-header-l',
      'heading-md': 'hmf-header-m',
      'heading-sm': 'hmf-header-s',
      'heading-xs': 'hmf-header-xs',
      default: 'hmf-header-m',
    },
    body: {
      'body-reg-lg': 'hmf-body-l',
      'body-reg-md': 'hmf-body-m',
      'body-reg-sm': 'hmf-body-s',
      'body-bold-lg': 'hmf-body-bold-l',
      'body-bold-md': 'hmf-body-bold-m',
      'body-bold-sm': 'hmf-body-bold-s',
      default: 'hmf-body-m',
    },
  };

  const getFontFamily = (type: string, font: string | undefined) => {
    const resolvedFont = (font === '' ? 'default' : font) ?? 'default';
    return fontFamilyMap[type]?.[resolvedFont] || '';
  };

  const userModalStyles = getUserModalStyles(modalData);
  const closeButtonStyles = getCloseButtonStyles(modalData);
  const hasDisclaimer = modalData?.disclaimer?.[0];
  const userDisclaimerStyles = getUserDisclaimerStyles(modalData);

  return (
    <ErrorBoundaryWithLogger>
      {showModal && modalData !== null && (
        <div className="modal-wrapper hmf-display-none hmf-display-md-flex">
          <HomefieldModal open={showModal} onCloseModal={() => closeModal()}>
            <div
              style={userModalStyles}
              className={`modal-content-wrapper hmf-display-flex hmf-align-items-center hmf-flex-column hmf-text-align-center ${
                modalData.topText ? 'hmf-pt-xs' : ''
              }`}>
              {modalData.topText && (
                <div className="hmf-label-l top-text hmf-pt-s">{modalData.topText}</div>
              )}
              <a
                href={checkStores(modalData.link)}
                className="modal-link hmf-p-xs hmf-display-flex hmf-align-items-center hmf-flex-column hmf-justify-content-evenly hmf-text-decoration-none"
                tabIndex={modalData.link ? 0 : -1}
                data-em={`modal_${modalData.tagCategory}`}
                aria-live="off">
                {modalData.headline && (
                  <div
                    className={`${getFontFamily(
                      'headline',
                      modalData.headlineFontSizeV2,
                    )} hmf-py-xxs`}>
                    {modalData.headline}
                  </div>
                )}
                {modalData.subheadline && (
                  <div
                    className={`${getFontFamily(
                      'subheadline',
                      modalData.subheadlineFontSizeV2,
                    )} hmf-py-xxs`}>
                    {modalData.subheadline}
                  </div>
                )}
                {modalData.bodyCopy && (
                  <div
                    className={`${getFontFamily('body', modalData.bodyCopyFontSize)} hmf-py-xxs`}>
                    {modalData.bodyCopy}
                  </div>
                )}
                {modalData?.countdownTimerV2?.[0]?.endTime && (
                  <CountdownTimer
                    endDate={getEndTime()}
                    message={modalData.countdownTimerV2[0].message}
                  />
                )}
              </a>
              {hasDisclaimer?.bodyCopy && (
                <div
                  className="disclaimer hmf-legal-xs hmf-p-xxs hmf-text-align-center"
                  style={userDisclaimerStyles}>
                  {hasDisclaimer.bodyCopy}
                  {modalData?.detailsLink?.[0].link && (
                    <a
                      href={checkStores(modalData.detailsLink[0].link)}
                      className="details-link hmf-ml-xxxs hmf-text-decoration-underline"
                      tabIndex={0}>
                      {modalData.detailsLink[0].linkTitle}
                    </a>
                  )}
                </div>
              )}
              <button
                className="close-button hmf-p-xs"
                type="button"
                tabIndex={0}
                onClick={(event) => {
                  closeModal(event);
                }}
                style={closeButtonStyles}
                onKeyDown={(event) => {
                  if (event.keyCode === KEY_CODES.ENTER || event.keyCode === KEY_CODES.SPACE) {
                    closeModal(event);
                  }
                }}
                aria-label="Close button">
                <HomefieldIconSignCancel />
              </button>
            </div>
          </HomefieldModal>
        </div>
      )}
    </ErrorBoundaryWithLogger>
  );
}
