import { useState, useEffect } from 'react';
import axios from 'axios';
import { getCookie, setCookie } from 'utility/cookie-utils';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  environmentConfigSelector,
  storeIdentifierNameSelector,
} from 'store/selectors/commonSelector';
import {
  setAthleteZip,
  setDeliveryZip,
  setStoreDetails,
  setStoreList,
} from 'store/slices/locationServices';
import useLogger from 'hooks/useLogger';

const OnPageLoadLocationHandler = () => {
  const dispatch = useAppDispatch();
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const environmentConfig = useAppSelector(environmentConfigSelector);
  const [storeCookieVal] = useState(getCookie('setStoreCookie'));
  const [deliveryZipCookieVal] = useState(getCookie('deliveryZip'));
  const [whereaboutsCookieVal] = useState(getCookie('whereabouts'));
  const { warn } = useLogger();

  const { baseUrl, key } =
    environmentConfig.api.availability[storeIdentifierName] ||
    environmentConfig.api.availability.dsg;

  const setStoreDataFromAPI = (userLocation, isWhereAboutCookie = false) => {
    const storeIdentifier = ['dsg', 'gg', 'pl', 'mj'].includes(storeIdentifierName)
      ? 'dsg,gg,pl,mj'
      : storeIdentifierName;

    axios
      .get(
        `${baseUrl}/api/v4/stores/search?addr=${userLocation}&radius=100&lob=${storeIdentifier}`,
        {
          headers: { 'X-API-KEY': key },
          timeout: 20000,
        },
      )
      .then((response) => {
        if (
          response.data &&
          response.data.results !== undefined &&
          response.data.results.length !== 0
        ) {
          dispatch(setStoreDetails(response.data.results[0].store));

          // Fire event to let others know the store location changed
          const evt = new CustomEvent('HeaderServices:StoreSelection:Change', {
            detail: response.data.results[0].store,
          });
          window.dispatchEvent(evt);

          dispatch(setStoreList(response.data.results));
          const { zip, name, location, status } = response.data.results[0].store;
          const setStoreCookieString = `${zip}_${name}_${location}_${status}`;
          setCookie('setStoreCookie', setStoreCookieString);
          setCookie('DCSG_NGX_CUST_STORE', setStoreCookieString);
        }

        const geocodedZip = response?.data?.origin?.geocoded_address.match(/\d{5}/g)[0];
        if (!deliveryZipCookieVal) {
          // deliveryZip cookie doesn't exist
          if (isWhereAboutCookie) {
            // setting deliveryZip cookie to whereabouts cookie
            setCookie('deliveryZip', userLocation);
            dispatch(setDeliveryZip(userLocation));
            dispatch(setAthleteZip(userLocation.match(/\d{5}/g)[0]));

            // Fire event to let others know the same day zip changed
            const evt = new CustomEvent('HeaderServices:SameDayDelivery:Change', {
              detail: userLocation,
            });
            window.dispatchEvent(evt);
          } else {
            // setting deliveryZip cookie to zip extracting from geocoded_address
            setCookie('deliveryZip', geocodedZip);
            dispatch(setDeliveryZip(geocodedZip));
            dispatch(setAthleteZip(geocodedZip));

            // Fire event to let others know the same day zip changed
            const evt = new CustomEvent('HeaderServices:SameDayDelivery:Change', {
              detail: geocodedZip,
            });
            window.dispatchEvent(evt);
          }
        } else {
          // This is a odd scenario case where someone doesn't have a setStoreCookie but does have deliveryZip (maybe deleted it?)
          dispatch(setDeliveryZip(deliveryZipCookieVal));
          dispatch(setAthleteZip(geocodedZip));
        }
      })
      .catch((e) => {
        warn('OnPageLoadLocationHandler - ', e);
      });
  };

  const getLocationWithLatAndLng = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = `${position.coords.latitude},${position.coords.longitude}`;
        setStoreDataFromAPI(userLocation);
      },
      () => {
        // No action for geolocation failure
      },
      {
        timeout: 20000,
      },
    );
  };

  const getLocationWithStoreCookieVal = () => {
    // check if setStoreCookie exists and contains something close to what we expect it to
    if (storeCookieVal && storeCookieVal.split('_').length < 3) {
      // setStoreCookie contains something funky lets prevent the store details call
      if (whereaboutsCookieVal) {
        // update state and set delivery zip of whereabouts exists
        setCookie('deliveryZip', whereaboutsCookieVal);
        dispatch(setDeliveryZip(whereaboutsCookieVal));
        dispatch(setAthleteZip(whereaboutsCookieVal));
      }
      return;
    }

    // storeCookie exists
    const storeCookie = storeCookieVal.split('_');
    const location = storeCookie[2];

    axios
      .get(`${baseUrl}/api/v3/stores/${location}`, {
        timeout: 20000,
      })
      .then((response) => {
        dispatch(setStoreDetails(response.data));

        // Fire event to let others know the store location changed
        const storeChangeEvt = new CustomEvent('HeaderServices:StoreSelection:OnLoad', {
          detail: response.data,
        });
        window.dispatchEvent(storeChangeEvt);

        dispatch(setAthleteZip(storeCookie[0].match(/\d{5}/g)[0]));
        const { zipcode, name, status } = response.data;
        const storeVal =
          response.data.store !== undefined ? response.data.store : response.data.location;
        const setStoreCookieString = `${zipcode}_${name}_${storeVal}_${status}`;
        setCookie('setStoreCookie', setStoreCookieString);
        if (!deliveryZipCookieVal) {
          // deliveryZip cookie does not exist
          setCookie('deliveryZip', whereaboutsCookieVal);
          dispatch(setDeliveryZip(whereaboutsCookieVal));
          // Fire event to let others know the same day zip changed
          const evt = new CustomEvent('HeaderServices:SameDayDelivery:Change', {
            detail: whereaboutsCookieVal,
          });
          window.dispatchEvent(evt);
        } else {
          dispatch(setDeliveryZip(deliveryZipCookieVal));
          // Fire event to let others know the same day zip changed
          const evt = new CustomEvent('HeaderServices:SameDayDelivery:OnLoad', {
            detail: deliveryZipCookieVal,
          });
          window.dispatchEvent(evt);
        }
      })
      .catch((e) => {
        warn('OnPageLoadLocationHandler - ', e);
      });
  };

  useEffect(() => {
    // check if storeCookie exists
    dispatch(setDeliveryZip(deliveryZipCookieVal || whereaboutsCookieVal));
    if (!deliveryZipCookieVal && whereaboutsCookieVal) {
      setCookie('deliveryZip', whereaboutsCookieVal);
      // Fire event to let others know the same day zip changed
      const evt = new CustomEvent('HeaderServices:SameDayDelivery:Change', {
        detail: whereaboutsCookieVal,
      });
      window.dispatchEvent(evt);
    }
    if (
      !storeCookieVal ||
      storeCookieVal === 'null' ||
      storeCookieVal === 'undefined' ||
      (storeCookieVal && storeCookieVal.split('_').length < 3)
    ) {
      // get initial data with whereabouts
      if (whereaboutsCookieVal) setStoreDataFromAPI(whereaboutsCookieVal, true);
      // store cookie does not exist with geolocation permission
      getLocationWithLatAndLng();
    } else {
      // store cookies exist
      getLocationWithStoreCookieVal();
    }
  }, []);

  return null;
};

export default OnPageLoadLocationHandler;
