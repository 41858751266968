import { ReduxState } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export const productDetailsSelector = (state: ReduxState) => state.quickView.productDetails;
export const promotionDataSelector = (state: ReduxState) => state.quickView.promotionData;
export const templateSelector = (state: ReduxState) => state.quickView.template;
export const colorSelector = (state: ReduxState) => state.quickView.color;
export const skuSelector = (state: ReduxState) => state.quickView.sku;
export const clearanceSelector = (state: ReduxState) => state.quickView.clearance;
export const skuCategorySelector = (state: ReduxState) => state.quickView.skuCategory;
export const previewSelector = (state: ReduxState) => state.quickView.preview;
export const ecodeParamSelector = (state: ReduxState) => state.quickView.ecodeParam;
export const referrerParamSelector = (state: ReduxState) => state.quickView.referrerParam;
export const optionalParamsSelector = (state: ReduxState) => state.quickView.optionalParams;
