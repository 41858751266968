export const KEY_CODES = {
  TAB: 9,
  ESCAPE: 27,
  SPACE: 32,
  ENTER: 13,
  BACKSPACE: 8,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
};

export const NEW_KEY_CODES = {
  ESCAPE: 'Escape',
  TAB: 'Tab',
  SPACE: 'Space',
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
};

export const DEVICE_WIDTH = {
  mobile: '767px',
  tablet: '1023px',
  smallDesktop: '1200px',
};

export const VISUAL_TEST_BREAKPOINTS = [
  { width: 1600, height: 929, name: 'desktop' },
  { width: 1024, height: 1366, name: 'small-desktop' },
  { width: 767, height: 1024, name: 'tablet' },
  { width: 430, height: 932, name: 'mobile' },
];

export const VISUAL_TEST_THEMES = [
  { name: 'dsg', headerCode: '111' },
  { name: 'gg', headerCode: '121' },
  { name: 'pl', headerCode: '161' },
];

export const VISUAL_TEST_LOWER_BRAND_THEMES = [
  { name: 'plf', headerCode: '163' },
  { name: 'g3', headerCode: '171' },
];
