import React from 'react';
import { TextLink } from '@dsg-tech/homefield-react';
import { HomefieldIconSignCancel } from '@dsg-tech/homefield-react-icons';
import { MY_ACCOUNT_LISTS_URL } from 'utility/common-utils';
import { LinkMessageType } from '../../../types';

type ToastyContentProps = {
  stage?: string;
  link?: LinkMessageType;
  onClose: () => void;
};

const ToastyContent = ({ stage, link, onClose }: ToastyContentProps) => {
  const contentMap = {
    'Signed In': {
      message: 'Added to ',
      caption: 'Favorites',
      action: true,
    },
    'Signed In Limit': {
      message: `Limit reached, let's `,
      caption: 'clear space.',
      action: true,
    },
    Unknown: {
      message: 'Unexpected error. Please try again later.',
      action: false,
    },
    'Signed In Remove': {
      message: 'Removed from ',
      caption: 'Favorites',
      action: true,
    },
    'Signed In Repeated': {
      message: 'Product already added',
      action: false,
    },
    'Signed In not found': {
      message: 'Product not found',
      action: false,
    },
  };

  const Default = {
    message: 'Configure message...',
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    action: <a href="#">Set action</a>,
  };

  const { message, action, caption } = contentMap[stage] || Default;

  return (
    <div className="hmf-soft-radius hmf-elevated-shadow hmf-position-fixed hmf-display-flex hmf-align-center hmf-py-s hmf-px-m toasty hmf-align-items-center">
      <span className="hmf-header-bold-xs toasty-inline-message hmf-display-flex hmf-align-center hmf-align-self-center">
        {(link && Object.keys(link).length) || action ? (
          <div className="hmf-display-inline action-link hmf-display-flex hmf-align-items-center hmf-justify-content-between">
            <div>
              <span>{link?.intro ?? message}</span>
              <TextLink
                variant="primary"
                type="button"
                data-em="AddedToFavorites_PopUp_ViewLink"
                className="button-message button-link hmf-body-bold-m"
                href={link?.url ?? `${MY_ACCOUNT_LISTS_URL}/Favorites`}>
                {link?.caption ?? caption}
              </TextLink>
            </div>
          </div>
        ) : (
          message
        )}
      </span>
      <HomefieldIconSignCancel
        width={24}
        height={24}
        onClick={onClose}
        className="toasty-cancelIcon"
      />
    </div>
  );
};

export default ToastyContent;
