import { createSelector } from '@reduxjs/toolkit';
import { ReduxState } from '../../types';

export const commonSelector = (state: ReduxState) => state.common;
export const typeSelector = (state: ReduxState) => state.common.headerFooterType;
export const breakpointSelector = (state: ReduxState) => state.common.breakpoint;
export const storeIdentifierNameSelector = (state: ReduxState) => state.common.storeIdentifierName;
export const aosSelector = (state: ReduxState) => state.common.aos;
export const apiEndpointsSelector = (state: ReduxState) => state.common.apiEndpoints;
export const partyKeySelector = (state: ReduxState) => state.common.partyKey;
export const storeIdSelector = (state: ReduxState) => state.common.brandConfig.storeId;
export const featureFlagsSelector = (state: ReduxState) => state.common.featureFlags;
export const windowVariablesSelector = (state: ReduxState) => state.common.windowVariables;
export const storeNameSelector = (state: ReduxState) => state.common.brandConfig.storeName;
export const brandConfigSelector = (state: ReduxState) => state.common.brandConfig;
export const environmentConfigSelector = (state: ReduxState) => state.common.environmentConfig;
export const isSkuSelectionUndefinedSelector = (state: ReduxState) =>
  state.common.isSkuSelectionUndefined;
export const getFeatureFlagByNameSelector = (value: string) =>
  createSelector(
    featureFlagsSelector,
    windowVariablesSelector,
    storeIdentifierNameSelector,
    (featureFlags, windowVariables, storeIdentifierName) => {
      return !!featureFlags[value][storeIdentifierName] || !!windowVariables[value];
    },
  );
