import { useEffect, useRef, useCallback } from 'react';

const useOutsideClickDetector = (
  cb: () => void,
  open: boolean,
  excludeRef?: React.MutableRefObject<HTMLElement>[],
) => {
  const containerRef = useRef<HTMLElement | null>(null);

  const detectClick = useCallback(
    (e: MouseEvent) => {
      if (open && containerRef.current && !containerRef.current.contains(e.target as Node)) {
        if (excludeRef?.some((ref) => ref.current?.contains(e.target as Node))) {
          return;
        }
        cb();
      }
    },
    [open, cb],
  );

  useEffect(() => {
    window.addEventListener('click', detectClick);
    return () => {
      window.removeEventListener('click', detectClick);
    };
  }, [detectClick]);

  return containerRef;
};

export default useOutsideClickDetector;
