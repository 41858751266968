import React, { useEffect, useState, useCallback } from 'react';
import { favoritesListSelector } from 'store/selectors/userFavoritesSelector';
import { useAppDispatch, useAppSelector } from '../store/store';
import { FavoriteListsDetailProductType } from '../types';
import {
  setUserFavorites,
  addUserFavorite,
  removeUserFavorite,
} from '../store/slices/userFavorites';
import { myLockerAPIRequest } from '../utility/common-utils';
import {
  quickviewProductAddToFavoritesEvent,
  quickviewProductRemoveFromFavoritesEvent,
} from '../components/Quickview/analytics/analytics-events';
import useLogger from './useLogger';

const useFavorites = (storeIdentifierName: string) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const products = useAppSelector(favoritesListSelector);
  const { warn, trace } = useLogger();

  const toastyEvent = useCallback((name) => {
    const evt = new CustomEvent('renderToasty', { detail: name });
    window.dispatchEvent(evt);
  }, []);

  const productExistenceCheck = (
    productEcode: string,
    productSku: string | undefined,
    favorites: FavoriteListsDetailProductType[],
  ) =>
    favorites.some(
      ({ ecode, sku }) => productEcode.toUpperCase() === ecode.toUpperCase() && productSku === sku,
    );

  useEffect(() => {
    setLoading(false);
  }, [products]);

  const addFavorite = (
    ecode: string,
    sku = undefined,
    updateFavoritesList: () => void = () => {},
  ) => {
    if (productExistenceCheck(ecode, sku, products)) {
      const evt = new CustomEvent('renderToasty', { detail: 'Signed In Repeated' });
      window.dispatchEvent(evt);
      return;
    }

    setLoading(true);

    const body = {
      products: [
        {
          ecode: ecode.toUpperCase(),
          ...(sku ? { sku } : {}),
        },
      ],
    };
    myLockerAPIRequest('/products', `brand=${storeIdentifierName}`, 'PUT', JSON.stringify(body))
      .then((response) => {
        if (response.status === 200) {
          const dataResponse = response.data[0];
          const { id: responseId, ecode: responseEcode, sku: responseSku } = dataResponse;
          dispatch(
            addUserFavorite({
              id: responseId,
              ecode: responseEcode,
              ...(responseSku ? { sku: responseSku } : {}),
            }),
          );
          const evt = new CustomEvent('renderToasty', { detail: 'Signed In' });
          window.dispatchEvent(evt);
          updateFavoritesList();
          quickviewProductAddToFavoritesEvent(responseEcode, sku);
        }
        setLoading(false);
      })
      .catch((e) => {
        warn('useFavorites', { ErrorMessage: 'Error adding favorite', Error: e });
        const { response } = e;
        if (
          response?.status === 422 &&
          response?.data?.errorIdentifier === 'OVER_CAPACITY_EXCEPTION'
        ) {
          trace('useFavorites', 'OVER_CAPACITY_EXCEPTION');
          toastyEvent('Signed In Limit');
        } else {
          trace('useFavorites', 'Unknown Error');
          toastyEvent('Unknown');
        }
        setLoading(false);
      });
  };

  const removeFavorite = (
    ecode: string,
    sku?: string,
    updateFavoritesList: () => void = () => {},
  ) => {
    if (!productExistenceCheck(ecode, sku, products)) {
      const evt = new CustomEvent('renderToasty', { detail: 'Signed In not found' });
      window.dispatchEvent(evt);
      return;
    }

    setLoading(true);

    const productFavorite = products.find(
      (p: FavoriteListsDetailProductType) => p.ecode === ecode && p.sku === sku,
    );
    myLockerAPIRequest(`/products`, `productIds=${productFavorite.id}`, 'DELETE')
      .then((response) => {
        if (response.status === 202) {
          dispatch(removeUserFavorite(productFavorite));
          toastyEvent('Signed In Remove');
          quickviewProductRemoveFromFavoritesEvent(ecode, sku);
          updateFavoritesList();
        }
        setLoading(false);
      })
      .catch((e) => {
        warn('useFavorites', { ErrorMessage: 'Error removing favorite', Error: e });
        toastyEvent('Unknown');
        setLoading(false);
      });
  };

  const fetchFavorites = () => {
    setLoading(true);
    myLockerAPIRequest('/lists/detail', `brand=${storeIdentifierName}`, 'GET')
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUserFavorites(response.data));
          return;
        }
        warn('useFavorites', {
          ErrorMessage: 'fetchFavorites: Response was successful but with an error',
          Error: response,
        });
        setLoading(false);
      })
      .catch((e) => {
        warn('useFavorites', { ErrorMessage: 'Error fetching favorite', Error: e });
        setLoading(false);
      });
  };

  return {
    addFavorite,
    removeFavorite,
    fetchFavorites,
    loading,
    productExistenceCheck,
  };
};

export default useFavorites;
