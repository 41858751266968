import React, { useState, useEffect } from 'react';
import { Button, TextLink, ActionText } from '@dsg-tech/homefield-react';
import {
  HomefieldIconChevronUp,
  HomefieldIconChevronDown,
  HomefieldIconLocationPinFilled,
  HomefieldIconMobilePhone,
  HomefieldIconSignCircledCancelFilled,
  HomefieldIconSignCheckCircleFilled,
} from '@dsg-tech/homefield-react-icons';
import { handleADA } from '@dsg-tech/homefield-utils';
import useLogger from 'hooks/useLogger';
import { StoreDetails as StoreDetailsType, StoreListData } from 'types';

import { getStoreChainName } from '../../../../utility/utility';
import { envData } from '../../../../environments';
import './StoreDetails.scss';

const dayList = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

type FirstThreeAbbr<S> = S extends `${infer first}${infer second}${infer third}${infer rest}`
  ? Lowercase<`${first}${second}${third}`>
  : never;
type DayAbbr = FirstThreeAbbr<(typeof dayList)[number]>;

export interface Props {
  storeData: StoreListData;
  formatTitleCase: (str: string) => string;
  assignChainName: (chain: string) => string;
  handleSelectStore: (selectedStoreDetails: StoreListData) => void;
  skus: string[] | number[] | null;
  currentSelectedStore: StoreDetailsType | null;
  selectedSku: number | null;
  storeIdentifierName: string;
  showAvailability: boolean;
}

const StoreDetails = ({
  storeData,
  assignChainName,
  formatTitleCase,
  handleSelectStore,
  skus,
  currentSelectedStore,
  selectedSku,
  storeIdentifierName,
  showAvailability,
}: Props) => {
  const [expandStoreDetails, setExpandStoreDetails] = useState(false);
  const [skuAts, setSkuAts] = useState(0);
  const [skuIsa, setSkuIsa] = useState(0);
  const [storeStatus, setStoreStatus] = useState({ msg: '', status: '' });
  const { warn } = useLogger();

  const formatTime = (time: string) => {
    const hours = time.slice(0, 2);
    const hoursNum = +hours;
    const minutes = time.slice(2);
    const period = hoursNum < 12 ? 'am' : 'pm';
    const hoursText = hoursNum > 12 ? hoursNum - 12 : hoursNum;

    return `${hoursText}:${minutes} ${period}`;
  };

  const storePickupDropdown = (hourType = 'storeHours') => {
    return dayList.map((day) => {
      try {
        const key = day.slice(0, 3).toLowerCase() as DayAbbr;

        let opHrs = storeData.store.storeHours[key].split('-');
        if (hourType === 'curbsideHours') {
          opHrs = storeData.store.curbsideHours[key].split('-');
        }

        let operationHours = 'Closed all day';
        if (opHrs[0] !== '0000' || opHrs[1] !== '0000') {
          operationHours = `${formatTime(opHrs[0])} - ${formatTime(opHrs[1])}`;
        }

        return (
          <React.Fragment key={day}>
            <span className="store-details-store-hours-day">{formatTitleCase(day)}</span>
            <span className="store-details-store-hours-time">
              {['1', '2', null].includes(storeData.store.status)
                ? 'Closed all day'
                : operationHours}
            </span>
          </React.Fragment>
        );
      } catch (e: any) {
        warn('StoreDetails - ', e);
        return null;
      }
    });
  };

  const isPickupAvailable = () => {
    const skuQty = storeData?.skus?.filter((data) => data.sku === selectedSku?.toString());

    if (!skuQty || (skuQty && skuQty.length === 0)) return;

    const { ats, isa } = skuQty[0].qty;
    if (Number(ats) >= 0 && Number(isa) >= 0) {
      setSkuAts(parseInt(skuQty[0].qty.ats, 10));
      setSkuIsa(parseInt(skuQty[0].qty.isa, 10));
    }
  };

  useEffect(() => {
    if (selectedSku !== null && storeData !== null && storeData.skus !== undefined) {
      isPickupAvailable();
    }
  }, [storeData, selectedSku]);

  const getStoreStatus = () => {
    const status = {
      when_open: 'Open until',
      after_hours: 'Opens at',
      weekend_after_hours: 'Open',
    };
    const today = new Date();
    const day = today.getDay();
    const hour = today.getHours();
    const minute = today.getMinutes();
    const key = dayList[day].slice(0, 3).toLowerCase() as DayAbbr;
    let opHrs = storeData.store.storeHours[key].split('-');
    const storeOpenHours = Number(opHrs[0].slice(0, 2));
    const storeOpenMinutes = Number(opHrs[0].slice(2));
    const storeCloseHours = Number(opHrs[1].slice(0, 2));
    const storeCloseMinutes = Number(opHrs[1].slice(2));

    if (['1', '2'].includes(storeData.store.status)) {
      setStoreStatus({ msg: `Temporarily Closed`, status: 'after_hours' });
      return;
    }

    if (opHrs[0] === '0000' || opHrs[1] === '0000') {
      setStoreStatus({ msg: `Closed`, status: 'after_hours' });
      return;
    }

    if (
      (hour > storeOpenHours && hour < storeCloseHours) ||
      (hour === storeOpenHours && minute >= storeOpenMinutes) ||
      (hour === storeCloseHours && minute <= storeCloseMinutes)
    ) {
      // open until store closing time
      setStoreStatus({ msg: `${status.when_open} ${formatTime(opHrs[1])}`, status: `when_open` });
    } else if (hour < storeOpenHours || (hour === storeOpenHours && minute < storeOpenMinutes)) {
      // opens same day
      setStoreStatus({
        msg: `${status.after_hours} ${formatTime(opHrs[0])}`,
        status: `after_hours`,
      });
    } else if (day !== 6) {
      // opens next day
      opHrs =
        storeData.store.storeHours[
          dayList[(day + 1) % 7].slice(0, 3).toLowerCase() as DayAbbr
        ].split('-');
      setStoreStatus({
        msg: `${status.after_hours} ${formatTime(opHrs[0])}`,
        status: `after_hours`,
      });
    } else {
      // weekend
      setStoreStatus({
        msg: `${status.weekend_after_hours} ${dayList[(day + 1) % 7]}`,
        status: `after_hours`,
      });
    }
  };

  const createStoreDetailsLink = () => {
    const { store } = storeData;
    if (storeIdentifierName === 'dsg' || storeIdentifierName === 'gg') {
      return `https://stores.${envData[getStoreChainName(store.chain)].domainName}/${store.state}/${
        store.city
      }/${store.location ? store.location : store.store}`;
    }
    return `https://stores.${envData[storeIdentifierName].domainName}/${store.state}/${
      store.city
    }/${store.location ? store.location : store.store}`;
  };

  useEffect(() => {
    if (storeData !== null && storeData.store !== null) {
      getStoreStatus();
    }
  }, [storeData]);

  const hasCurrentlySelectedStore =
    currentSelectedStore !== null &&
    (storeData.store.location === currentSelectedStore.location ||
      storeData.store.location === currentSelectedStore.store);

  return (
    <div data-testid="StoreDetails">
      {hasCurrentlySelectedStore && (
        <div className="store-details-current-store-label hmf-label-s hmf-my-xs">
          <strong>MY STORE</strong>
        </div>
      )}
      <div className="store-details-name-dist-container hmf-display-flex hmf-flex-row hmf-align-items-center  hmf-my-xs">
        <div className="hmf-subheader-bold-l">
          <strong className="hmf-text-transform-capitalize">{`${assignChainName(
            storeData.store.chain,
          )} - ${storeData.store.name.toLowerCase()}`}</strong>
        </div>
        <div className="store-details-distance hmf-label-s">
          <strong className="hmf-display-flex hmf-flex-row">{storeData.distance} MI</strong>
        </div>
      </div>
      <div className="store-details-address ">
        {storeData?.store?.street1 ? storeData.store.street1.toLowerCase() : ''}
      </div>
      {(skus === null || !showAvailability) && (
        <div className="store-details-address  hmf-mb-xxs">
          {`${
            storeData.store?.street2?.toLowerCase() ?? ''
          } ${storeData.store.city.toLowerCase()}, ${storeData.store.state} ${storeData.store.zip}`}
        </div>
      )}
      {selectedSku === null && skus !== null && showAvailability && (
        <div className="hmf-legal-s hmf-my-xxs">
          Select product options to see item availability at each store
        </div>
      )}
      {selectedSku !== null && showAvailability && (
        <div>
          {skuAts < 10 && skuAts > 0 && (
            <div className="hmf-body-s hmf-my-xs store-details-only-few-left-message">
              Only {skuAts} left!
            </div>
          )}
          <div className="store-details-availability-container">
            {skuAts > 0 && skuIsa === 0 && (
              <div className="hmf-body-s hmf-my-xs">In-Store Purchase Only</div>
            )}
            {skuAts > 0 && (
              <div className="hmf-my-xs hmf-display-flex hmf-flex-row hmf-align-items-center">
                <HomefieldIconSignCheckCircleFilled className="store-details-check-svg  hmf-mr-xxs" />
                <div className="hmf-body-s store-details-availability">Available for Pickup</div>
              </div>
            )}
            {skuIsa > 0 && (
              <div className=" hmf-my-xs hmf-display-flex hmf-flex-row hmf-align-items-center">
                <HomefieldIconSignCheckCircleFilled className="store-details-check-svg  hmf-mr-xxs" />
                <div className="hmf-body-s store-details-availability">
                  Available for Purchase in Store
                </div>
              </div>
            )}
            {skuAts === 0 && skuIsa === 0 && (
              <div className="hmf-my-xs hmf-display-flex hmf-flex-row hmf-align-items-center">
                <HomefieldIconSignCircledCancelFilled className="store-details-cancel-svg hmf-mr-xxs" />
                <div className="hmf-body-s store-details-availability">Not Available</div>
              </div>
            )}
          </div>
        </div>
      )}
      {(skus === null || !showAvailability) && (
        <div className={`store-details-status-${storeStatus.status}`}>{storeStatus.msg}</div>
      )}
      <div className="store-details-container hmf-display-flex hmf-flex-row hmf-align-items-center hmf-justify-content-space-between">
        <Button
          className="store-details-button"
          onClick={() => handleSelectStore(storeData)}
          text={hasCurrentlySelectedStore && skus === null ? 'Keep this store' : 'select store'}
          variant={hasCurrentlySelectedStore ? 'primary' : 'secondary'}
        />
        <div className="store-details-show-toggle-container">
          <span className="hmf-display-flex hmf-flex-row hmf-align-items-center hmf-justify-content-flex-end">
            <ActionText
              className="hmf-body-s hmf-body-m-m hmf-display-flex hmf-flex-row hmf-pr-xxxs"
              onClick={() => {
                setExpandStoreDetails(!expandStoreDetails);
              }}
              onKeyPress={(e) =>
                handleADA(e, 'button', () => {
                  setExpandStoreDetails(!expandStoreDetails);
                })
              }>
              <>
                Store Info
                {expandStoreDetails ? (
                  <HomefieldIconChevronUp className="store-details-chevron" />
                ) : (
                  <HomefieldIconChevronDown className="store-details-chevron" />
                )}
              </>
            </ActionText>
          </span>
        </div>
      </div>

      {expandStoreDetails && (
        <>
          {skus !== null && (
            <div data-testid="store-address-section" className="hmf-my-xs">
              <div className="store-details-address">
                {storeData.store && storeData.store.street1
                  ? storeData.store.street1.toLowerCase()
                  : ''}
              </div>
              <div className="store-details-address">
                {`${
                  storeData.store?.street2?.toLowerCase() ?? ''
                } ${storeData.store.city.toLowerCase()}, ${storeData.store.state} ${
                  storeData.store.zip
                }`}
              </div>
            </div>
          )}
          <div data-testid="store-phone-section" className="hmf-display-flex hmf-flex-row hmf-my-s">
            <TextLink
              className="hmf-mr-xs"
              target="_blank"
              href={`https://www.google.com/maps?saddr=My+Location&daddr=${storeData.store.chain},${storeData.store.street1},${storeData.store.street2},${storeData.store.zip}`}>
              <React.Fragment key=".0">
                <HomefieldIconLocationPinFilled className="hmf-mr-xxxs hmf-ml-0" />
                Get Directions
              </React.Fragment>
            </TextLink>
            <TextLink href={`tel:${storeData.store.phone}`}>
              <React.Fragment key=".0">
                <HomefieldIconMobilePhone className="hmf-mr-xxxs hmf-ml-0" />
                {storeData.store.phone}
              </React.Fragment>
            </TextLink>
          </div>
          <div className="store-details-store-hours-title hmf-body-bold-m hmf-my-xs">
            Store Hours
          </div>
          <div className="store-details-store-pickup hmf-mb-s">{storePickupDropdown()}</div>
          {['3', '4'].includes(
            storeData.store.status,
          ) /* Store Status is 3 or 4 display curbside hours */ && (
            <>
              <div className="store-details-store-hours-title hmf-body-bold-m hmf-my-xs">
                Pickup Hours
              </div>
              <div className="store-details-store-pickup">
                {storePickupDropdown('curbsideHours')}
              </div>
            </>
          )}
          <TextLink
            className="hmf-body-bold-m hmf-my-xs store-details-view-more"
            href={createStoreDetailsLink()}
            target="_blank"
            data-em="header_storeDetails">
            View Store Details
          </TextLink>
        </>
      )}
    </div>
  );
};

export default StoreDetails;
