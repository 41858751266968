export const envData = {
  calia: {
    storeId: 15108,
    storeIdentifier: 'calia',
    catalogId: 12301,
    channelId: -1,
    domainName: 'calia.com',
    fonts: ['https://images.dickssportinggoods.com/assets/fonts/montserrat/font.css'],
    signUpUrl: 'https://www.calia.com/athleterecord/services/emailsignup',
  },
  vrst: {
    storeId: 15108,
    storeIdentifier: 'vrst',
    catalogId: 12301,
    channelId: -1,
    domainName: 'vrst.com',
    fonts: ['https://images.dickssportinggoods.com/assets/fonts/tilde/font.css'],
    signUpUrl: 'https://www.vrst.com/athleterecord/services/emailsignup',
  },
  dsg: {
    storeId: 15108,
    storeIdentifier: 'dsg',
    storeName: "DICK's Sporting Goods",
    catalogId: 12301,
    channelId: -1,
    domainName: 'dickssportinggoods.com',
    qaDomainName: 'dksxchange.com',
    fonts: [
      'https://images.dickssportinggoods.com/assets/fonts/dsgsans/font.css',
      'https://images.dickssportinggoods.com/assets/fonts/archivo/font.css',
    ],
    chatUrl: 'https://www.dickssportinggoods.com/s/help-desk',
    signUpUrl: 'https://www.dickssportinggoods.com/athleterecord/services/emailsignup',
    font: `<link rel="preload" href="https://images.dickssportinggoods.com/assets/fonts/archivo/font.css" as="style" /><link rel="preload" href="https://images.dickssportinggoods.com/assets/fonts/dsgsans/font.css" as="style" /><link rel="stylesheet" href="https://images.dickssportinggoods.com/assets/fonts/dsgsans/font.css"><link rel="stylesheet" href="https://images.dickssportinggoods.com/assets/fonts/archivo/font.css">`,
    socialLinks: {
      youtube: 'http://www.youtube.com/user/DicksSportingGoods',
      twitter: 'http://twitter.com/dicks',
      facebook: 'http://www.facebook.com/dickssportinggoods',
      pinterest: 'http://pinterest.com/dkssports',
      instagram: 'https://www.instagram.com/dickssportinggoods/',
    },
  },
  pl: {
    storeId: 16066,
    storeIdentifier: 'pl',
    storeName: 'Public Lands',
    catalogId: 20310,
    channelId: -1,
    domainName: 'publiclands.com',
    qaDomainName: 'dksxchange.com',
    fonts: ['https://images.dickssportinggoods.com/assets/fonts/graphikwide/font.css'],
    signUpUrl: 'https://www.publiclands.com/athleterecord/services/emailsignup',
    socialLinks: {
      twitter: 'https://www.twitter.com/publiclandsshop',
      facebook: 'https://www.facebook.com/publiclandsshop',
      instagram: 'https://www.instagram.com/publiclandsshop',
      youtube: 'https://www.youtube.com/channel/UCq0I2s_omAmGJkis8CAFA4w',
    },
  },
  plf: {
    storeId: 16066,
    storeIdentifier: 'plf',
    catalogId: 20310,
    channelId: -1,
    fonts: ['https://images.dickssportinggoods.com/assets/fonts/graphikwide/font.css'],
    socialLinks: {
      twitter: 'https://www.twitter.com/publiclandsshop',
      facebook: 'https://www.facebook.com/publiclandsshop',
      instagram: 'https://www.instagram.com/publiclandsshop',
      youtube: 'https://www.youtube.com/channel/UCq0I2s_omAmGJkis8CAFA4w',
    },
  },
  g3: {
    storeId: 15108,
    storeIdentifier: 'g3',
    storeName: 'Going Going Gone',
    catalogId: 12301,
    channelId: -1,
    domainName: 'goinggoinggone.com',
    qaDomainName: 'dksxchange.com',
    fonts: [
      'https://images.dickssportinggoods.com/assets/fonts/dsgsans/font.css',
      'https://images.dickssportinggoods.com/assets/fonts/roboto/font.css',
    ],
    chatUrl: 'https://www.goinggoinggone.com/s/help-desk',
    signUpUrl: 'https://www.goinggoinggone.com/athleterecord/services/emailsignup',
    socialLinks: {
      facebook: 'https://www.facebook.com/goinggoinggonestore',
      instagram: 'https://www.instagram.com/goinggoinggone/',
    },
  },
  sm: {
    storeId: 15108,
    storeIdentifier: 'sm',
    catalogId: 12301,
    channelId: -1,
    fonts: [
      'https://images.dickssportinggoods.com/assets/fonts/dsgsans/font.css',
      'https://images.dickssportinggoods.com/assets/fonts/archivo/font.css',
    ],
    socialLinks: {
      youtube: 'http://www.youtube.com/user/DicksSportingGoods',
      twitter: 'http://twitter.com/dicks',
      facebook: 'http://www.facebook.com/dickssportinggoods',
      pinterest: 'http://pinterest.com/dkssports',
      instagram: 'https://www.instagram.com/dickssportinggoods/',
    },
  },
  gg: {
    storeId: 10701,
    storeIdentifier: 'gg',
    storeName: 'Golf Galaxy',
    catalogId: 10051,
    channelId: -1,
    domainName: 'golfgalaxy.com',
    qaDomainName: 'dksxchange.com',
    fonts: [
      'https://images.dickssportinggoods.com/assets/fonts/hindsiliguri/font.css',
      'https://images.dickssportinggoods.com/assets/fonts/vitesse/font.css',
    ],
    chatUrl: 'https://www.golfgalaxy.com/s/customer-service',
    signUpUrl: 'https://www.golfgalaxy.com/athleterecord/services/emailsignup',
    socialLinks: {
      youtube: 'https://youtube.com/golfgalaxy',
      twitter: 'https://twitter.com/golfgalaxy',
      facebook: 'https://facebook.com/golfgalaxy',
      pinterest: 'https://pinterest.com/golfgalaxy',
      instagram: 'https://www.instagram.com/golfgalaxy/',
    },
  },
  mj: {
    storeId: 16066,
    storeIdentifier: 'mj',
    catalogId: 20310,
    storeName: 'MooseJaw',
    channelId: -1,
    domainName: 'moosejaw.com',
    qaDomainName: 'dksxchange.com',
    fonts: ['https://images.dickssportinggoods.com/assets/fonts/nunitosans/font.css'],
    signUpUrl: 'https://www.moosejaw.com/athleterecord/services/emailsignup',
    socialLinks: {
      twitter: 'https://twitter.com/Moosejaw',
      facebook: 'https://www.facebook.com/moosejaw',
      instagram: 'https://www.instagram.com/moosejawmadness',
      youtube: 'https://www.youtube.com/user/MoosejawMadness',
    },
  },
};

export function getSocialLinks(storeName) {
  let { socialLinks } = envData.dsg;
  if (storeName.includes('gg')) {
    socialLinks = envData.gg.socialLinks;
  } else if (storeName.includes('g3')) {
    socialLinks = envData.g3.socialLinks;
  }
  return socialLinks;
}
