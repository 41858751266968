import { startTransition, useContext, useEffect, useState, useCallback } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import axios, { CancelTokenSource } from 'axios';
import { useAppDispatch, useAppSelector } from '../store/store';

import { StoreIdentifierName } from '../types';
import { WindowContext } from '../utility/windowContext';
import { breakpointSelector } from '../store/selectors/commonSelector';
import { useMockTickerOverride, useTickerPageNameOverride } from './override-hooks';
import useLogger from './useLogger';

declare global {
  interface Window {
    homr: {
      namePromise: Promise<string>;
    };
  }
}

/**
 * Reusable Hook for integrating with the homR CMS API
 *
 * Need to test staging homR?
 * Set a sessionStorage item with the key of HEADER-FOOTER-STAGING-HOMR to any value and use a domain with "localhost" in it.
 */
const useHomr = (
  pageType: 'header' | 'footer' | 'ticker' | 'modal' | 'progressive-header' | 'header-featured',
  name: string,
  chain: StoreIdentifierName,
  appDispatch?: ActionCreatorWithPayload<unknown>,
) => {
  const dispatch = useAppDispatch();
  const win = useContext(WindowContext);
  const [homrContent, setHomrContent] = useState<object | null>(null);
  const breakpoint = useAppSelector(breakpointSelector);
  const { error, warn, info } = useLogger();

  // local storage keys that, when set, will allow us to override the information fetched or
  // returned by this hook
  const [tickerPageNameOverride] = useTickerPageNameOverride();
  const [mockTickerOverride] = useMockTickerOverride();

  const validHostsSet = [
    'dickssportinggoods.com',
    'qa6.dksxchange.com',
    'vrst.com',
    'calia.com',
    'goinggoinggone.com',
    'publiclands.com',
    'moosejaw.com',
    'sportsmatter.org',
    'golfgalaxy.com',
  ];

  const getHomrUrl = useCallback(() => {
    const host = win && window.location ? window.location.host : '';
    const isValidHost = validHostsSet.find((validHost) => host.includes(validHost));
    const isStoresHost = host.includes('stores.');
    const storesHost = isStoresHost ? host.split('.')[1] : '';
    const isLocalhost = host.includes('localhost');
    const isDksxchange = /^([a-zA-Z0-9-]+\.)?dksxchange\.com$/.test(host); // check for np0 env
    if (isValidHost && isStoresHost) {
      return `https://www.${storesHost}.com/dcsg-ngx-home/homr/v2/api/v4/content`;
    }
    if (isValidHost && !host.includes('static-search.dickssportinggoods.com')) {
      return '/dcsg-ngx-home/homr/v2/api/v4/content';
    }
    if (sessionStorage.getItem('HEADER-FOOTER-STAGING-HOMR') && (isLocalhost || isDksxchange)) {
      return 'https://active-dsg-dcsg-ngx-home-mono-staging.iso.an01.pcf.dcsg.com/dcsg-ngx-home/homr/v2/api/v4/content';
    }
    return 'https://www.dickssportinggoods.com/dcsg-ngx-home/homr/v2/api/v4/content';
  }, [win]);

  const taxoLoadedEventEmitter = useCallback(() => {
    if (['dsg', 'gg', 'pl', 'mj'].includes(chain)) {
      const taxoLoadedEvent = new CustomEvent(
        breakpoint === 'desktop' ? 'TopNav_Desktop_TaxoLoaded' : 'TopNav_Mobile_TaxoLoaded',
      );
      document.dispatchEvent(taxoLoadedEvent);
    }
  }, [breakpoint, chain]);

  const getPageNameFromHomr = async (): Promise<string | null> => {
    if (win && window?.homr?.namePromise && window.location.pathname.startsWith('/p/')) {
      const value = await window.homr.namePromise;
      return Promise.resolve(value);
    }
    info(`Could not get homr name for ${pageType}`);
    return null;
  };

  const getPageNameFromLocation = useCallback((): string => {
    if (pageType === 'ticker' && tickerPageNameOverride !== null) {
      return tickerPageNameOverride;
    }
    if (win && window?.location?.pathname) {
      if (window.location.pathname === '/search/SearchDisplay') {
        return 'search';
      }
      if (window?.location?.host.includes('static-search.dickssportinggoods.com')) {
        return 'default';
      }
      const splitPath = window.location.pathname.split('/');
      if (splitPath.length > 0) {
        return splitPath[splitPath.length - 1] !== ''
          ? splitPath[splitPath.length - 1]
          : 'homepage';
      }
    }
    return 'default';
  }, [pageType, tickerPageNameOverride]);

  const setPageName = useCallback(async () => {
    const homrPageName = await getPageNameFromHomr();
    if (homrPageName) {
      return homrPageName;
    }
    return getPageNameFromLocation();
  }, [chain, win, getPageNameFromLocation]);

  const makeHomrCall = useCallback(
    (pageName: string, cancelToken: CancelTokenSource) => {
      // if we're setting mock data for the ticker in localStorage, use that to populate the app,
      // rather than fetching it from Homr, and exit early
      if (pageType === 'ticker' && mockTickerOverride !== null) {
        // returning this as a promise here to more accurately simulate the way we normally respond
        // with an axios promise. It also helps with unit tests set data in the correct order
        return Promise.resolve().then(() => {
          startTransition(() => {
            if (appDispatch) dispatch(appDispatch(mockTickerOverride.content));
            setHomrContent(mockTickerOverride);
          });
        });
      }
      return axios
        .get(getHomrUrl(), {
          timeout: 2000,
          params: { pageType, name: pageName, chain },
          cancelToken: cancelToken.token,
        })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            Object.entries(response.data.content).length
          ) {
            startTransition(() => {
              if (appDispatch) dispatch(appDispatch(response.data.content));
              setHomrContent(response.data);
              if (pageType === 'header') taxoLoadedEventEmitter();
            });
          } else {
            warn(`Not able to retrieve homR Data ${pageType} - ${pageName}`);
          }
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            info(`${pageType} request canceled by axios`);
          } else {
            warn(`Error getting HomR Data for ${pageType} - ${pageName}`, e);
          }
        });
    },
    [
      appDispatch,
      chain,
      dispatch,
      getHomrUrl,
      pageType,
      taxoLoadedEventEmitter,
      mockTickerOverride,
    ],
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (pageType === 'ticker' || pageType === 'modal') {
      (async () => {
        const newPageName = await setPageName();
        makeHomrCall(newPageName, cancelToken);
      })();
    } else {
      makeHomrCall(name, cancelToken);
    }
    return () => {
      cancelToken.cancel();
    };
  }, [makeHomrCall, name, setPageName]);

  return [homrContent];
};

export default useHomr;
