import { useEffect, useState } from 'react';
import { TrendingSearch } from '../types';

declare global {
  interface Window {
    trendingSearchData: TrendingSearch;
  }
}

const useTrendingSearchDataFromWindow = () => {
  const [trendingSearchWindowData, setTrendingSearchWindowData] = useState<TrendingSearch | null>(
    null,
  );

  const handleTrendingSearchData = () => {
    if (window.trendingSearchData) {
      setTrendingSearchWindowData(window.trendingSearchData);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'HeaderServices:TrendingSearchData',
      handleTrendingSearchData as EventListener,
    );
    handleTrendingSearchData();

    return () => {
      document.removeEventListener(
        'HeaderServices:TrendingSearchData',
        handleTrendingSearchData as EventListener,
      );
    };
  }, []);

  return { trendingSearchWindowData };
};

export default useTrendingSearchDataFromWindow;
