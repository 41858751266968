import { createSlice } from '@reduxjs/toolkit';
import { FavoriteListsDetailProductType, UserState } from '../../types';

export const initialState: UserState = {
  defaultList: [],
  lists: [],
};

export const userFavoritesSlice = createSlice({
  name: 'userFavorites',
  initialState,
  reducers: {
    setLists(state, action) {
      return {
        ...state,
        lists: action.payload,
      };
    },
    setUserFavorites(state, action) {
      return {
        ...state,
        defaultList: [...action.payload.list.products],
      };
    },
    addUserFavorite(state, action) {
      const product = action.payload;
      const { defaultList } = state;
      const newProductsList = [...defaultList, product];
      return {
        ...state,
        defaultList: newProductsList,
      };
    },
    removeUserFavorite(state, action) {
      const product = action.payload;
      const { defaultList } = state;
      const favoritesListContent = defaultList.filter(
        (p: FavoriteListsDetailProductType) =>
          p.ecode !== product.ecode || (p.ecode === product.ecode && p.sku !== product.sku),
      );

      return {
        ...state,
        defaultList: favoritesListContent,
      };
    },
  },
});

export const { setUserFavorites, addUserFavorite, removeUserFavorite, setLists } =
  userFavoritesSlice.actions;

export default userFavoritesSlice.reducer;
