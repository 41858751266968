/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Skeleton } from '@dsg-tech/homefield-react';
import { StoreIdentifierName } from 'types';
import { useAppSelector } from 'store/store';
import { getFeatureFlagByNameSelector } from 'store/selectors';
import ErrorBoundaryWithLogger from 'components/common/ErrorBoundary/ErrorBoundary';
import { checkStores } from '../../../utility/utility';
import { TickerData, TickerResponse } from './Ticker.interface';
import useHomr from '../../../hooks/useHomr';
import ExpandableTicker from './ExpandableTicker/ExpandableTicker';
import CarouselTicker from './CarouselTicker/CarouselTicker';
import './Ticker.scss';

type Props = {
  storeIdentifierName: StoreIdentifierName;
};

const Ticker = ({ storeIdentifierName }: Props) => {
  const tickerHomrData = useHomr('ticker', 'default', storeIdentifierName) as
    | TickerResponse[]
    | null;
  const tickerApiResponse: TickerResponse | null = tickerHomrData ? tickerHomrData[0] : null;
  const expandableTickerFlag = useAppSelector(getFeatureFlagByNameSelector('expandableTicker'));

  const createTickerLink = (linkText: string): string => {
    if (typeof linkText !== 'string') return '';
    if (
      linkText.startsWith('/') ||
      linkText.startsWith('http://') ||
      linkText.startsWith('https://')
    ) {
      return linkText;
    }
    return `/${linkText}`;
  };

  const getTickerMessages = (
    tickerData: TickerData[],
    homeRTickerData: TickerResponse,
    foreGroundColor: string,
  ) => {
    return tickerData.map((ticker, idx: number) => {
      const dataEmTemplateTag = homeRTickerData.templateTag?.replace(/\s/g, '') || '';
      const dataEmLinkTitle = ticker.linkTitle?.replace(/\s/g, '') || '';
      const dataEmTopText = ticker.topText?.replace(/\s/g, '') || '';

      if (ticker.topText !== '') {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx} className="app-ticker-message">
            <div
              className="ticker__section hmf-position-relative"
              style={{ borderColor: foreGroundColor }}>
              <a
                className="hmf-display-flex hmf-flex-row hmf-justify-content-center hmf-text-decoration-none"
                href={checkStores(createTickerLink(ticker.link))}
                target={
                  checkStores(createTickerLink(ticker.link))?.includes('http') ? '_blank' : '_self'
                }
                data-em={`tkr_${dataEmTemplateTag}_${idx + 1}_ticker-${
                  idx + 1
                }_na_na${dataEmLinkTitle}_${dataEmTopText}`}>
                <span
                  className="ticker__message ticker__message--main ticker__message--bold"
                  style={{ color: foreGroundColor }}>
                  {ticker.topText}
                </span>
                <span
                  className="ticker__message ticker__message--disclaimer"
                  style={{ color: foreGroundColor }}>
                  {ticker.bottomText}{' '}
                  <span className="ticker__message--underline">{ticker.linkTitle}</span>
                </span>
              </a>
            </div>
          </div>
        );
      }
      return '';
    });
  };

  const generateTickerData = () => {
    if (!tickerApiResponse) return null;

    const { content, templateName } = tickerApiResponse;
    if (!content || Object.keys(content).length === 0) return null;

    let tickerContentData: TickerData[] = [];
    let foreGroundColor = '';
    let backGroundColor = '';
    let tickerLink = '';

    if (
      templateName === 'ticker' ||
      templateName === 'split_ticker' ||
      templateName === 'carousel_ticker'
    ) {
      const splitTicker = content['Split Ticker'][0];
      foreGroundColor = splitTicker.foregroundColor ?? '';
      backGroundColor = splitTicker.backgroundColor ?? '';
      tickerContentData = splitTicker['Ticker Content'];
      tickerLink = createTickerLink(splitTicker['Ticker Content'][0].link);
    }

    // Replace all tickers with ExpandableTicker when flag is enabled
    if (expandableTickerFlag) {
      return (
        <ErrorBoundaryWithLogger>
          <ExpandableTicker
            tickerData={tickerContentData}
            foregroundColor={foreGroundColor}
            backgroundColor={backGroundColor}
          />
        </ErrorBoundaryWithLogger>
      );
    }

    // Render CarouselTicker if carousel_ticker template is explicitly set
    if (templateName === 'carousel_ticker') {
      return (
        <ErrorBoundaryWithLogger>
          <CarouselTicker
            tickerData={tickerContentData}
            foregroundColor={foreGroundColor}
            backgroundColor={backGroundColor}
          />
        </ErrorBoundaryWithLogger>
      );
    }

    // Default rendering for other tickers when flag is not enabled
    const classes = [
      'rh-ticker-container header-ticker-minimum ticker-dsg hmf-align-items-center hmf-display-flex hmf-flex-row hmf-justify-content-center hmf-position-relative hmf-text-align-center hmf-text-decoration-none',
      tickerContentData.length > 1 ? 'ticker-dsg--multi' : 'ticker-dsg--single',
    ].join(' ');

    return (
      <a href={tickerLink} className={classes} style={{ backgroundColor: backGroundColor }}>
        {getTickerMessages(tickerContentData, tickerApiResponse, foreGroundColor)}
      </a>
    );
  };

  return (
    <>
      {!tickerApiResponse ? (
        <div className="header-ticker-minimum">
          <Skeleton className="header-ticker-minimum" />
        </div>
      ) : (
        generateTickerData()
      )}
    </>
  );
};

export default Ticker;
