import React, { ReactElement, useState, useEffect } from 'react';
import { HomefieldIconSignCancel } from '@dsg-tech/homefield-react-icons';
import './Tooltip.scss';

export interface TooltipProps {
  variant?: 'mouseover' | 'controlled';
  children: ReactElement[] | ReactElement;
  direction: string;
  content: string | ReactElement;
  title?: string;
  delay?: number;
  open?: boolean;
  callback?: () => void;
}

export const Tooltip = ({
  variant,
  children,
  direction,
  content,
  title,
  delay,
  open,
  callback,
}: TooltipProps) => {
  let timeout;
  const [isOpen, setIsOpen] = useState(open);

  const mouseoverShowTip = () => {
    timeout = setTimeout(() => {
      setIsOpen(true);
    }, delay);
  };

  const mouseoutHideTip = () => {
    clearInterval(timeout);
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <button
      type="button"
      className="Tooltip-Wrapper hmf-display-inline-block hmf-position-relative hmf-p-0 hmf-m-0"
      // When to show the tooltip
      onMouseEnter={variant === 'mouseover' ? mouseoverShowTip : () => {}}
      onMouseLeave={variant === 'mouseover' ? mouseoutHideTip : () => {}}
      aria-describedby={isOpen ? 'tooltip-tip' : undefined}>
      {/* Wrapping */}
      {children}
      {isOpen && (
        <div
          role="tooltip"
          id="tooltip-tip"
          className={`Tooltip-Tip hmf-body-s hmf-p-s hmf-position-absolute ${direction || 'top'}`}>
          <button
            type="button"
            className="Tooltip-Tip-closeButton hmf-position-absolute hmf-p-0"
            onClick={() => {
              setIsOpen(false);
              callback();
            }}
            aria-label="Close Sign In Tooltip">
            <HomefieldIconSignCancel aria-hidden height="16px" width="16px" />
          </button>
          {title ? <div className="tooltip-title hmf-body-bold-m">{title}</div> : null}
          {/* Content */}
          <div className="tooltip-content">{content}</div>
        </div>
      )}
    </button>
  );
};

export default Tooltip;

Tooltip.defaultProps = {
  variant: 'mouseover',
  title: '',
  delay: 400,
  open: false,
  callback: () => {},
};
