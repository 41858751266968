import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProgressiveHeaderState {
  selectedCategory: string | null;
  showL1Links: boolean;
}

const initialState: ProgressiveHeaderState = {
  selectedCategory: null,
  showL1Links: false,
};

const progressiveHeaderSlice = createSlice({
  name: 'progressiveHeader',
  initialState,
  reducers: {
    setSelectedCategory(state, action: PayloadAction<string | null>) {
      return { ...state, selectedCategory: action.payload };
    },
    setShowL1Links(state, action: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.showL1Links = action.payload;
    },
    resetSelectedCategory(state) {
      // eslint-disable-next-line no-param-reassign
      state.selectedCategory = null;
    },
  },
});

export const { setSelectedCategory, setShowL1Links, resetSelectedCategory } =
  progressiveHeaderSlice.actions;
export default progressiveHeaderSlice.reducer;
