import { useEffect, useRef, useState } from 'react';

export default function useInterval(callback: () => void, delay: number) {
  const [id, setId] = useState<undefined | NodeJS.Timer>();
  const savedCallback = useRef<() => void>(callback);
  const clear = () => clearInterval(id);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      setId(setInterval(tick, delay));
    }

    return () => {
      clearInterval(id);
    };
  }, [delay]);
  return [id, clear];
}
