import { ReduxState } from '../../types';

export const searchInputSelector = (state: ReduxState) => state.sayt.searchInput;
export const searchDebouncedInputSelector = (state: ReduxState) => state.sayt.debouncedSearchInput;
export const searchDataSelector = (state: ReduxState) => state.sayt.searchData;
export const searchProductInputSelector = (state: ReduxState) => state.sayt.productSearchInput;
export const searchFireSaytImpressionSelector = (state: ReduxState) =>
  state.sayt.fireSaytImpression;
export const searchFirePreSaytImpressionSelector = (state: ReduxState) =>
  state.sayt.firePreSaytImpression;
export const searchDataLoadingSelector = (state: ReduxState) => state.sayt.searchDataLoading;
export const showSaytDrawerSelector = (state: ReduxState) => state.sayt.showSaytDrawer;
export const defaultSearchTermSelector = (state: ReduxState) => state.sayt.defaultSearchTerm;
export const showPreSaytDrawerSelector = (state: ReduxState) => state.sayt.showPreSaytDrawer;
