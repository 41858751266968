import { defaultSearchTermSelector } from '../store/selectors/saytSelector';
import { setDefaultSearchTerm, setSearchInput, setShowSaytDrawer } from '../store/slices/sayt';
import { useAppDispatch, useAppSelector } from '../store/store';

const useSetSearchTerm = () => {
  const dispatch = useAppDispatch();
  const defaultSearchTerm = useAppSelector(defaultSearchTermSelector);

  const setDisplaySaytDrawer = (value: boolean) => {
    dispatch(setShowSaytDrawer(value));
  };

  const setSearchTerm = (value: string) => {
    let showDrawer = defaultSearchTerm;
    dispatch(setSearchInput(value));

    if (defaultSearchTerm) {
      showDrawer = '';
      dispatch(setDefaultSearchTerm(null));
    }

    if (value && value.length > 2 && !showDrawer) {
      setDisplaySaytDrawer(true);
    } else {
      setDisplaySaytDrawer(false);
    }
  };

  return [setSearchTerm];
};

export default useSetSearchTerm;
