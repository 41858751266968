import React, { useEffect, useState } from 'react';
import './CountdownTimer.scss';

interface CountdownTimerProps {
  endDate: string;
  message?: string;
}

interface TimeObject {
  days?: { timeUnit: string; timeValue: number };
  hrs?: { timeUnit: string; timeValue: number };
  mins?: { timeUnit: string; timeValue: number };
  secs?: { timeUnit: string; timeValue: number };
}

export default function CountdownTimer({ endDate, message = 'Sale Ends' }: CountdownTimerProps) {
  const [msg, setMsg] = useState(message);
  let timer: any;
  let timeDifference: number;

  function calculateTimeLeft() {
    const currentTime = new Date();
    const end = new Date(endDate);
    end.setTime(end.getTime() + end.getTimezoneOffset() * 60 * 1000);
    timeDifference = end.getTime() - currentTime.getTime();
    const timeComponentsObject: TimeObject = {};

    timeComponentsObject.days = {
      timeUnit: 'days',
      timeValue: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
    };
    timeComponentsObject.hrs = {
      timeUnit: 'hrs',
      timeValue: Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    };
    timeComponentsObject.mins = {
      timeUnit: 'mins',
      timeValue: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
    };
    timeComponentsObject.secs = {
      timeUnit: 'secs',
      timeValue: Math.floor((timeDifference % (1000 * 60)) / 1000),
    };
    if (timeDifference < 1000) {
      timeComponentsObject.hrs.timeValue = 0;
      timeComponentsObject.mins.timeValue = 0;
      timeComponentsObject.secs.timeValue = 0;
      setMsg('Event Ended');
      clearTimeout(timer);
    }
    return timeComponentsObject;
  }

  const [timeLeft, setTimeLeft] = useState<TimeObject>();

  function toDoubleDigits(num: number): string {
    return num < 10 ? `0${num.toString()}` : num.toString();
  }

  function TimeComponent({ element }) {
    return (
      <div className="time-component" key={element.timeUnit}>
        <div className="time-value">{toDoubleDigits(element.timeValue)}</div>
        <div className="time-unit hmf-legal-s">{element.timeUnit}</div>
      </div>
    );
  }

  const TimeComponents = ({ timeRemaining }: any) => {
    let displayDays = timeRemaining.days.timeValue > 0;
    return (
      <>
        {Object.keys(timeRemaining).map((timeElement: any) => {
          if (timeElement === 'days') {
            if (timeRemaining.days.timeValue > 0) {
              return (
                <>
                  <TimeComponent element={timeRemaining.days} />
                  <div className="time-value hmf-mr-xxs hmf-ml-xxxs">:</div>
                </>
              );
            }
            displayDays = false;
          } else if (timeElement === 'hrs') {
            return (
              <>
                <TimeComponent element={timeRemaining[timeElement]} />
                <div
                  className={`time-value ${
                    displayDays ? 'hmf-ml-xxs hmf-mr-xxxs' : 'hmf-mx-xxxs'
                  } `}>
                  :
                </div>
              </>
            );
          } else if (timeElement === 'mins') {
            return (
              <>
                <TimeComponent element={timeRemaining[timeElement]} />
                {!displayDays && <div className="time-value subhead-bold-lg hmf-mx-xxxs">:</div>}
              </>
            );
          } else if (timeElement === 'secs') {
            if (!displayDays) {
              // eslint-disable-next-line react/jsx-key
              return <TimeComponent element={timeRemaining[timeElement]} />;
            }
          }
          return null;
        })}
      </>
    );
  };

  useEffect(() => {
    timer = setTimeout(() => {
      if (timeDifference > 0) {
        setTimeLeft(calculateTimeLeft());
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
  }, [endDate]);

  return (
    <div className="display-timer-wrapper hmf-py-xxs">
      <div className="timer-message hmf-subheader-l hmf-mr-xxs">{msg}</div>
      {timeLeft && <TimeComponents timeRemaining={timeLeft} />}
      {timeLeft && timeLeft.secs && timeLeft.secs.timeValue === 0 && (
        <div role="alert" aria-live="polite" className="countdown-ada" />
      )}
    </div>
  );
}
