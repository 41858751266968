import React, { useState, ChangeEvent, FormEvent, EventHandler } from 'react';

const PreSubmitForm = ({
  email,
  handleChange,
  handleSubmit,
  parentError,
  parentErrorMesssage = '',
  storeIdentifierName,
}: {
  email: string;
  handleChange: EventHandler<ChangeEvent>;
  handleSubmit: EventHandler<FormEvent>;
  parentError: boolean;
  parentErrorMesssage: string;
  storeIdentifierName: string;
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const blurFocused = () => {
    // On blur if email input is empty set error and focus state to false
    if (email.length === 0) {
      setFocused(false);
      setError(false);
    }
  };

  const handleBeforeChange = (e: ChangeEvent<HTMLInputElement>) => {
    // If input is empty disable error state
    if (e.target.value.length === 0) {
      setError(false);
    }

    handleChange(e);
  };

  return (
    <>
      <form
        className={`hmf-mb-0 footer-email-signup-form ${focused ? 'footer-focused' : ''} ${
          parentError || error ? 'footer-error' : ''
        }`}
        onSubmit={handleSubmit}
        noValidate>
        <input
          id="emailSignup"
          className="footer-email-input"
          type="email"
          value={email}
          onChange={handleBeforeChange}
          placeholder={focused ? '' : 'Email Address'}
          onFocus={() => setFocused(true)}
          onBlur={blurFocused}
          aria-required={false}
          alt="Email Address"
        />
        {storeIdentifierName !== 'pl' && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label htmlFor="emailSignup">Email Address</label>
        )}
        <input className="footer-email-submit hmf-label-l" type="submit" value="Sign Up" />
      </form>
      {(parentError || error) && (
        <span className="footer-email-error-text hmf-legal-xs">
          {parentErrorMesssage || 'Please enter an email in the format username@domain.com'}
        </span>
      )}
    </>
  );
};

export default PreSubmitForm;
