import React, { useEffect, useRef, useState } from 'react';
import { Drawer } from '@dsg-tech/homefield-react';
import { breakpointSelector } from 'store/selectors';
import ErrorBoundaryWithLogger from 'components/common/ErrorBoundary/ErrorBoundary';
import { useAppSelector } from 'store/store';
import { HomefieldIconSignCancel } from '@dsg-tech/homefield-react-icons';
import { NEW_KEY_CODES } from 'utility/constants';
import { TickerData } from '../../Ticker.interface';
import './TickerDrawer.scss';

interface TickerDrawerProps {
  showDrawer: boolean;
  setShowDrawer: () => void;
  closing: boolean;
  tickerData: TickerData[] | undefined;
  focusedItem: number | null;
}

const TickerDrawer = ({
  showDrawer,
  setShowDrawer,
  closing,
  tickerData,
  focusedItem,
}: TickerDrawerProps) => {
  const viewPortBreakpoint = useAppSelector(breakpointSelector);
  const refs = useRef<(HTMLAnchorElement | null)[]>([]);
  const [currentFocusIndex, setCurrentFocusIndex] = useState<number | null>(null);

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add('ticker-drawer-open');
      if (focusedItem !== null) {
        setCurrentFocusIndex(focusedItem);
      }
    } else {
      document.body.style.overflow = 'scroll';
      document.body.classList.remove('ticker-drawer-open');
      setCurrentFocusIndex(null);
    }

    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, [showDrawer, focusedItem]);

  useEffect(() => {
    if (currentFocusIndex !== null && refs.current[currentFocusIndex]) {
      (refs.current[currentFocusIndex] as HTMLElement)?.focus();
      refs.current.forEach((ref: HTMLAnchorElement | null) => {
        if (ref) ref.classList.remove('focus-visible');
      });

      (refs.current[currentFocusIndex] as HTMLElement).classList.add('focus-visible');
    }
  }, [currentFocusIndex]);

  const handleLinkFocus = (index: number) => {
    setCurrentFocusIndex(index);
  };

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === NEW_KEY_CODES.TAB) {
      handleLinkFocus(index);
    }
  };

  const handleCloseModal = () => {
    refs.current.forEach((ref: HTMLElement | null) => {
      if (ref) ref.classList.remove('focus-visible');
    });
    setCurrentFocusIndex(null);
    document.body.style.overflow = 'scroll';
    setShowDrawer();
  };

  // Function to generate the data-em attribute value
  const generateDataEm = (topText: string) => {
    // We need to disable the no-useless-escape rule here because we're using a
    // hyphen (-) in the character class of the regex. In this context, the hyphen
    // is not creating a range, but is meant to be taken literally. However, ESLint's
    // no-useless-escape rule incorrectly flags this as a useless escape.
    // eslint-disable-next-line no-useless-escape
    const sanitizedTopText = topText.replace(/[.,/#!$%^&*;:'{}=\-_`~()\s]/g, '');
    return `tkr_ticker_1_ticker-1_na_naDetails_${sanitizedTopText}`;
  };

  const tickerDrawerCustomProps = {
    id: 'tickerDrawerContainer',
  };

  const drawerSize = viewPortBreakpoint === 'mobile' ? 'medium' : 'small';

  return (
    <Drawer
      {...tickerDrawerCustomProps}
      size={drawerSize}
      className="ticker-drawer-container"
      attachedBackground={viewPortBreakpoint !== 'mobile'}
      open={showDrawer}
      mobilePeek={false}
      closing={closing}
      closable={false}
      onCloseModal={handleCloseModal}
      direction="top">
      <div className="ticker-drawer hmf-display-flex hmf-flex-column">
        <div className="ticker-drawer-header hmf-display-flex hmf-align-items-center hmf-align-items-lg-flex-end hmf-justify-content-end">
          <div className="hmf-display-flex hmf-justify-content-end hmf-position-relative">
            <button
              className="close-icon-container hmf-p-xxxs hmf-justify-content-end"
              type="button"
              aria-label="Close menu"
              onClick={handleCloseModal}
              onKeyDown={(event) => {
                if (event.key === NEW_KEY_CODES.SPACE || event.key === NEW_KEY_CODES.ENTER) {
                  handleCloseModal();
                }
              }}
              tabIndex={0}>
              <HomefieldIconSignCancel width="32" height="32" />
            </button>
          </div>
        </div>
        <div className="ticker-drawer-body hmf-display-flex hmf-justify-content-center hmf-mx-s hmf-mt-0 hmf-mb-l hmf-mx-lg-m hmf-my-lg-l">
          <div className="ticker-drawer-item-container hmf-display-flex hmf-flex-row hmf-justify-content-space-around hmf-flex-wrap">
            {tickerData?.map((ticker, index) => (
              <ErrorBoundaryWithLogger
                key={`ticker-${ticker.linkTitle}-${ticker.topText}-${ticker.link}`}>
                <div className="ticker-drawer-item">
                  <div className="hmf-header-bold-m hmf-mb-xxxs">{ticker.topText}</div>
                  <div className="hmf-body-l hmf-mb-xxs">{ticker.bottomText}</div>
                  <a
                    className="ticker-drawer-item-link hmf-body-l"
                    href={ticker.link}
                    ref={(el) => {
                      refs.current[index] = el;
                    }}
                    tabIndex={0}
                    onFocus={() => handleLinkFocus(index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    data-em={generateDataEm(ticker.topText)}>
                    {ticker.linkTitle}
                  </a>
                </div>
              </ErrorBoundaryWithLogger>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default TickerDrawer;
