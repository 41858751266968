import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { CommunicationSection } from '../../../types';
import useEmailSignUp from '../../../hooks/useEmailSignUp';
import PreSubmitForm from './PreSubmitForm/PreSubmitForm';
import './EmailSignUp.scss';

interface EmailSignUpState {
  email: string;
  submitted: boolean;
  error: EmailError;
  response: object;
}

interface EmailError {
  isError: boolean;
  message?: string;
}

export default function EmailSignUp({
  emailHeadline = '',
  smsHeadline = '',
  storeIdentifierName,
}: Readonly<CommunicationSection>) {
  const [email, setEmail] = useState<EmailSignUpState['email']>('');
  const [submitted, setSubmitted] = useState<EmailSignUpState['submitted']>(false);
  const [emailSignUpResponse, emailSignUpError, updateEmailSignUp, signUpAnalytics] =
    useEmailSignUp();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateEmailSignUp(email);
  };

  useEffect(() => {
    if (emailSignUpResponse) {
      if (emailSignUpResponse.status === 201) {
        setSubmitted(true);
        signUpAnalytics('Success! Thank you for signing up.', null);
      } else if (emailSignUpResponse.status === 200) {
        setSubmitted(true);
      } else if (emailSignUpResponse.status === 409) {
        signUpAnalytics('This offer is available to first-time email subscribers only.', null);
      }
    }
  }, [emailSignUpResponse]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const SubmittedUI = () => {
    if (emailSignUpResponse && emailSignUpResponse.status === 200) {
      return (
        <div>
          <p className="footer-email-signup-success hmf-body-bold-s">
            <span>Welcome back!</span> You&apos;ve already received this offer. You have been
            resubscribed to receive other offers.
          </p>
        </div>
      );
    }
    if (emailSignUpResponse && emailSignUpResponse.status === 201) {
      return (
        <div>
          {storeIdentifierName === 'pl' || storeIdentifierName === 'mj' ? (
            <p className="footer-email-signup-success hmf-body-bold-s">
              <span>Success!</span> You&apos;ve now subscribed to
              {storeIdentifierName === 'pl' ? ' Public Lands' : ' Moosejaw'} email. Check your inbox
              to receive news on exclusive offers, new gear &amp; more!
            </p>
          ) : (
            <p className="footer-email-signup-success hmf-body-bold-s">
              <span>Success!</span> Your 10% off is on its way to {email}.
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="footer-email-signup">
      {emailHeadline && (
        <h1 className="hmf-m-0 hmf-body-bold-m">
          {emailHeadline}
          <sup>{emailHeadline && '*'}</sup>
        </h1>
      )}
      {smsHeadline && <h2 className="hmf-subheader-m"> {smsHeadline} </h2>}
      {submitted ? (
        <SubmittedUI />
      ) : (
        <PreSubmitForm
          email={email}
          parentError={emailSignUpError}
          parentErrorMesssage={
            emailSignUpResponse?.status === 409
              ? 'This offer is available to first-time email subscribers only.'
              : ''
          }
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          storeIdentifierName={storeIdentifierName || ''}
        />
      )}
    </div>
  );
}
