import { useCallback } from 'react';
import { TickerResponse } from 'common/Ticker/Ticker.interface';
import { JsonCompatible, useLocalStorage } from './useLocalStorage';

/**
 * A local convenience function that determines if the user is in an environment where localStorage
 * overrides can be used.
 */
const canUseOverrides = () => {
  // allow us to override Homr data in a local development environment
  if (
    typeof window !== 'undefined' &&
    (window?.location?.hostname === 'localhost' ||
      window?.location?.hostname === 'localhost.dcsg.com')
  ) {
    return true;
  }

  // allow us to override Homr data in the standalone static-search page of the app compiled for
  // production
  if (
    typeof window !== 'undefined' &&
    window?.location?.hostname === 'static-search.dickssportinggoods.com'
  ) {
    return true;
  }
  // if none of the previous cases apply, don't allow anyone to apply localStorage overrides
  return false;
};

/**
 * A hook that determines whether we can override the requested page name with a value set in local
 * storage.
 *
 * @note we can't check against every possible value that might be returned by localStorage, so the
 * pageName returned might not match the type and throw an error. This should be fine since this
 * hook is primarily meant to be used in a development context, so **don't try to use it in anything
 * customer-facing!**
 *
 * @returns a tuple with 2 values:
 *  - The first value is a string if local overrides are enabled and a valid override was provided.
 *    Null otherwise.
 *  - The second value is a setter function that sets the value in local storage
 */
export const useTickerPageNameOverride = (): [
  pageName: string | null,
  setPageName: (pageName: string | null) => void,
] => {
  const canUseLocalStorageOverrides = canUseOverrides();
  const [tickerPageNameOverride, setTickerPageNameOverride] = useLocalStorage<string>(
    'homrAPITickerPageNameOverride',
  );

  // if we can't use localStorage overrides, exit early
  if (!canUseLocalStorageOverrides) {
    return [null, setTickerPageNameOverride];
  }

  // otherwise, return the override and a function to set the value
  return [tickerPageNameOverride, setTickerPageNameOverride];
};

/**
 * A hook that determines whether we can override the data passed to the Ticker with data set in
 * local storage.
 *
 * @note we can't check against every possible value that might be returned by localStorage, so the
 * mockTickerData returned might not match the type and throw an error. This should be fine since
 * this hook is primarily meant to be used in a development context, so **don't try to use it in
 * anything customer-facing!**
 *
 * @returns a tuple with 2 values:
 *  - The first value is a blob of JSON data if local overrides are enabled and a valid override was
 *    provided. Null otherwise
 *  - The second value is a setter function that sets the value in local storage
 */
export const useMockTickerOverride = (): [
  mockTickerData: TickerResponse | null,
  setMockTickerData: (overrideData: TickerResponse | null) => void,
] => {
  const canUseLocalStorageOverrides = canUseOverrides();
  const [mockTickerOverride, setMockTickerOverride] = useLocalStorage<TickerResponse>(
    'homrAPITickerMockOverride',
  );

  const setOverride = useCallback(
    (overrideData: TickerResponse | null) => {
      // casting here. The TickerResponse type is already meant to represent JSON, so it should be
      // JsonCompatible anyway
      setMockTickerOverride(overrideData as JsonCompatible<TickerResponse> | null);
    },
    [setMockTickerOverride],
  );

  // if we can't use localStorage overrides, exit early
  if (!canUseLocalStorageOverrides) {
    return [null, setOverride];
  }

  // otherwise, return the override and a function to set the value
  return [mockTickerOverride, setOverride];
};
