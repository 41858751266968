/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useCallback } from 'react';
import {
  HomefieldIconChevronLeft,
  HomefieldIconChevronRight,
  HomefieldIconMediaPause,
  HomefieldIconMediaPlay,
} from '@dsg-tech/homefield-react-icons';
import ErrorBoundaryWithLogger from 'components/common/ErrorBoundary/ErrorBoundary';
import { multiUseAnalyticsEvent } from 'utility/analytics-events';
import useInterval from 'hooks/useInterval';
import { checkStores } from 'utility/utility';
import './CarouselTicker.scss';
import { TickerData } from '../Ticker.interface';

interface CarouselTickerProps {
  tickerData: TickerData[];
  backgroundColor?: string;
  foregroundColor?: string;
}

const CarouselTicker: React.FC<CarouselTickerProps> = ({
  tickerData,
  backgroundColor,
  foregroundColor,
}) => {
  const slidesToScrollNext = 1;
  const slidesToScrollPrev = -1;
  const [duration, setDuration] = useState(11000);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliding, setSliding] = useState(true);
  const [slideWidth, setSlideWidth] = useState('768px');
  const [initialized, setInitialized] = useState(false);
  const [hasTickerAnalyticsFired, setHasTickerAnalyticsFired] = useState(false);

  const resetIntervalHook = () => {
    setDuration((prevDuration) => (prevDuration % 2 === 1 ? 11000 : 11001));
  };

  const handleNext = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>, interval = false) => {
      if (!interval) resetIntervalHook();
      if (sliding || !interval) {
        setCurrentSlide(
          (prevSlide) => (prevSlide + slidesToScrollNext + tickerData.length) % tickerData.length,
        );
      }
    },
    [sliding, tickerData.length],
  );

  const handlePrevious = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>, interval = false) => {
      if (!interval) resetIntervalHook();
      setCurrentSlide(
        (prevSlide) => (prevSlide + slidesToScrollPrev + tickerData.length) % tickerData.length,
      );
    },
    [tickerData.length],
  );

  useInterval(() => handleNext(undefined, true), sliding && initialized ? duration : null);

  const handleResize = useCallback(() => {
    const tickerItem = document.querySelector('.rh-ticker-carousel-item');
    setSlideWidth(tickerItem ? `${tickerItem.getBoundingClientRect().width}px` : 'auto');
  }, []);

  // Effect for handling window resizes to update slide width
  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    setCurrentSlide(0);
    setInitialized(true);
  }, [tickerData]);

  const carouselOffset = `-${currentSlide * parseInt(slideWidth, 10)}px`;

  const fireTickerAnalytics = () => {
    if (!hasTickerAnalyticsFired) {
      multiUseAnalyticsEvent({ event: 'tickerFirstInteraction' });
      setHasTickerAnalyticsFired(true);
    }
  };

  return (
    <div
      className="rh-ticker-carousel-wrapper header-ticker-minimum"
      style={{ background: backgroundColor }}>
      <div className="rh-ticker-carousel" aria-labelledby="carousel-ticker">
        <div className="rh-ticker-carousel-control--spacer" />
        <button
          type="button"
          onClick={() => {
            handlePrevious();
            // Remove analytics event once test is complete
            fireTickerAnalytics();
          }}
          aria-label="previous carousel slide"
          alt="Previous Carousel Slide">
          <HomefieldIconChevronLeft width="24px" height="24px" />
        </button>
        <div
          id="carousel-ticker"
          className="rh-ticker-carousel-track"
          style={{ width: slideWidth }}>
          <div
            className="rh-ticker-carousel-inner"
            style={{
              transition: 'transform 250ms ease-in',
              transform: `translateX(${carouselOffset})`,
            }}>
            {tickerData.map((ticker, index) => {
              // In order to be able to catch the error with ErrorBoundaryWithLogger and to avoid the component from breaking
              // We need to catch the error in try-catch and still wrap with ErrorBoundaryWithLogger
              try {
                return (
                  <ErrorBoundaryWithLogger
                    key={`ticker-${ticker.linkTitle}-${ticker.topText}-${ticker.link}`}>
                    <div className="rh-ticker-carousel-item" aria-hidden={currentSlide !== index}>
                      <div className="rh-ticker-carousel-content-section">
                        <ErrorBoundaryWithLogger>
                          <a
                            href={checkStores(ticker.link)}
                            target={
                              checkStores(ticker.link) && checkStores(ticker.link).includes('http')
                                ? '_blank'
                                : '_self'
                            }
                            style={{ color: foregroundColor }}
                            tabIndex={currentSlide === index ? 0 : -1}
                            data-em={`tkr_ticker_1_ticker-1_na_naDetails_${ticker.topText.replace(
                              // eslint-disable-next-line no-useless-escape
                              /[.,\/#!$%\^&\*;:'{}=\-_`~()\s]/g,
                              '',
                            )}`}>
                            <span className="rh-ticker-carousel-top-section">{ticker.topText}</span>
                            <span className="rh-ticker-carousel-bottom-section">
                              {ticker.bottomText}{' '}
                              <span className="rh-ticker-carousel-link">{ticker.linkTitle}</span>
                            </span>
                          </a>
                        </ErrorBoundaryWithLogger>
                      </div>
                    </div>
                  </ErrorBoundaryWithLogger>
                );
              } catch (e) {
                return null;
              }
            })}
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            handleNext();
            // Remove analytics event once test is complete
            fireTickerAnalytics();
          }}
          aria-label="next carousel slide"
          alt="Next Carousel Slide">
          <HomefieldIconChevronRight width="24px" height="24px" />
        </button>
        <div className="rh-ticker-play-pause">
          <button
            type="button"
            onClick={() => {
              setSliding((prev) => !prev);
              // Remove analytics event once test is complete
              fireTickerAnalytics();
            }}
            aria-label={sliding ? 'carousel pause' : 'carousel play'}
            alt={sliding ? 'Carousel Control: Pause' : 'Carousel Control: Play'}>
            {sliding ? (
              <HomefieldIconMediaPause width="24px" height="29px" />
            ) : (
              <HomefieldIconMediaPlay width="24px" height="24px" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

CarouselTicker.defaultProps = {
  backgroundColor: 'black',
  foregroundColor: 'white',
};

export default CarouselTicker;
